import React, { SFC, ChangeEvent, ReactNode } from "react";
import cx from "classnames";
import classes from "../Stepper/styles.module.scss";
import { StepHeaderProps, StepHeader } from "../StepHeader/StepHeader";
import { StepContentProps, StepContent } from "../StepContent/StepContent";

export interface StepProps {
  className?: string;
  /** The number to display */
  index?: number;
  /** The label to display */
  label?: string;
  /** Is active */
  active?: boolean;
  /** Is done */
  done?: boolean;
  /** Is last step */
  last?: boolean;
  /** The icon to display */
  icon?: ReactNode;
  /** @ignore */
  onClick?: (value: any) => void;
}

const Step: SFC<StepProps> = ({
  className,
  index = 0,
  label,
  icon,
  active = false,
  done = false,
  last = false,
  onClick,
  ...rest
}) => {
  const children = React.Children.map(rest.children, (child) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    if (child.type === StepHeader) {
      return React.cloneElement<StepHeaderProps>(
        child as React.ReactElement<StepHeaderProps>,
        {
          icon,
          index,
          onClick,
        },
      );
    } else if (child.type === StepContent) {
      return React.cloneElement<StepContentProps>(
        child as React.ReactElement<StepContentProps>,
        {
          className,
        },
      );
    }
  });

  return (
    <div
      className={cx(
        classes["ds-steppers__step"],
        {
          [classes["ds-steppers__step--done"]]: done,
          [classes["ds-steppers__step--current"]]: active,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Step;
