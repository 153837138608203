import React from "react";
import { Modal, Fade, Backdrop, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./sidePanel.module.css";
import Box from "@mui/material/Box";

interface SidePanelProps {
  open: boolean;
  onClose: () => void;
  width: "full" | "semi" | "small" | "regular";
  side: "right" | "left";
  children: React.ReactNode;
}

const SidePanel = ({
  open,
  onClose,
  width,
  side,
  children,
}: SidePanelProps) => {
  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: side === "right" ? "flex-end" : "flex-start",
  };

  const paperStyle = {
    width:
      width === "full"
        ? "100%"
        : width === "semi"
          ? "50%"
          : width === "small"
            ? "20%"
            : width === "regular"
              ? "75%"
              : "75%",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "5px",
    right: side === "right" ? "5px" : "auto",
    left: side === "left" ? "5px" : "auto",
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={modalStyle}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
        sx: { backdropFilter: "blur(3px)" }, // Ajout du flou au backdrop
      }}
    >
      <Fade in={open}>
        <div className={styles.paper} style={paperStyle}>
          <IconButton
            className={styles.closeButton}
            sx={closeButtonStyle}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ p: 0, m: 0 }}>{children}</Box>
        </div>
      </Fade>
    </Modal>
  );
};

export default SidePanel;
