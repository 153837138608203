/* Styles import */
import styles from "../admin.module.css";

/* Global import */
import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  SearchInput,
  Loading,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import EditIcon from "@material-ui/icons/Create";
import { useState, useCallback } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import Api from "../../../utils/api";
/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import ListMsg from "../../../components/listMsg";

interface State {
  search: string;
  selectedCountry?: Country;
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const ExportMsg: React.FC<Props> = ({ rootStore }) => {
  const [state, setState] = useState({
    search: "",
  });

  const { search } = state;

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="ADMIN" />

      <HeaderAdmin pages="EXPORTMSG" rootStore={rootStore} />

      <Card className={styles.body}>
        <div className={styles.headerCard}>
          <CardHeader title={"Exportability messages"} />
        </div>

        <CardContent>
          <ListMsg />

          {rootStore.countriesStore.loadingCountries && <Loading />}
        </CardContent>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(ExportMsg));
