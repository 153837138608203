/* Global import */
import React, { Component } from "react";
import { Provider, inject, observer } from "mobx-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Loading } from "./components/horizon-components-react/src/components";

/* Custom import */
import RootStore from "./stores/rootStore";
import PrivateRoute from "./components/privateRoute";
import "./utils/i18n";

/* Pages import */
import AppRouter from "./AppRouter";

interface Props {
  rootStore: RootStore; // Déclarez la prop 'rootStore' ici
}
interface State {}

@inject("rootStore")
@observer
export default class App extends React.Component<Props, State> {
  rootStore: RootStore;

  constructor(props: Props) {
    super(props);

    this.rootStore = new RootStore();
  }

  render() {
    return (
      <div>
        {this.rootStore.authStore.checkLoading
          ? this.renderLoading()
          : this.renderRouting()}
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  renderRouting() {
    return <AppRouter rootStore={this.rootStore} />;
  }
}
