import React, { Component, RefObject } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";

export interface LinkProps {
  /** Link can be \<a\> or \<button\> HTML tag. */
  as?: "a" | "button";

  /** A link can appear disabled. */
  disabled?: boolean;

  /** Primary content. */
  children?: React.ReactNode;

  /** Additional classes. */
  className?: string;

  href?: string;

  /** A unique identifier. */
  id?: string;

  label?: string;

  /** Specifies where to display the linked URL. */
  target?: "_self" | "_blank";

  /**
   * Called when user clicks on link.
   *
   * @param {MouseEvent<HTMLLinkElement>} event React's original MouseEvent.
   */
  handleClick?(event: React.MouseEvent<HTMLLinkElement>): void;
}

export default class Link extends Component<LinkProps> {
  static defaultProps = {
    as: "a",
    href: "#",
  };

  constructor(props: LinkProps) {
    super(props);
  }

  render() {
    const {
      as,
      children,
      className,
      disabled,
      href,
      id,
      label,
      target,
      handleClick,
    } = this.props;

    const getPropsByType = (componentType: string) => {
      const propstype: any = {};
      if (componentType === "button") {
        propstype.type = "button";
        propstype["aria-label"] = label;
        if (disabled === true) {
          propstype.disabled = "";
        }

        propstype.disabled = disabled;
      } else if (componentType === "a") {
        propstype.href = href;
        propstype.title = label;
        if (disabled === true) {
          propstype["aria-disabled"] = true;
        }
      }

      return propstype;
    };

    const ComponentType: string = as === "button" ? "button" : "a";
    const rest = getPropsByType(ComponentType);

    return (
      <ComponentType
        {...rest}
        className={cx(className, {
          [classes["ds-button--link"]]: as === "button",
          [classes["ds-button-link--disabled"]]: as === "a" && disabled,
        })}
        id={id}
        target={target}
        onClick={handleClick}
      >
        {children}
      </ComponentType>
    );
  }
}
