/* Styles import */
import styles from "../../license.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";

// eslint-disable-next-line

/* Custom import */
import { inject, observer } from "mobx-react";
import AppBar from "../../../../components/appBar";
import HeaderLicense from "../../../../components/headerLicense/headerLicense";
import {
  Card,
  CardContent,
  Title,
} from "../../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import Footer from "../../../../components/footer/footer";
import { useNavigate, useParams } from "react-router-dom";
import { flowUrl } from "../../../../utils/generator";
import Api from "../../../../utils/api";

interface State {
  license?: PostLicenseDto;
}

interface Props {
  rootStore: import("../../../../stores/rootStore").default;
}
const SendComponent: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const { id } = useParams() as {
    id?: string;
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const [license, setLicense] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const parsedId = parseInt(id, 10);
        if (!isNaN(parsedId)) {
          const licenseInfo =
            await rootStore.licenseStore.searchLicenseById(parsedId);
          setLicense(licenseInfo as PostLicenseDto);
        }
      }
    };

    fetchData();
  }, [id, rootStore]);

  const getMessage = () => {
    const { regulation } = useParams();
    switch (regulation) {
      case "FR_LIST_DOUBLE_USAGE":
      case "FR_ARRETE_DU_31_JUILLET_2014_HEL":
        return (
          <div>
            <p>
              Le contrôle des exportations des biens et technologies dits{" "}
              <i>à double usage</i> (BDU) est mis en œuvre par l’État pour
              lutter contre l’accumulation déstabilisante d’armes dans certaines
              régions du monde. Vous pouvez faire une demande de licence double
              usage en ligne sur le&nbsp;
              <a
                href="https://sbdu.entreprises.gouv.fr/fr"
                target="_blank"
                rel="noopener noreferrer"
              >
                portail EGIDE
              </a>
              .
              <br />
              <br />
              &nbsp;Cliquez ensuite sur <i>Demande en ligne</i> et suivez les
              étapes indiquées.
            </p>
          </div>
        );
      case "FR_MUNITIONS_LIST":
        return (
          <div>
            <p>
              L’entreprise qui souhaite exporter ou transférer des matériels de
              guerre et matériels assimilés doit obtenir une licence, par
              l’intermédiaire du système SIGALE (Système d’information, de
              gestion et d’administration des licences d’exportation), avant la
              signature de tout contrat et avant l’exportation physique des
              produits de défense.
              <br />
              <br />
              Cette démarche en ligne permet de s’informer sur l’avancement des
              démarches en cours et de récupérer les actes notifiés par
              l’Administration (cette démarche est accessible sous réserve de
              disposer d’un profil opérateur validé par l’Administration).
              <br />
              <br />
              Faites une demande de licence en ligne sur le&nbsp;
              <a
                href="https://www.service-public.fr/professionnels-entreprises/vosdroits/R42486"
                target="_blank"
                rel="noopener noreferrer"
              >
                portail SIGALE
              </a>
              &nbsp;et cliquez sur <i>Accéder au service en ligne</i>.
            </p>
          </div>
        );
      case "DE_DUAL_USE_GUTER":
      case "DE_MUNITIONS_LIST":
        return (
          <div>
            <p>
              Mit dem&nbsp;
              <a
                href="https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/Antragsstellung/ELAN-K2/elan-k2_node.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                ELAN-K2 Ausfuhr-System
              </a>
              &nbsp;bietet das Bundesamt für Wirtschaft und Ausfuhrkontrolle
              (BAFA) einen kostenlosen Zugang zu fast allen im Ausfuhrbereich
              benötigten Anträgen. Sie können mit dem System Anträge auf
              Ausfuhr-/Verbringungsgenehmigung, Nullbescheid, Voranfrage,
              Sonstige Anfragen, Auskunft zur Güterliste, Handels- und
              Vermittlungsgeschäfte, Sammelgenehmigungen, Internationale
              Einfuhrbescheinigungen, Wareneingangsbescheinigungen und die
              Formulare im Zusammenhang mit der Anmeldung bzw. Meldung
              (Meldeformular M1) von Allgemeinen Genehmigungen beim BAFA
              einreichen.
              <br />
              <br />
              Die Antragstellung erfolgt papierlos. Einzureichende Unterlagen
              können in PDF-Form hochgeladen werden. Auch erfolgen z. B.
              Rückfragen des BAFA schnell und medienbruchfrei über das System.
              Durch das Formular , Sonstige Anfrage“ können Anfragen eingereicht
              werden, die nicht bereits durch die bestehenden Formulare
              abgedeckt werden.
            </p>
          </div>
        );
      case "US_DUAL_USE_LIST_EAR":
        return (
          <div>
            <p>
              To request a US EAR license, you will need to fill out an end-user
              statement and send it to the US supplier or the US point of
              contact (US applicant), who will submit a license request to US
              authorities.
              <br />
              <br />
              Please click&nbsp;
              <a
                href="https://www.pmddtc.state.gov/ddtc_public?id=ddtc_kb_article_page&sys_id=a1d2f652dbb4130044f9ff621f961916"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              &nbsp;to get more information about US license applications.
            </p>
          </div>
        );
      case "US_MUNITIONS_LIST_ITAR":
        return (
          <div>
            <p>
              To request a US ITAR authorization, you will need to fill out an
              end-user statement and a DSP-83 and send it to the US supplier or
              the US point of contact (US applicant), who will submit a license
              request to US authorities.
              <br />
              <br />
              Please click&nbsp;
              <a
                href="https://www.bis.doc.gov/index.php/licensing/simplified-network-application-process-redesign-snap-r/cin-request"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              &nbsp;to get more information about US license applications.
            </p>
          </div>
        );
      default:
        return <div></div>;
    }
  };

  const getLicense = (license: PostLicenseDto) => {
    return Object.entries(license).map((elemLicense) => {
      const [label, value] = elemLicense;

      switch (label) {
        case "regulation":
          return (
            value && (
              <li key={label} className={styles.left_padding_1}>
                {i18n.t("pages.license.send." + label)} :{" "}
                {i18n.t("pages.license.send." + value)}
              </li>
            )
          );
        case "equipments":
        case "consignees":
          if (value.length) {
            return (
              <li key={label} className={styles.left_padding_1}>
                {i18n.t("pages.license.send." + label)} :
                {value.map((elementArray: object, index: number) => (
                  <ul key={index} className={styles.left_padding_1}>
                    {" "}
                    Item {index + 1}
                    {Object.entries(elementArray).map((licenseData) => {
                      const [labelData, valueData] = licenseData;

                      if (labelData !== "id" && valueData) {
                        return (
                          <li key={labelData} className={styles.left_padding_1}>
                            {i18n.t("pages.license.send." + labelData)} :{" "}
                            {valueData}
                          </li>
                        );
                      } else {
                        return false;
                      }
                    })}
                  </ul>
                ))}
              </li>
            );
          } else {
            return false;
          }
        case "id":
        case "status":
        case "usOrigin":
        case "destinationCountryId":
        case "updatedAt":
        case "createdAt":
          return false;
        default:
          return (
            value && (
              <li key={label} className={styles.left_padding_1}>
                {i18n.t("pages.license.send." + label)} : {value}
              </li>
            )
          );
      }
    });
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"LICENSE_MNGT"} />

      <HeaderLicense pages="REQUEST" rootStore={rootStore} />

      <div className={styles.body}>
        <Card>
          <Title tag="h2">{i18n.t("pages.license.menu.request")}</Title>
          <CardContent>
            {getMessage()}
            <Title tag="h3" className={styles.margin_top_bottom_1}>
              {i18n.t("pages.license.send.title")}
            </Title>
            <ul className={styles.left_padding_2}>
              {license && getLicense(license as PostLicenseDto)}
            </ul>
          </CardContent>
        </Card>
      </div>

      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => goBack(),
            label: i18n.t("global.previous"),
          },
        ]}
      />
    </div>
  );
};

export default inject("rootStore")(observer(SendComponent));
