/* Styles import */
import styles from "../subHeader/subHeader.module.css";

/* Global import */
import React from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";

/* Custom import */
import SubHeader from "../subHeader";
import { decode } from "../../utils/jwt";

interface Props {
  pages: Pages;
  rootStore: import("../../stores/rootStore").default;
}
type Pages =
  | "TENANTS"
  | "USERS"
  | "COUNTRIES"
  | "EXPORTMSG"
  | "SANCTION"
  | "US_RULES"
  | "EUROPEAN_RULES"
  | "CLASSIFICATION_DATABASE"
  | "CONTROL_LISTE"
  | "SCREENING"
  | "SCREENING_TENANT"
  | "SYSTEM";

const headerAdminComponent = ({ pages, rootStore }: Props) => {
  const jwt: JwtData =
    decode(window.localStorage.getItem("access_token") || "") || [];
  const jwtRoles: string[] = jwt.realm_access?.roles;

  return (
    <SubHeader>
      {jwtRoles?.includes("SUPER_ADMINISTRATOR") && (
        <div className={styles.linkContent}>
          <Link
            id="admin-tenants"
            className={`${styles.link} ${
              pages === "TENANTS" ? styles.selected : {}
            }`}
            to="/admin/tenants"
          >
            Clients
          </Link>
        </div>
      )}
      {jwtRoles?.includes("SUPER_ADMINISTRATOR") && (
        <Link
          id="admin-users"
          className={`${styles.link} ${
            pages === "USERS" ? styles.selected : {}
          }`}
          to="/admin/users"
        >
          {i18n.t("pages.admin.menu.users")}
        </Link>
      )}

      {rootStore.userStore.isAuthorized([
        "C_E_ADMIN",
        "ADMINISTRATOR_GROUP",
      ]) && (
        <Link
          id="classification-database"
          className={`${styles.link} ${
            pages === "CLASSIFICATION_DATABASE" ? styles.selected : {}
          }`}
          to="/admin/classification-database"
        >
          {i18n.t("pages.admin.menu.classification-database")}
        </Link>
      )}

      {rootStore.userStore.isAuthorized([
        "C_E_ADMIN",
        "ADMINISTRATOR_GROUP",
        "SUPER_ADMINISTRATOR",
      ]) && (
        <div className={styles.linkContent}>
          <Link
            id="admin-countries"
            className={`${styles.link} ${
              pages === "COUNTRIES" ? styles.selected : {}
            }`}
            to="/admin/countries"
          >
            {i18n.t("pages.admin.menu.countries")}
          </Link>

          {jwtRoles?.includes("SUPER_ADMINISTRATOR") && (
            <Link
              id="admin-upload"
              className={`${styles.link} ${
                pages === "EXPORTMSG" ? styles.selected : {}
              }`}
              to="/admin/exportmsg"
            >
              Messages
            </Link>
          )}

          {jwtRoles?.includes("SUPER_ADMINISTRATOR") && (
            <Link
              id="admin-german"
              className={`${styles.link} ${
                pages === "EUROPEAN_RULES" ? styles.selected : {}
              }`}
              to="/admin/european-rules"
            >
              {i18n.t("pages.admin.menu.germanEccn")}
            </Link>
          )}

          {jwtRoles?.includes("SUPER_ADMINISTRATOR") && (
            <Link
              id="admin-upload"
              className={`${styles.link} ${
                pages === "SANCTION" ? styles.selected : {}
              }`}
              to="/admin/sanction"
            >
              Sanctions
            </Link>
          )}
          {jwtRoles?.includes("SUPER_ADMINISTRATOR") && (
            <Link
              id="admin-screening"
              className={`${styles.link} ${
                pages === "SCREENING" ? styles.selected : {}
              }`}
              to="/admin/screening"
            >
              {`Screening`}
            </Link>
          )}

          {rootStore.userStore.isAuthorized(["ADMINISTRATOR_GROUP"]) && (
            <Link
              id="classification-database"
              className={`${styles.link} ${
                pages === "SCREENING_TENANT" ? styles.selected : {}
              }`}
              to="/admin/screening-tenant"
            >
              Screening
            </Link>
          )}

          {jwtRoles?.includes("SUPER_ADMINISTRATOR") && (
            <Link
              id="control-liste"
              className={`${styles.link} ${
                pages === "CONTROL_LISTE" ? styles.selected : {}
              }`}
              to="/regulation/control-lists-edit"
            >
              {"Regulations"}
            </Link>
          )}

          {jwtRoles?.includes("SUPER_ADMINISTRATOR") && (
            <Link
              id="admin-german"
              className={`${styles.link} ${
                pages === "SYSTEM" ? styles.selected : {}
              }`}
              to="/admin/system"
            >
              {i18n.t("pages.admin.menu.systemmenu")}
            </Link>
          )}
        </div>
      )}
    </SubHeader>
  );
};

export default headerAdminComponent;
