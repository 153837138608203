/* Styles import */
import styles from "../admin.module.css";
import i18n from "i18next";
/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
} from "../../../components/horizon-components-react/src/components";
import { observer, inject } from "mobx-react";
import Api from "../../../utils/api";
/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import ProgressUpload from "../../../components/progressUpload";
import { getToLocaleDate } from "../../../utils/date";
import axios from "axios";

interface State {
 

  filename_canada: string;
  file_date_canada: string;
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const Screening: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const [state, setState] = useState({
    filename_eu: "",
    file_date_eu: "",

    filename_alias: "",
    file_date_alias: "",

  });
  const [file_date_uk, setfile_file_date_uk] = useState("");
  const [filename_uk, setfilename_uk] = useState("");

  const [file_date_canada, setfile_file_date_canada] = useState("");
  const [filename_canada, setfilename_canada] = useState("");

  const [file_date_eu, setfile_file_date_eu] = useState("");
  const [filename_eu, setfilename_eu] = useState("");



  const [file_date_fr, setfile_file_date_fr] = useState("");
  const [filename_fr, setfilename_fr] = useState("");

  const [file_date_alias, setfile_file_date_alias] = useState("");
  const [filename_alias, setfilename_alias] = useState("");

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {

       const policy = await api.get_file_parametre();
      const simplifiedPolicy = policy.map(({ categorie, file_name, date_maj }) => ({
        categorie,
        file_name,
        date_maj,
      }));
      const getFileInfoByCategory = (category:string) => {
        return simplifiedPolicy.find(({ categorie }) => categorie === category);
      };

      
      if (isMounted) {
      
      const SCREENING_UK=getFileInfoByCategory('SCREENING_UK');
      setfile_file_date_uk(SCREENING_UK?.date_maj||"");
      setfilename_uk(SCREENING_UK?.file_name||""); 

      const SCREENING_CANADA=getFileInfoByCategory('SCREENING_CANADA');
      setfile_file_date_canada(SCREENING_CANADA?.date_maj||"");
      setfilename_canada(SCREENING_CANADA?.file_name||"");    
       
  
      const SCREENING_EU=getFileInfoByCategory('SCREENING_EU');
      setfile_file_date_eu(SCREENING_EU?.date_maj||"");
      setfilename_eu(SCREENING_EU?.file_name||"");        
       
      const SCREENING_FR=getFileInfoByCategory('SCREENING_EU');
      setfile_file_date_fr(SCREENING_FR?.date_maj||"");
      setfilename_fr(SCREENING_FR?.file_name||"");   

      const ALIAS_SCREENING=getFileInfoByCategory('ALIAS_SCREENING');
      setfile_file_date_alias(ALIAS_SCREENING?.date_maj||"");
      setfilename_alias(ALIAS_SCREENING?.file_name||"");      

       
        const policy1 = await api.get_alias_screening_file();

        if (policy1) {
          setState((prevState) => ({
            ...prevState,
            file_date_alias: policy1[0].date_maj,
            filename_alias: policy1[0].file_name,
          }));
        }



      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="ADMIN" />

      <HeaderAdmin pages="SCREENING" rootStore={rootStore} />

      <Card className={styles.body}>
        <CardHeader title={`UK screening`} />

        <CardContent>
          <ProgressUpload
            onChange={(file) => rootStore.controlReasonsStore.screeninguk(file)}
            progress={rootStore.controlReasonsStore.EccnExclusPourcent5}
            uploadState={rootStore.controlReasonsStore.EccnExclusState5}
            label={i18n.t("pages.admin.screening_uk.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${file_date_uk}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${filename_uk}`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={`Canada screening`} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.screeningcanada(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent3}
            uploadState={rootStore.controlReasonsStore.EccnExclusState3}
            label={i18n.t("pages.admin.screening_ca.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${file_date_canada}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${filename_canada}`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={`EU screening`} />

        <CardContent>
          <ProgressUpload
            onChange={(file) => rootStore.controlReasonsStore.screeningeu(file)}
            progress={rootStore.controlReasonsStore.EccnExclusPourcent}
            uploadState={rootStore.controlReasonsStore.EccnExclusState}
            label={i18n.t("pages.admin.screening_eu.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${file_date_eu}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${filename_eu}`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={`FR screening`} />

        <CardContent>
          <ProgressUpload
            onChange={(file) => rootStore.controlReasonsStore.screeningfr(file)}
            progress={rootStore.controlReasonsStore.EccnExclusPourcent2}
            uploadState={rootStore.controlReasonsStore.EccnExclusState2}
            label={i18n.t("pages.admin.screening_fr.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${file_date_fr}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${filename_fr}`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={`Alias screening`} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.aliasscreening(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent1}
            uploadState={rootStore.controlReasonsStore.EccnExclusState1}
            label={i18n.t("pages.admin.alias_screening.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${file_date_alias}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${filename_alias}`}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(Screening));
