/* eslint-disable no-restricted-globals */
import React, { Component, InputHTMLAttributes, ChangeEvent } from "react";

type Props = {
  /** The name of the group */
  name: string;
  /** The callback for when the input checked status changes */
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: any) => void;
};

type State = {
  value?: any;
};

type RadioGroupProps = Props &
  Pick<
    InputHTMLAttributes<HTMLInputElement>,
    Exclude<keyof InputHTMLAttributes<HTMLInputElement>, "onChange">
  >;

export default class RadioGroup extends Component<RadioGroupProps, State> {
  private isControlled: boolean = false;

  constructor(props: RadioGroupProps) {
    super(props);
    this.isControlled = props.value != null;
    this.state = this.isControlled
      ? {}
      : {
          value: props.defaultValue,
        };
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!this.isControlled) {
      this.setState({
        value: event.target.value,
      });
    }
    if (this.props.onChange) {
      this.props.onChange(event, event.target.value);
    }
  };

  render() {
    const value = this.isControlled ? this.props.value : this.state.value;
    return (
      <div role="radiogroup">
        {React.Children.map(this.props.children, (child) => {
          if (!React.isValidElement(child)) {
            return null;
          }

          if (child.type === React.Fragment) {
            console.warn(
              "The Tabs component doesn't accept a Fragment as a child. Consider providing an array instead.",
            );
          }

          const childProps = child.props as any;

          return React.cloneElement<any>(child, {
            name,
            checked: value === childProps.value,
            onChange: this.handleChange,
          });
        })}
      </div>
    );
  }
}
