import * as React from "react";
import cx from "classnames";
import classes from "./styles.module.scss";

import ArrowDown from "@material-ui/icons/ExpandMore";
import ArrowUp from "@material-ui/icons/ExpandLess";
import ArrowPlus from "@material-ui/icons/Add";
import ArrowMinus from "@material-ui/icons/Remove";

interface IIncrementalProps {
  /** Initial value for counter */
  value?: number;
  /** Minimum value for counter */
  minValue?: number;
  /** Maximum value for counter */
  maxValue?: number;
  /** If enabled, the counter runs indefinitely */
  rollup?: boolean;
  /** Function called when the counter change */
  onChange?: (value: number) => void;
  /** aria-label for arrow down */
  labelDown?: string;
  /** aria-label for arrow up */
  labelUp?: string;
  /** aria-label for number */
  labelInput?: string;
  /**
   * @ignore
   */
  isHour?: boolean;
  /**
   * @ignore
   */
  className?: string;
  /**
   * @ignore
   */
  classNameButtonDown?: string;
  /**
   * @ignore
   */
  classNameButtonUp?: string;
}

interface ITimePickIncrementalState {
  value: number;
}

export default class Incremental extends React.Component<
  IIncrementalProps,
  ITimePickIncrementalState
> {
  static defaultProps = {
    value: 0,
    minValue: 0,
    maxValue: 99,
    rollup: false,
    isHour: false,
    className: classes["ds-incrementer"],
    classNameButtonDown: classes["ds-incrementer__button"],
    classNameButtonUp: classes["ds-incrementer__button"],
    labelDown: "Increase number",
    labelUp: "Decrease number",
  };

  state = {
    value: Math.max(
      Math.min(this.props.value || 0, this.props.maxValue || 0),
      this.props.minValue || 0,
    ),
  };

  componentDidUpdate(prevProps: IIncrementalProps) {
    if (
      this.props.value !== undefined &&
      this.props.value !== prevProps.value
    ) {
      this.setState({
        value: Math.max(
          Math.min(this.props.value || 0, this.props.maxValue || 0),
          this.props.minValue || 0,
        ),
      });
    }
  }

  render() {
    const {
      value,
      isHour,
      className,
      classNameButtonDown,
      classNameButtonUp,
      labelDown,
      labelUp,
      labelInput,
    } = this.props;

    if (isHour) {
      return (
        <div className={className}>
          {this.state.value.toString().padStart(2, "0")}
          <button
            type="button"
            aria-label={labelDown}
            onClick={this.onIncrement}
            className={classNameButtonUp}
          >
            <ArrowUp aria-hidden="true" className={classes["arrow--icon"]} />
          </button>
          <button
            type="button"
            aria-label={labelUp}
            onClick={this.onDecrement}
            className={classNameButtonDown}
          >
            <ArrowDown aria-hidden="true" className={classes["arrow--icon"]} />
          </button>
        </div>
      );
    } else {
      return (
        <div className={className}>
          <button
            onClick={this.onDecrement}
            className={cx(
              classes["ds-incrementer__button"],
              classes["ds-incrementer__button--minus"],
            )}
            type="button"
            aria-label={labelUp}
          >
            <ArrowMinus
              className={cx(classes["ds-icon-minus"], classes["plus--icon"])}
              aria-hidden="true"
            />
          </button>
          <input
            type="number"
            aria-label={labelInput}
            value={this.state.value}
            readOnly
          />
          <button
            onClick={this.onIncrement}
            className={cx(
              classes["ds-incrementer__button"],
              classes["ds-incrementer__button--plus"],
            )}
            type="button"
            aria-label={labelDown}
          >
            <ArrowPlus
              className={cx(classes["ds-icon-more"], classes["plus--icon"])}
              aria-hidden="true"
            />
          </button>
        </div>
      );
    }
  }

  onIncrement = (): void => {
    let value = this.state.value + 1;
    if (value > (this.props.maxValue || 0)) {
      if (this.props.rollup) {
        value = this.props.minValue || 0;
      } else {
        value = this.props.maxValue || 0;
      }
    }
    this.changeValue(value);
  };

  onDecrement = (): void => {
    let value = this.state.value - 1;
    if (value < (this.props.minValue || 0)) {
      if (this.props.rollup) {
        value = this.props.maxValue || 0;
      } else {
        value = this.props.minValue || 0;
      }
    }
    this.changeValue(value);
  };

  changeValue(newValue: number): void {
    if (this.props.value !== newValue && this.props.onChange) {
      this.props.onChange(newValue);
    }
  }
}
