/* Global import */
import { action, makeObservable, observable, runInAction } from "mobx";

/* Custom import */
import Api from "../utils/api";
import configuration from "../configuration";
interface Entity {
  id: number;
  id_entite: string;
  id_tenant: string;
}

export default class TenantStore {
  rootStore: import("./rootStore").default;
  api: Api;

  @observable tenants: TenantItemReceived[] = [];
  @observable entities: Entity[] = [];
  @observable datalog: [] = [];
  @observable datalogrep: [] = [];
  @observable loading: boolean = false;
  @observable questionsResponse: any;
  @observable tenantAhData: any;
  @observable tenanttempleteData: any;

  constructor(rootStore: import("./rootStore").default) {
    makeObservable(this);
    this.api = new Api();
    this.rootStore = rootStore;
  }
  @action async getLogreplication() {
    this.loading = true;

    try {
      this.datalogrep = await this.api.getLogreplication();
    } catch (e) {}

    this.loading = false;
  }
  @action async getLog(annee: string) {
    this.loading = true;

    try {
      this.datalog = await this.api.getLog(annee);
    } catch (e) {}

    this.loading = false;
  }

  @action async getTenantAhData() {
    const tenantAhResponse = await fetch(
      `${
        configuration.LARAVEL_API
      }/getTenantAh?name=${encodeURIComponent(this.rootStore.userStore.user?.tenantId! || "")}`,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
        },
      }
    );
    this.tenantAhData = await tenantAhResponse.json();
  }

  @action async getTenanttempleteData() {
    const tenanttempleteResponse = await fetch(
      `${
        configuration.LARAVEL_API
      }/getTenanttemplete?name=${encodeURIComponent(this.rootStore.userStore.user?.tenantId! || "")}`,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
        },
      }
    );
    this.tenanttempleteData = await tenanttempleteResponse.json();
  }

  @action
  async getQuestionsResponse() {
    this.loading = true;

    try {
      const response = await fetch(
        `${configuration.LARAVEL_API}/get_Question?tenant=${encodeURIComponent(this.rootStore.userStore.user?.tenantId || "")}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
          },
        }
      );
      // await new Promise(resolve => setTimeout(resolve, 10000));

      const data = await response.json();
      runInAction(() => {
        this.questionsResponse = data;
        this.loading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        // Gérer l'erreur de manière appropriée
        console.error("Failed to fetch questions", e);
      });
    }
  }

  @action async getLogTenant() {
    this.loading = true;

    try {
      this.datalog = await this.api.getLogTenant(
        this.rootStore.userStore.user?.tenantId!
      );
    } catch (e) {}

    this.loading = false;
  }

  @action async getEntities(tenant: string) {
    this.loading = true;

    try {
      this.entities = await this.api.getTenantEntities(tenant);
    } catch (e) {}
    this.loading = false;
  }

  @action async getTenants() {
    this.loading = true;

    try {
      this.tenants = await this.api.getTenants();
    } catch (e) {}

    this.loading = false;
  }

  async submitTenant(tenant: TenantItem, method: axiosMethod = "POST") {
    this.loading = true;
    try {
      await this.api.submitTenant(method, tenant);

      this.getTenants();
    } catch (e) {}

    this.loading = false;
  }
}
