/* Styles import */
import styles from "./result.module.css";
import html2canvas from "html2canvas";
import moment from "moment";
import "moment/locale/fr";
import jsPDF from "jspdf";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  GridCol,
  GridRow,
  Title,
} from "../../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Api from "../../../../utils/api";
/* Custom import */
import AppBar from "../../../../components/appBar";
import Footer from "../../../../components/footer";
import { flowUrl } from "../../../../utils/generator";
import TimeLine from "../../../../components/timeLine";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "../../../../components/horizon-components-react/src/components";
import configuration from "../../../../configuration";

interface State {
  flowId?: string;
  post?: BasicEntityFinale_eu[];
  unique: string;
  nosanction: string;
}
interface InjectedProps {
  rootStore: import("../../../../stores/rootStore").default;
}
interface Props extends InjectedProps {}

const ResultBasicEntity: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const { id, company } = useParams();
  const state = useLocation();
  const navigate = useNavigate();
  const navigateTowards = (url: string) => {
    navigate(flowUrl(url, id));
  };
  const goBack = () => {
    navigate(-1);
  };
  const [post, setPost] = useState<BasicEntityFinale_eu[]>();
  const [msg1, setMsg1] = useState("");
  const [Lib1, setLib1] = useState("");
  const [Url1, setUrl1] = useState("");
  const [Lib2, setLib2] = useState("");
  const [Url2, setUrl2] = useState("");
  const [Lib3, setLib3] = useState("");
  const [Url3, setUrl3] = useState("");
  const [bmsg1, setBMsg1] = useState("");
  const [bLib1, setBLib1] = useState("");
  const [bUrl1, setBUrl1] = useState("");
  const [bLib2, setBLib2] = useState("");
  const [bUrl2, setBUrl2] = useState("");
  const [bLib3, setBLib3] = useState("");
  const [bUrl3, setBUrl3] = useState("");
  const [nosanction, setNosanction] = useState("Screening ....");
  const [unique, setUnique] = useState("");

  const getButtons = () => {
    const location = useLocation();
    const { state } = location;
    const unique = state.unique;
    const buttons = [
      {
        id: "previous",
        onClick: () =>
          unique === "ok"
            ? navigateTowards("/screening/basic/ah")
            : navigateTowards("/screening/basic/ah/choose-basic-entity"),
        label: i18n.t("global.previous"),
        primary: false,
        disabled: false,
      },
      {
        id: "PDF",
        onClick: () => handleDownloadImage(),
        label: i18n.t("global.pdf"),
      },
    ];

    return buttons;
  };
  const flowId = id;

  useEffect(() => {
    const fetchData = async () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      const bmsg = "EXP081";
      const msg = "EXP080";

      setMsg1(await api.CodeToMsg(msg));
      setBMsg1(await api.CodeToMsg(bmsg));
      setLib2(await api.CodeTolLib2(msg));
      setUrl2(await api.CodeTolUrl2(msg));
      setLib1(await api.CodeTolLib1(msg));
      setUrl1(await api.CodeTolUrl1(msg));
      setBLib1(await api.CodeTolLib1(bmsg));
      setBUrl1(await api.CodeTolUrl1(bmsg));
      setBLib2(await api.CodeTolLib2(bmsg));
      setBUrl2(await api.CodeTolUrl2(bmsg));

      fetch(
        `${configuration.LARAVEL_API}/entite_ah?companyName=` +
          encodeURIComponent(`${company}`) +
          `&id_tenant=` +
          rootStore.userStore.user?.tenantId
      )
        .then((response) => response.json())
        .then((ok) => setPost(ok.results));

      if (!post) {
        setTimeout(() => {
          setNosanction(i18n.t("global.nosanctionah"));
        }, 1000);
      }
    };

    fetchData();
  }, [company, post]);

  const handleDownloadImage = async () => {
    const element = document.getElementById("print")!;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/jpg");
    const maDate = new Date();

    const location = useLocation();
    const { state } = location;

    const auteur =
      rootStore.userStore.user?.firstname +
      " " +
      rootStore.userStore.user?.lastname;

    const societe = rootStore.userStore.user?.tenantId;
    var afDate = moment(maDate).format("YYYY-MM-DD");

    var doc = new jsPDF("p", "mm");
    let country = window.navigator.language;
    let datrap = moment().locale("en").format("ll");

    if (country === "fr-FR") datrap = moment().locale("fr").format("ll");

    doc.setFontSize(14);
    let width = doc.internal.pageSize.getWidth();
    doc.text(
      i18n.t("global.titrescreening"),
      doc.internal.pageSize.getWidth() / 2 - 20,
      10
    );

    doc.setFontSize(10);
    doc.text(i18n.t("global.rapport") + " " + datrap, 10, 20);
    doc.text(i18n.t("global.autheur") + " : " + auteur, 10, 25);
    doc.text(i18n.t("global.societe") + " : " + societe, 10, 30);
    let dcountry = "";
    let acountry = "";
    /*
    if (result) {
      dcountry = result.departureCountry.name
      acountry = result.countryOfDestination.name
    } 
    */
    let height = doc.internal.pageSize.getHeight();

    let widthRatio = width / canvas.width;
    let heightRatio = height / canvas.height;

    let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
    doc.addImage(
      data,
      "JPEG",
      0,
      40,
      canvas.width * ratio,
      canvas.height * ratio
    );

    doc.text(
      i18n.t("global.footer"),
      doc.internal.pageSize.getWidth() / 2 - 30,
      270
    );
    doc.save(afDate + " EU Screening " + company + ".pdf");

    const link = document.createElement("a");
    // link.href = data;
    // link.download = 'downloaded-image.jpg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const screeningBasicEntityResult =
    rootStore.screeningStore.screeningBasicEntityResult;

  let feu = "green";
  if (post) {
    post.forEach((s) => {
      if (!s.Entity_Regulation_NumberTitle.toString().includes("Civil")) {
        feu = "yellow";
      }
    });
  }

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"SCREENING"} />

      <div className={styles.image}></div>
      <div id="print">
        <div>
          <div className={styles.row}>
            <img
              className={styles.traficLight}
              src={require(
                `../../../../../statics/images/global-traffic-${feu}.svg`
              )}
              alt={"traffic"}
            />

            <div>
              <Title tag="h2">Screening</Title>
            </div>
          </div>

          <div className={styles.body}>
            <Title tag="h2">
              {i18n.t("pages.screening.entity.choose-entity.titreahresultat")}
            </Title>
            <div>
              {
                <div className={styles.message}>
                  <div className={`${styles.traficLightBox} ${feu}`}></div>
                  <img
                    className={styles.traficLight}
                    src={require(
                      `../../../../../statics/images/traffic-${feu}.svg`
                    )}
                    alt={"trafic"}
                  />

                  <div>
                    <p>
                      {post &&
                        post.map((s) => (
                          <div>
                            <b>
                              {!s.Entity_Regulation_NumberTitle.toString().includes(
                                "Civil"
                              )
                                ? "The entity " +
                                  s.name +
                                  " is a military end user."
                                : "The entity " +
                                  s.name +
                                  " is a civil end user."}{" "}
                            </b>
                          </div>
                        ))}

                      <div>
                        <br />
                      </div>
                      {post &&
                        post.map(
                          (s) =>
                            !s.Entity_Regulation_NumberTitle.toString().includes(
                              "Civil"
                            ) && (
                              <div>
                                <div>
                                  <p>
                                    {msg1 && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: msg1,
                                        }}
                                      />
                                    )}
                                  </p>
                                </div>
                                <div>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={Url1}
                                  >
                                    {Lib1}
                                  </a>
                                  <a
                                    target="_blank"
                                    style={{ marginLeft: "50px" }}
                                    rel="noopener noreferrer"
                                    href={Url2}
                                  >
                                    {Lib2}
                                  </a>
                                  <a
                                    target="_blank"
                                    style={{ marginLeft: "50px" }}
                                    rel="noopener noreferrer"
                                    href={Url3}
                                  >
                                    {Lib3}
                                  </a>
                                </div>
                              </div>
                            )
                        )}

                      {post &&
                        post.map(
                          (s) =>
                            s.Entity_Regulation_NumberTitle.toString().includes(
                              "Civil"
                            ) && (
                              <div>
                                <div>
                                  <p>
                                    {bmsg1 && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: bmsg1,
                                        }}
                                      />
                                    )}
                                  </p>
                                </div>
                                <div>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={bUrl1}
                                  >
                                    {bLib1}
                                  </a>

                                  <a
                                    target="_blank"
                                    style={{ marginLeft: "50px" }}
                                    rel="noopener noreferrer"
                                    href={bUrl2}
                                  >
                                    {bLib2}
                                  </a>
                                  <a
                                    target="_blank"
                                    style={{ marginLeft: "50px" }}
                                    rel="noopener noreferrer"
                                    href={bUrl3}
                                  >
                                    {bLib3}
                                  </a>
                                </div>
                              </div>
                            )
                        )}
                    </p>
                  </div>
                </div>
              }
              <br />
              <br />
              {post &&
                post.map((s) => (
                  <Card>
                    <Table>
                      <TableRow>
                        <TableCell>Partner's name</TableCell>
                        <TableCell>{s.name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Country</TableCell>
                        <TableCell>{s.Entity_Remark}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>ICAO</TableCell>
                        <TableCell>
                          {s.Entity_SubjectType_ClassificationCode}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Partner's city</TableCell>
                        <TableCell>
                          {s.Entity_Regulation_EntryIntoForceDate}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Final status</TableCell>
                        <TableCell>{s.Entity_Regulation_NumberTitle}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Status date</TableCell>
                        <TableCell>
                          {moment(
                            s.Entity_Regulation_Programme,
                            "YYYY-MM-DD HH:mm:ss"
                          ).format("DD/MM/YYYY")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Source </TableCell>
                        <TableCell>
                          {s.Entity_Regulation_PublicationUrl}
                        </TableCell>
                      </TableRow>
                    </Table>
                  </Card>
                ))}
            </div>
          </div>
        </div>
      </div>

      <Footer buttons={getButtons()}>
        {flowId && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(ResultBasicEntity));
