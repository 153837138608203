/* Styles import */
import styles from "./result.module.css";
import moment from "moment";
import "moment/locale/fr";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  GridCol,
  GridRow,
  Title,
} from "../../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Api from "../../../../utils/api";
/* Custom import */
import AppBar from "../../../../components/appBar";
import Footer from "../../../../components/footer";
import { flowUrl } from "../../../../utils/generator";
import TimeLine from "../../../../components/timeLine";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "../../../../components/horizon-components-react/src/components";
import configuration from "../../../../configuration";

interface InjectedProps {
  rootStore: import("../../../../stores/rootStore").default;
}
interface Props extends InjectedProps {}
interface State {
  flowId?: string;
  post?: BasicEntityFinale_fr[];
  nosanction: string;
}
type MsgType = {
  code_msg: string;
  Regle: string;
  Message: string;
  Lib_url1: string;
  Url1: string;
  Lib_url2: string;
  Url2: string;
  Lib_url3: string;
  Url3: string;
};

interface InjectedProps {
  rootStore: import("../../../../stores/rootStore").default;
}
interface Props extends InjectedProps {}

const ResultBasicEntity: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const { id, company, unique, company_us } = useParams();
  const flowId = id;
  const navigate = useNavigate();
  const navigateTowards = (url: string) => {
    navigate(flowUrl(url, id));
  };
  const goBack = () => {
    navigate(-1);
  };

  const [msg, setMsg] = useState({
    code_msg: "",
    Regle: "",
    Message: "",
    Lib_url1: "",
    Url1: "",
    Lib_url2: "",
    Url2: "",
    Lib_url3: "",
    Url3: "",
  });

  const [nosanction, setNosanction] = useState("");
  const [msg1, setMsg1] = useState("");
  const [Lib1, setLib1] = useState("");
  const [Url1, setUrl1] = useState("");
  const [Lib2, setLib2] = useState("");
  const [Url2, setUrl2] = useState("");
  const [Lib3, setLib3] = useState("");
  const [Url3, setUrl3] = useState("");
  const [bmsg1, setBMsg1] = useState("");
  const [bLib1, setBLib1] = useState("");
  const [bUrl1, setBUrl1] = useState("");
  const [bLib2, setBLib2] = useState("");
  const [bUrl2, setBUrl2] = useState("");
  const [bLib3, setBLib3] = useState("");
  const [bUrl3, setBUrl3] = useState("");
  const [post, setPost] = useState<BasicEntityFinale_eu[]>();

  const getButtons = () => {
    const location = useLocation();
    const buttons = [
      {
        id: "previous",
        onClick: () =>
          unique === "ok"
            ? navigateTowards("/screening/basic/other")
            : navigateTowards("/screening/basic/fr/choose-basic-entity"),
        label: i18n.t("global.previous"),
        primary: false,
        disabled: false,
      },
      {
        id: "PDF",
        onClick: () => handleDownloadImage(),
        label: i18n.t("global.pdf"),
      },
      {
        id: "due",
        onClick: () => {
          let currentPostList = null as any;

          // Déterminer quelle liste utiliser
          if (rootStore.screeningStore.liste_fr) {
            currentPostList = post;
          } else if (rootStore.screeningStore.liste_eu) {
            currentPostList = post;
          } else if (rootStore.screeningStore.liste_uk) {
            currentPostList = post;
          } else if (rootStore.screeningStore.liste_canadien) {
            currentPostList = post;
          } else if (rootStore.screeningStore.liste_us) {
            currentPostList = company_us;
          }
          const idEntity =
            currentPostList && currentPostList.length > 0
              ? currentPostList[0].name
              : null;

          navigate(flowUrl(`/screening/basic/due_diligence/${idEntity}`));
        },
        label: i18n.t("global.due_diligence"),
      },
    ];

    return buttons;
  };

  const bmsg = "EXP056";
  useEffect(() => {
    const fetchData = async () => {
      try {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        const msg = await api.GcodeToMsg(bmsg);
        const msg1 = msg.Message;
        const Lib1 = msg.Lib_url1;
        const Url1 = msg.Url1;
        const Lib2 = msg.Lib_url2;
        const Url2 = msg.Url2;
        const Lib3 = msg.Lib_url3;
        const Url3 = msg.Url3;

        const response = await fetch(
          `${configuration.LARAVEL_API}/entite_fr?companyName=` +
            encodeURIComponent(`${company}`)
        );
        const data = await response.json();

        //console.log(data.results);
        setPost(data.results);

        if (!data.results) {
          setTimeout(() => {
            setNosanction(i18n.t("global.nosanction"));
          }, 1000);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors as needed
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []);
  const handleDownloadImage = async () => {
    const element = document.getElementById("print")!;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/jpg");
    const maDate = new Date();
    const auteur =
      rootStore.userStore.user?.firstname +
      " " +
      rootStore.userStore.user?.lastname;

    const societe = rootStore.userStore.user?.tenantId;
    var afDate = moment(maDate).format("YYYY-MM-DD");

    var doc = new jsPDF("p", "mm");
    let country = window.navigator.language;
    let datrap = moment().locale("en").format("ll");

    if (country === "fr-FR") datrap = moment().locale("fr").format("ll");

    doc.setFontSize(14);
    let width = doc.internal.pageSize.getWidth();
    doc.text(
      i18n.t("global.titrescreening"),
      doc.internal.pageSize.getWidth() / 2 - 20,
      10
    );

    doc.setFontSize(10);
    doc.text(i18n.t("global.rapport") + " " + datrap, 10, 20);
    doc.text(i18n.t("global.autheur") + " : " + auteur, 10, 25);
    doc.text(i18n.t("global.societe") + " : " + societe, 10, 30);
    let dcountry = "";
    let acountry = "";
    /*
    if (result) {
      dcountry = result.departureCountry.name
      acountry = result.countryOfDestination.name
    } 
    */
    let height = doc.internal.pageSize.getHeight();

    let widthRatio = width / canvas.width;
    let heightRatio = height / canvas.height;

    let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
    doc.addImage(
      data,
      "JPEG",
      0,
      40,
      canvas.width * ratio,
      canvas.height * ratio
    );

    doc.text(
      i18n.t("global.footer"),
      doc.internal.pageSize.getWidth() / 2 - 30,
      270
    );
    doc.save(afDate + " FR Screening " + company + ".pdf");

    const link = document.createElement("a");
    // link.href = data;
    // link.download = 'downloaded-image.jpg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const screeningBasicEntityResult =
    rootStore.screeningStore.screeningBasicEntityResult;

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"SCREENING"} />

      <div className={styles.image}></div>

      <div className={styles.body}>
        <Title tag="h2">
          {i18n.t("pages.screening.entity.result.titleBasic")}
        </Title>

        {post?.length === 0 && (
          <div id="print">
            {
              <div className={styles.message}>
                <div
                  className={`${styles.traficLightBox} ${styles["green"]}`}
                ></div>
                <img
                  className={styles.traficLight}
                  src={require(
                    `../../../../../statics/images/traffic-green.svg`
                  )}
                  alt={"trafic"}
                />

                <b>
                  {nosanction}

                  {rootStore.screeningStore.company}
                </b>
              </div>
            }
            <div>
              <p>
                <div>
                  <br />
                </div>
              </p>
            </div>
          </div>
        )}

        {post?.length !== 0 && (
          <div id="print">
            {
              <div className={styles.message}>
                <div
                  className={`${styles.traficLightBox} ${styles["yellow"]}`}
                ></div>
                <img
                  className={styles.traficLight}
                  src={require(
                    `../../../../../statics/images/traffic-yellow.svg`
                  )}
                  alt={"trafic"}
                />

                <div>
                  <p>
                    <b>
                      {
                        //screeningBasicEntityResult.message
                      }{" "}
                    </b>
                    <div>
                      <br />
                    </div>

                    {post &&
                      post.map(
                        (s) =>
                          s.type.indexOf("Personne morale") !== -1 && (
                            <div>
                              <div>
                                <b>
                                  {i18n.t(
                                    "pages.screening.entity.result.entite"
                                  ) +
                                    ` ` +
                                    s.name +
                                    `  ` +
                                    i18n.t(
                                      "pages.screening.entity.result.rest_fr"
                                    )}
                                </b>
                              </div>
                            </div>
                          )
                      )}

                    {post &&
                      post.map(
                        (s) =>
                          s.type.indexOf("Personne morale") === -1 && (
                            <div>
                              <div>
                                <b>
                                  {i18n.t(
                                    "pages.screening.entity.result.person"
                                  ) +
                                    ` ` +
                                    s.name +
                                    `  ` +
                                    i18n.t(
                                      "pages.screening.entity.result.rest_fr"
                                    )}
                                </b>
                              </div>
                            </div>
                          )
                      )}
                  </p>
                </div>
              </div>
            }

            <div>
              <br />
            </div>
            {post &&
              post.map((s) => (
                <div>
                  <div>
                    <p>
                      {msg1 &&
                        msg1.split("<br>").map((i, key) => {
                          return (
                            <div key={key}>
                              <div
                                className="post__content"
                                dangerouslySetInnerHTML={{ __html: i }}
                              ></div>
                            </div>
                          );
                        })}
                    </p>
                  </div>
                  <div>
                    <a target="_blank" rel="noopener noreferrer" href={Url1}>
                      {Lib1}
                    </a>
                    <a
                      target="_blank"
                      style={{ marginLeft: "50px" }}
                      rel="noopener noreferrer"
                      href={Url2}
                    >
                      {Lib2}
                    </a>
                    <a
                      target="_blank"
                      style={{ marginLeft: "50px" }}
                      rel="noopener noreferrer"
                      href={Url3}
                    >
                      {Lib3}
                    </a>
                  </div>
                </div>
              ))}

            <br />
            <br />
            {post &&
              post.map((s) => (
                <Card>
                  <Table>
                    <TableRow>
                      <TableCell>{i18n.t("global.name")}</TableCell>
                      <TableCell>{s.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{i18n.t("global.Type")}</TableCell>
                      <TableCell>{s.type}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{i18n.t("global.localisation")}</TableCell>
                      <TableCell>{s.localisation}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{i18n.t("global.reglementation")}</TableCell>
                      <TableCell>{s.reglementation}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{i18n.t("global.Function")}</TableCell>
                      <TableCell>{s.fonction}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{i18n.t("global.Note")}</TableCell>
                      <TableCell>{s.note}</TableCell>
                    </TableRow>
                  </Table>
                </Card>
              ))}
          </div>
        )}
      </div>
      <Footer buttons={getButtons()}>
        {flowId && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(ResultBasicEntity));
