export default {
  API: process.env.REACT_APP_API,
  LARAVEL_API: process.env.REACT_APP_SCREEN,
  CHARMAX: parseInt(process.env.REACT_APP_CHARMAX as string),
  DEFAULT_COUNTRY: process.env.REACT_APP_DEFAULT_COUNTRY as string,
  KC_URL: process.env.REACT_APP_KEYCLOACK_URL as string,
  KC_REALM: process.env.REACT_APP_KEYCLOACK_REALM as string,
  KC_CLIENT_ID: process.env.REACT_APP_KEYCLOACK_CLIENT_ID as string,
  KC_CLIENT_SECRET: process.env.REACT_APP_KEYCLOACK_CLIENT_SECRET as string,
};
