/* Styles import */
import styles from "./result.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  GridCol,
  GridRow,
  Title,
} from "../../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../../../components/appBar";
import Footer from "../../../../components/footer";
import { flowUrl } from "../../../../utils/generator";
import TimeLine from "../../../../components/timeLine";

interface State {
  flowId?: string;
}

interface Props {
  rootStore: import("../../../../stores/rootStore").default;
}
const ResultEntity: React.FC<Props> = ({ rootStore }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [flowId, setFlowId] = useState("");

  useEffect(() => {
    if (id) {
      setFlowId(id);
    }
  }, [id]);

  const navigateTo = (url: string) => {
    navigate(flowUrl(url, id));
  };
  const screeningEntityResult = rootStore.screeningStore.screeningEntityResult;

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"SCREENING"} />

      <div className={styles.image}></div>

      <div className={styles.body}>
        <Title tag="h2">{i18n.t("pages.screening.entity.result.title")}</Title>

        {screeningEntityResult && (
          <div className={styles.message}>
            <div
              className={`${styles.traficLightBox} ${
                styles[screeningEntityResult.trafficLightColor.toLowerCase()]
              }`}
            ></div>

            <img
              className={styles.traficLight}
              src={require(
                `../../../../../statics/images/traffic-${screeningEntityResult.trafficLightColor.toLowerCase()}.svg`,
              )}
              alt={"traficLight"}
            />

            <p>{screeningEntityResult.message}</p>
          </div>
        )}
      </div>

      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => navigateTo("/screening/entity/choose-entity"),
            label: i18n.t("global.previous"),
          },
          {
            id: "next",
            onClick: () => navigateTo("/screening/end-user"),
            label: i18n.t("pages.screening.end-user.button"),
            primary: true,
          },
        ]}
      >
        {flowId && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(ResultEntity));
