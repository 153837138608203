/* Global import */
import {
  observable,
  action,
  runInAction,
  makeObservable,
  makeAutoObservable,
} from "mobx";
import i18n from "i18next";
import Moment from "moment";
/* Custiom import */
import Api from "../utils/api";
import { decode } from "../utils/jwt";

export default class AuthStore {
  @observable isLogged: boolean = false;
  @observable error: string = "";
  @observable loading: boolean = false;
  @observable checkLoading: boolean = false;

  rootStore: import("./rootStore").default;
  api: import("../utils/api").default;

  constructor(rootStore: import("./rootStore").default) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.api = new Api();

    this.checkIsLogged();
  }

  @action async login(email: string, password: string) {
    this.loading = true;
    this.clearError();
    Moment.locale("fr");
    try {
      const authResponse = await this.api.login(email, password);

      const expirationDate = new Date(
        new Date().getTime() + authResponse.expire_in
      );
      this.setError(i18n.t("pages.admin.users.API_E"));

      // if (authResponse.user.role == "API_E") {
      this.setError(i18n.t("pages.admin.users.API_E"));
      this.setError(i18n.t("pages.admin.users.expiration"));
      if (
        authResponse.token != undefined &&
        authResponse.access_token != undefined
      ) {
        //todo pas access_token
        window.localStorage.setItem("token", authResponse.token);
        window.localStorage.setItem("access_token", authResponse.access_token);

        this.setLogged(authResponse);
        await this.rootStore.tenantStore.getQuestionsResponse();
        // await new Promise(resolve => setTimeout(resolve, 30000));

        await this.rootStore.tenantStore.getTenantAhData();
        await this.rootStore.tenantStore.getTenanttempleteData();
        await this.rootStore.countriesStore.setCountryTable();

        //Addlog
        const logr = this.api.AddLog(
          this.rootStore.userStore.user?.tenantId || "",
          this.rootStore.userStore.user?.email || "",
          this.rootStore.userStore.user?.role || "",
          "Login",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Expiration=" + expirationDate,
          "",
          JSON.stringify(authResponse.user + authResponse.access_token) //todo decode JWT
        );
      }
    } catch (error: any) {
      this.setError(error);
    }
  }
  @action async checkIsConnect() {
    this.checkLoading = true;
    this.clearError();

    try {
      const token = window.localStorage.getItem("token");
      const authResponse = await this.api.checkIsLoged(token || "");

      this.isLogged = true;
      this.loading = false;
      this.checkLoading = false;
      this.rootStore.userStore.setUser(authResponse.user);
      this.rootStore.tenantStore.getEntities(authResponse.user?.tenantId!);
      this.watchSession(authResponse.token);
    } catch (error: any) {
      this.setError(error);
    }
  }

  @action async checkIsLogged() {
    this.checkLoading = true;
    this.clearError();

    try {
      const token = window.localStorage.getItem("token"); //todo use access_token
      const authResponse = await this.api.checkIsLoged(token || ""); //todo essayer de virer ce call

      this.setLogged(authResponse);
    } catch (error: any) {
      this.setError(error);
    }
  }

  @action setLogged(authResponse: AuthResponse) {
    this.isLogged = true;
    this.loading = false;
    this.checkLoading = false;
    this.rootStore.userStore.setUser(authResponse.user); //todo use access_token & decode JWT
    this.rootStore.jwtStore.setJwt(decode(authResponse.access_token)); //todo use access_token & decode JWT
    this.rootStore.tenantStore.getEntities(authResponse.user?.tenantId!);

    // Initialise default value for classification
    this.rootStore.eclabsStore.initializeDepartureCountry();
    this.watchSession(authResponse.access_token);
  }

  @action setError(error?: string) {
    this.loading = false;
    this.checkLoading = false;
    this.isLogged = false;
    this.error = error || "";
    // window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("token");
  }

  @action async logout() {
    this.clearError();
    this.rootStore.notificationStore.resetNotification();

    try {
      await this.api.logout();
      // window.localStorage.removeItem("access_token");
      window.localStorage.removeItem("token");
      this.isLogged = false;
    } catch (e) {}
  }

  @action clearError() {
    this.error = "";
  }

  @action watchSession(token: string) {
    const decodeToken = decode(token || localStorage.getItem("token") || "");
    const diff = (decodeToken.exp - decodeToken.iat) * 1000;
    let time = 0;

    const interval = setInterval(() => {
      time = time + 60000;

      if (time >= diff) {
        clearInterval(interval);
        this.setError(i18n.t("errors.token-expired"));
      }
    }, 60000);
  }
}
