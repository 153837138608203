/* Styles import */
import styles from "../license.module.css";

/* Global import */
import React, { Component, Fragment, useEffect, useState } from "react";
import { Title } from "../../../components/horizon-components-react/src/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";

import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../../components/appBar";
import Footer from "../../../components/footer";
import HeaderLicense from "../../../components/headerLicense";
import MatchingLinkedComponent from "../../../components/matching/linked";
import MatchingEquipmentComponent from "../../../components/matching/equipment";
import MatchingLicenseComponent from "../../../components/matching/license";
import Loader from "../../../components/loader";
import TimeLine from "../../../components/timeLine";
import {
  flowUrl,
  formatNumber,
  unformatNumber,
} from "../../../utils/generator";
import Api from "../../../utils/api";
import { flow, toJS } from "mobx";
import clsx from "clsx";
import { regulation } from "../../../utils/constant";
import license from "../../../components/matching/license";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

interface State {
  selectedEquipmentId?: number;
  type?: string;
  qtyImput: { [key: string]: number };
  amountImput: { [key: string]: number };
  amountInputFormatted: { [key: string]: string };
  qtyActual: { [key: string]: string };
  qtyOrdered: string;
  qtyInitial: { [key: string]: string };
  qtyVraieInitial: string;
  amountInitial: string;
  amountOrdered: string;
  vertical: string;
  horizontal: string;

  matchs: MatchedEquipment[];
}

interface Props extends InjectedProps {}

interface InjectedProps {
  rootStore: import("../../../stores/rootStore").default;
}

const ImputLicence: React.FC<Props> = ({ rootStore }) => {
  const navigate = useNavigate();
  const api = new Api();
  const goBack = () => {
    navigate(-1);
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [amountInput, setAmountInput] = useState<number>(0);
  const [qtyImput, setQtyImput] = useState<number>(0);

  const { id, type } = useParams() as {
    id?: string;
    type?: string;
  };

  const [isNegative, setIsNegative] = useState<{ [key: string]: boolean }>({});

  const [state, setState] = useState<State>({
    vertical: "top",
    horizontal: "center",
    type: type ? type : "NATIONAL",
    qtyImput: {},
    amountImput: {},
    amountInputFormatted: {},
    qtyActual: {},
    qtyOrdered: "",
    qtyInitial: {},
    qtyVraieInitial: "",
    amountInitial: "" as string,
    amountOrdered: "" as string,
    matchs: [] as MatchedEquipment[],
  });

  const { vertical, horizontal } = state;
  const [open, setSnackOpen] = useState(false);
  const [matchs, setMatchs] = useState<MatchedEquipment[]>([]);
  const [messageNotification, setMessageNotification] = useState("");
  const [statusSnack, setStatusSnack] = useState(0);
  const handleClose = () => {
    setSnackOpen(false);
  };

  const renameKeys = (obj: any) => {
    const keyMap = {
      amountOrdered: "amount_ordered",
      amountDelivered: "amount_delivered",
      quantityOrdered: "quantity_ordered",
      quantityDelivered: "quantity_delivered",
      unitPrice: "unit_price",
    } as any;

    return Object.keys(obj).reduce((acc: any, key: any) => {
      const newKey = keyMap[key] || key;
      acc[newKey] = obj[key];
      return acc;
    }, {});
  };

  useEffect(() => {
    const fetch = async () => {
      const linkedEquip = await api.getMatchedLicenseByFlowId(
        parseInt(id as string)
      );

      if (id) {
        setLoading(true);
        rootStore.licenseStore.getMatching(parseInt(id));

        const matching = toJS(linkedEquip);

        setLoading(false);
        setMatchs(matching);
        if (matching.length > 0) {
          setInputValues({
            quantities: matching.reduce(
              (acc, link) => {
                acc[link.id] = link.qtyFromFlow;
                return acc;
              },
              {} as { [key: number]: number }
            ),
            amounts: matching.reduce(
              (acc, link) => {
                acc[link.id] = link.amountFromFlow;
                return acc;
              },
              {} as { [key: number]: number }
            ),
          });
        }
      }
    };
    rootStore.countriesStore.getCountries();
    fetch();
  }, []);

  const handleSubmit = async (matchs: MatchedEquipment[]) => {
    console.log(toJS(matchs));
    const payload = {
      license_id: 0,
      equipment_id: 0,
      qtyRemaining: 0,
      amountRemaining: 0,
      amountEquip: parseInt(state.amountOrdered),
      quantityEquip: parseInt(state.qtyOrdered),
    };

    try {
      matchs.forEach(async (item) => {
        payload.license_id = item.license_id;
        payload.equipment_id = item.id;
        payload.qtyRemaining = 0; // => correspond à "actual" pas besoin pour FR/ML
        payload.amountRemaining = 0; // => correspond à "actual" pas besoin pour FR/ML
        payload.amountEquip = payload.amountEquip - parseInt(item.amount);
        payload.quantityEquip = payload.amountEquip - item.quantity;
      });
      await rootStore.licenseStore.sendLicenseUpdate(payload);
      setMessageNotification(i18n.t("notifications.form.success"));
      setStatusSnack(0);
      setSnackOpen(true);
      setTimeout(() => {
        goBack();
      }, 2000);
    } catch (error) {
      setMessageNotification(i18n.t("notifications.form.failure"));
      setStatusSnack(1);
      setSnackOpen(true);
    }

    setLoading(false);
    //navigate(flowUrl("/shipping", id));
  };
  interface InputValues {
    quantities: { [key: string]: number };
    amounts: { [key: string]: number };
  }

  const [inputValues, setInputValues] = useState<InputValues>({
    quantities: matchs.reduce(
      (acc, link) => {
        acc[link.id] = link.qtyFromFlow;
        return acc;
      },
      {} as { [key: string]: number }
    ),
    amounts: matchs.reduce(
      (acc, link) => {
        acc[link.id] = link.amountFromFlow;
        return acc;
      },
      {} as { [key: string]: number }
    ),
  });

  const handleQtyChange = (id: any, value: number) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      quantities: {
        ...prevValues.quantities,
        [id]: value,
      },
    }));
  };

  const handleAmountChange = (id: any, value: any) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      amounts: {
        ...prevValues.amounts,
        [id]: value,
      },
    }));
  };

  const renderLinkTable = (link: any) => {
    if (!link.amount_delivered) {
      link.amount_delivered = "0";
    }
    if (!link.amount_initial) {
      link.amount_initial = "0";
    }
    if (!link.amount_ordered) {
      link.amount_ordered = "0";
    }
    if (!link.amount_initial) {
      link.amount_initial = "0";
    }
    const formatNumberWithSpaces = (number: { toString: () => string }) => {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const amountRemainingFRML = (
      base: number,
      order: number,
      deliver: number,
      input: string
    ) => {
      //return base - order - parseInt(input?.replace(/\s/g, ""));

      const inputNumber = parseInt(input?.replace(/\s/g, ""));

      // Calculer la différence et formater le résultat avec des séparateurs d'espaces pour chaque millier
      const result = base - order - deliver - inputNumber;
      const formattedResult = result.toLocaleString();

      return formattedResult;
    };

    return (
      <div style={{ marginTop: "5%" }}>
        {link.map(
          (link: {
            id: any;
            license_number: any;
            model: any;
            pn: any;
            regulation: string;
            quantity_initial: number;
            quantity_ordered: number;
            quantity_delivered: number;
            amount_initial: string;
            amount_ordered: string;
            amount_delivered: string;
          }) => (
            <div key={link.id}>
              <h2 style={{ textAlign: "center" }}>
                Licence : {link.license_number}
              </h2>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <b>Désignation</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Part Number</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Quantité Actuelle</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Quantité à imputer</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Quantité Restante</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Montant Actuel</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Montant à imputer</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Montant Restant</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={link.id}>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "200px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {link.model}
                    </TableCell>
                    <TableCell align="center">{link.pn}</TableCell>
                    <TableCell align="center">
                      {link.regulation === "FR_MUNITIONS_LIST"
                        ? link.quantity_initial -
                          link.quantity_ordered -
                          link.quantity_delivered
                        : link.quantity_initial}
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        type="number"
                        variant="outlined"
                        value={inputValues.quantities[link.id] || ""}
                        onChange={(e) =>
                          handleQtyChange(link.id, parseInt(e.target.value))
                        }
                        InputProps={{
                          style: { height: "40px", width: "70px" },
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {link.regulation === "FR_MUNITIONS_LIST"
                        ? link.quantity_initial -
                          link.quantity_ordered -
                          link.quantity_delivered -
                          (inputValues.quantities[link.id] || 0)
                        : link.quantity_initial}
                    </TableCell>
                    <TableCell align="center">
                      {link.regulation === "FR_MUNITIONS_LIST"
                        ? parseInt(link.amount_initial) -
                          parseInt(link.amount_ordered) -
                          parseInt(link.amount_delivered)
                        : parseInt(link.amount_initial)}
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        type="text"
                        variant="outlined"
                        value={inputValues.amounts[link.id] || ""}
                        onChange={(e) =>
                          handleAmountChange(link.id, parseInt(e.target.value))
                        }
                        InputProps={{
                          style: { height: "40px", width: "170px" },
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {link.regulation === "FR_MUNITIONS_LIST"
                        ? amountRemainingFRML(
                            parseInt(link.amount_initial),
                            parseInt(link.amount_ordered),
                            parseInt(link.amount_delivered),
                            inputValues.amounts[link.id]?.toLocaleString() ||
                              "0"
                          )
                        : link.amount_initial}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          )
        )}
      </div>
    );
  };

  const matching = rootStore.licenseStore.matching;
  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"LICENSE_MNGT"} />
      <HeaderLicense pages="MATCHING" rootStore={rootStore} />
      {loading && <Loader />}
      <div className={styles.body}>
        <Title tag="h2">{i18n.t("pages.license.imput.title-frml")}</Title>
        {/* <Title tag="h2">{i18n.t("pages.license.imput.title")}</Title> */}
        {/* <p>{i18n.t("pages.license.imput.textHeader")}</p>
        {matching?.linked && matching.linked.length > 0 ? (
          matching.linked.map((link) => renderLinkTable(link))
        ) : (
          <p>Aucun lien trouvé.</p>
        )} */}
        {renderLinkTable(matchs)}
      </div>
      {matchs.length >= 0 && (
        <Footer
          justify="flex-end"
          buttons={[
            {
              id: "previous",
              onClick: () => goBack(),
              label: i18n.t("global.previous"),
              //  disabled: !activeSaveButton(),
            },
            {
              id: "save",
              //onClick: () => handleSave(),
              onClick: () => handleSubmit(matchs),
              label: i18n.t("global.save"),
              //  disabled: !activeSaveButton(),
            },
            {
              id: "next",
              onClick: () => {
                navigate(flowUrl("/shipping", id));
              },

              label: i18n.t("global.sub-expo-l_a"),

              primary: true,
              //  disabled: !activeSubmitButtonShipping(),
            },
          ]}
        >
          <TimeLine step={3} rootStore={rootStore} />
          <Snackbar
            autoHideDuration={2000}
            anchorOrigin={{
              vertical: state.vertical as any,
              horizontal: state.horizontal as any,
            }}
            open={open}
            onClose={handleClose}
            message=""
            key={vertical + horizontal}
          >
            <Alert
              onClose={handleClose}
              severity={statusSnack === 1 ? "error" : "success"}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {messageNotification}
            </Alert>
          </Snackbar>
        </Footer>
      )}
    </div>
  );
};

export default inject("rootStore")(observer(ImputLicence));
