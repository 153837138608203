import React, { SFC, ChangeEvent } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";
import MenuItem from "../MenuItem";
import MenuDivider from "../MenuDivider";
import { MenuItemProps } from "../MenuItem/MenuItem";
import { MenuDividerProps } from "../MenuDivider/MenuDivider";

type MenuProps = {
  className?: string;
  /** Show or hide the menu */
  show?: boolean;
};

const Menu: SFC<MenuProps> = ({ className, show = false, ...rest }) => {
  const children = React.Children.map(rest.children, (child, index) => {
    if (!React.isValidElement(child)) {
      return null;
    }
    if (child.type === MenuItem) {
      return React.cloneElement<MenuItemProps>(
        child as React.ReactElement<MenuItemProps>,
        {
          index: index,
        },
      );
    } else if (child.type === MenuDivider) {
      return React.cloneElement<MenuDividerProps>(
        child as React.ReactElement<MenuDividerProps>,
      );
    }
  });
  return (
    <div
      className={cx(
        classes["ds-menu-expand"],
        {
          [classes["ds--show"]]: show,
        },
        className,
      )}
      role="dialog"
    >
      <div className={cx(classes["ds-menu-expand__inner"])}>
        <ul className={cx(classes["ds-menu-expand__list"])}>{children}</ul>
      </div>
    </div>
  );
};

Menu.defaultProps = {
  show: false,
};

export default Menu;
