import React, { useState, useEffect } from "react";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

type SnackbarProps = {
  message: string;
  severity: "success" | "error" | "info" | "warning";
  position?:
    | "top"
    | "bottom"
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right"
    | "center";
  size?: "small" | "medium" | "large" | "auto";
  autoHideDuration?: number;
  onClose?: () => void;
};

const getAnchorOrigin = (
  position?: SnackbarProps["position"]
): SnackbarOrigin => {
  switch (position) {
    case "top-left":
      return { vertical: "top", horizontal: "left" };
    case "top-right":
      return { vertical: "top", horizontal: "right" };
    case "bottom-left":
      return { vertical: "bottom", horizontal: "left" };
    case "bottom-right":
      return { vertical: "bottom", horizontal: "right" };
    case "top":
      return { vertical: "top", horizontal: "center" };
    case "bottom":
      return { vertical: "bottom", horizontal: "center" };
    case "center":
    default:
      return { vertical: "top", horizontal: "center" };
  }
};

const SnackbarMsg: React.FC<SnackbarProps> = ({
  message,
  severity,
  position = "bottom",
  size = "medium",
  autoHideDuration = 3000,
  onClose,
}) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(true);
  }, [message, severity, position, size, autoHideDuration]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const anchorOrigin = getAnchorOrigin(position);

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      key={`${anchorOrigin.vertical}-${anchorOrigin.horizontal}`}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        variant="filled"
        sx={{
          width: "auto", // Utilisation d'une largeur automatique
          minWidth: "fit-content", // Garantit que la largeur s'adapte au contenu
          maxWidth: "100%", // Limitation de la largeur maximale si nécessaire
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMsg;
