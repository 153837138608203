import React from "react";
import i18n from "i18next";
import { Sync } from "@material-ui/icons";
import { MenuItem } from "@mui/material";
import { toJS } from "mobx";

export const generateFlowId = (flowType?: FlowType, id?: number): string => {
  let labelType = "";

  switch (flowType) {
    case "NEW_SALE":
      labelType = "Sale_";
      break;
    case "SPARES":
      labelType = "Spare_";
      break;
    case "MRO":
      labelType = "MRO_";
      break;
    case "INTERCOMPANY":
      labelType = "Inter_";
      break;
    case "RETROFIT":
      labelType = "RETRO_";
      break;
    case "TEMPORARY":
      labelType = "Temp_";
      break;
    case "TECHNOLOGY":
      labelType = "Tech_";
      break;
    case "IMPORT":
      labelType = "Import_";
      break;
    default:
      labelType = "";
      break;
  }

  return `${labelType}${id || ""}`;
};

export const flowUrl = (pageUrl: string, flowId?: string) => {
  let url = "";

  if (flowId) {
    url = `/flow/${flowId}`;
  }

  url += pageUrl;

  return url;
};

export const licenseStatus = {
  ACTIVE: {
    color: "#20bf6b",
    text: "ACTIVE",
    url: "/add",
  },
  EXPIRED: {
    color: "#c92e39",
    text: "EXPIRED",
    url: "/add",
  },
  DELETED: {
    color: "#c92e39",
    text: "DELETED",
    url: "/add",
  },
  PENDING: {
    color: "#fcc438",
    text: "PENDING",
    url: "/add",
  },
  DRAFT: {
    color: "#dc6eca",
    text: "DRAFT",
    url: "/request",
  },
  RENEW: {
    color: "#ff8000",
    text: "RENEW",
    url: "/request",
  },
};

export const flowStatus = {
  CLASSIFICATION: {
    text: "CLASSIFICATION",
    color: "#0f7cba",
    url: "/classification",
  },
  BASIC_SCREENING: {
    text: "BASIC_SCREENING",
    color: "#834187",
    url: "/screening/basic/us",
  },
  ARCHIVED: {
    text: "ARCHIVED",
    color: "#834187",
    url: "/screening/basic/us",
  },
  BASIC_SCREENING_REJECTED: {
    text: "BASIC_SCREENING",
    color: "#834187",
    url: "/screening/basic/us/result",
  },
  EMBARGO: {
    text: "EMBARGO",
    color: "#c92e39",
    url: "/exportability/results",
  },
  ENTITY: {
    text: "ENTITY",
    color: "#834187",
    url: "/screening/entity",
  },
  ENTITY_OBTAIN: {
    text: "ENTITY_OBTAIN",
    color: "#19967d",
    url: "/screening/entity",
  },
  ENTITY_REJECTED: {
    text: "ENTITY_REJECTED",
    color: "#c92e39",
    url: "/screening/entity/result",
  },
  ENTITY_PENDING: {
    text: "ENTITY_PENDING",
    color: "#fcc438",
    url: "/screening/entity/result",
  },
  END_USER: {
    text: "END_USER",
    color: "#fcc438",
    url: "/screening/end-user",
  },
  END_USER_REJECTED: {
    text: "END_USER_REJECTED",
    color: "#fcc438",
    url: "/screening/end-user/result",
  },
  END_USER_PENDING: {
    text: "END_USER_PENDING",
    color: "#fcc438",
    url: "/screening/end-user/result",
  },
  LICENSING: {
    text: "LICENSING",
    color: "#45fe77",
    url: "/license/matching",
  },
  READY: {
    text: "READY",
    color: "#45fe77",
    url: "/license/matching",
  },
};

export const redirectFlow = (status: FlowStatus, flowId: number) => {
  let uri = "";
  const statusEnum = flowStatus[status];

  if (statusEnum) {
    uri = `/flow/${flowId}${flowStatus[status].url}`;
  } else {
    uri = `/flow/${flowId}/classification`;
  }

  return uri;
};

export const redirectFlowLicense = (status: LicenseStatus, flowId: number) => {
  let uri = "";
  const statusEnum = licenseStatus[status];

  if (statusEnum) {
    uri = flowId + licenseStatus[status].url;
  } else {
    uri = "license/search";
  }

  return uri;
};

export const convertEntityNameToId = (
  entityName: string | undefined,
  entities: any[]
) => {
  const entity = entities.find((item) => item.id_entite === entityName);
  return entity ? entity.id : null;
};

export const convertEntityIdToName = (
  entityId: number | undefined,
  entities: any[]
) => {
  const entity = entities.find((item) => item.id === entityId);
  return entity ? entity.id_entite : null;
};

export const convertCountryIdToCode = (id: number, countries: any) => {
  const foundCountry = countries.find(
    (country: { id: number; label: string; value: string }) => country.id === id
  );
  return foundCountry ? foundCountry.value : null;
};

export const convertCountryCodeToId = (code: string, countries: any) => {
  const foundCountry = countries.find(
    (country: { value: string; id: number; label: string }) =>
      country.value === code
  );
  return foundCountry ? foundCountry.id : null;
};

export const fromRoman = (str: string) => {
  let result = 0;

  const decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
  const roman = [
    "M",
    "CM",
    "D",
    "CD",
    "C",
    "XC",
    "L",
    "XL",
    "X",
    "IX",
    "V",
    "IV",
    "I",
  ];

  for (let i = 0; i <= decimal.length; i++) {
    while (str.indexOf(roman[i]) === 0) {
      result += decimal[i];
      str = str.replace(roman[i], "");
    }
  }
  return result;
};

export const getCountriesListed = () => {
  const countriesListed = [
    { value: "63", label: i18n.t("global.countries.FR") },
    { value: "18", label: i18n.t("global.countries.BE") },
    { value: "67", label: i18n.t("global.countries.DE") },
    { value: "129", label: i18n.t("global.countries.EU3") },
    { value: "166", label: i18n.t("global.countries.EU4") },
    { value: "134", label: i18n.t("global.countries.EU1") },
    { value: "173", label: i18n.t("global.countries.EU2") },
    { value: "191", label: i18n.t("global.countries.UK") },
    { value: "202", label: i18n.t("global.countries.US") },
    { value: "33", label: i18n.t("global.countries.CA") },
    { value: "86", label: i18n.t("global.countries.IT") },
    { value: "168", label: i18n.t("global.countries.SP") },
    { value: "176", label: i18n.t("global.countries.TW") },
    { value: "160", label: i18n.t("global.countries.SG") },
    { value: "94", label: i18n.t("global.countries.KS") },
    { value: "38", label: i18n.t("global.countries.CN") },
  ];
  countriesListed.sort((a, b) => a.label.localeCompare(b.label));
  return countriesListed;
};

export function mapOrderLineValueReverse(value: string): string {
  switch (value) {
    case "NEW_LINE":
      return "0";
    case "CONFIRM_LINE":
      return "1";
    case "PARTIAL_LINE":
      return "2";
    case "COMPLETE_LINE":
      return "3";
    default:
      return "0";
  }
}

export function mapOrderLineValue(value: number): OrderLineType {
  switch (value) {
    case 0:
      return "NEW_LINE";
    case 1:
      return "CONFIRM_LINE";
    case 2:
      return "PARTIAL_LINE";
    case 3:
      return "COMPLETE_LINE";
    default:
      return "NEW_LINE";
  }
}

export const isValidNumberKey = (key: string) => {
  // Allow digits
  const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"];
  if (/^\d$/.test(key)) {
    return true;
  }
  if (allowedKeys.includes(key)) {
    return true;
  }
  return false;
};

export const getCurrencySymbol = (codeCurrency: Currency) => {
  const symbols: { [key in Currency]: string } = {
    EUR: "€",
    USD: "$",
    GBP: "£",
    CAD: "C$",
    SEK: "kr",
  };

  return symbols[codeCurrency] || "";
};
export const formatNumberWithSpaces = (number: number) => {
  if (number) {
    let formatted = number?.toString().replace(/\s/g, "");

    const parts = formatted?.split(".");
    let integerPart = parts[0];
    const decimalPart = parts.length > 1 ? "." + parts[1] : "";

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return integerPart + decimalPart;
  } else {
    return null;
  }
};

export const validateUsEccn = (value: string) => {
  if (value === "") {
    return true;
  }
  const us_eccnRegex = "^[0-9][A-E][0123569][0-9]{2}|^EAR99$";
  const usItarRegex =
    "^(Category )?((?=[DCLXVI])M*(C[D]|D?C*)(X[CL]|L?X*)(I[XV]|V?I*))";
  let itarBetweenOneAndTwentyOne = false;
  const matchingItar = value.match(usItarRegex);

  if (matchingItar) {
    const matchingItarInt = fromRoman(matchingItar[2]);

    if (matchingItarInt >= 1 && matchingItarInt <= 21) {
      itarBetweenOneAndTwentyOne = true;
    }
  }

  return value.match(us_eccnRegex) || itarBetweenOneAndTwentyOne;
};

export const validateNationalEccn = (value: string, dpCountry: number) => {
  if (value === "") {
    return true;
  }

  let nationalEccnRegex =
    /^[0-9][A-E][01234][0-9]{2}|5400|^NC$|^NL$|^NOT LISTED$/;

  switch (dpCountry) {
    case 67: //Germany
      nationalEccnRegex =
        /^[0-9][A-E][012349][0-9]{2}|5400|^NC$|^NL$|^NOT LISTED$/;
      break;
    case 191: //UK
      nationalEccnRegex =
        /^[0-9][A-E][01234][0-9]{2}|5400|^NC$|^NL$|^NOT LISTED$|^PL[0-9]{4}$/;
      break;
    case 33: //Canada
      nationalEccnRegex = /^[0-9]+-[A-Za-z0-9]+(\.[A-Za-z0-9]+)*$/;
      break;
  }

  const europeanMlRegex = /^ML[0-9]{1,2}|^AMA$/;
  const germanMlRegex = /^00[0-2][0-9][a-z]?|^AWG$/;
  const frenchHel = /^HEL|^hel$/;
  const hsRegex = /^[0-9]+([s.][0-9]+)*$/;

  return (
    value.match(nationalEccnRegex) ||
    value.match(europeanMlRegex) ||
    value.match(germanMlRegex) ||
    value.match(frenchHel) ||
    value.match(hsRegex)
  );
};

export const generateSelectOptionMui = (options: any[] | undefined) => {
  if (options) {
    const sortedOptions = options
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));

    return sortedOptions.map((option, index) => (
      <MenuItem
        key={index}
        value={option.value}
        style={{
          margin: "1px",
        }}
      >
        {option.label}
      </MenuItem>
    ));
  }

  return null;
};

export const generateSelectOption = (options: any[] | undefined) => {
  if (options) {
    const sortedOptions = options
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));

    return sortedOptions.map((option, index) => (
      <option key={index} value={option.value}>
        {option.label}
      </option>
    ));
  }

  return null;
};

export const generateQueryString = (object: { [key: string]: any }) => {
  const str = [];

  for (const key in object) {
    //alert(object[key])
    if (object[key] !== undefined && object[key] !== "") {
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`);
    }
  }

  return str.join("&");
};

export const generateLicenseTypeOptionsModern = (options: LicenseType[]) => {
  return options.map((option, index) => {
    if (option === "GLOBAL_OGEL") {
      return (
        <MenuItem key={index} value={"GLOBAL"}>
          {i18n.t(`global.license-type.${option}`)}
        </MenuItem>
      );
    }
    return (
      <MenuItem key={index} value={option}>
        {i18n.t(`global.license-type.${option}`)}
      </MenuItem>
    );
  });
};

export const generateLicenseTypeOptions = (options: LicenseType[]) => {
  return options.map((option, index) => {
    if (option === "GLOBAL_OGEL") {
      return (
        <option key={index} value={"GLOBAL"}>
          {i18n.t(`global.license-type.${option}`)}
        </option>
      );
    }
    return (
      <option key={index} value={option}>
        {i18n.t(`global.license-type.${option}`)}
      </option>
    );
  });
};

export function formatNumber(number: number): string {
  return number?.toLocaleString("fr-FR");
}

export function unformatNumber(str: string): number {
  return Number(str.replace(/\s/g, ""));
}

export function getRegutationByCountryIdAndType(
  id?: number,
  type?: string
): string[] {
  const notInArray = (value: any, array: any[]) => !array.includes(value);
  const otherEU = [67, 63, 168, 191];

  if (type === "US") {
    return ["US_DUAL_USE_LIST_EAR", "US_MUNITIONS_LIST_ITAR"];
  } else {
    if (id === 67) {
      return ["DE_DUAL_USE_GUTER", "DE_MUNITIONS_LIST"];
    } else if (id === 63) {
      return [
        // "FR_ANSSI",
        "FR_LIST_DOUBLE_USAGE",
        // "FR_ARRETE_DU_31_JUILLET_2014_HEL",
        "FR_MUNITIONS_LIST",
        // "FR_IMPORTATION",
      ];
    } else if (id === 168) {
      return ["ES_DUAL_USE_LIST", "ES_MUNITION_LIST"];
    } else if (id === 191) {
      return ["UK_MILITARY_LIST", "UK_DUAL_USE_LIST"];
    } else if (id === 33) {
      return ["CA_GROUP_1_DU", "CA_GROUP_2_ML"];
    } else if (notInArray(id, otherEU)) {
      return ["EU_DUAL_USE_LIST", "EU_MUNITIONS_LIST"];
    } else {
      return [
        "DE_DUAL_USE_GUTER",
        "DE_MUNITIONS_LIST",
        "ES_DUAL_USE_LIST",
        "ES_MUNITION_LIST",
        "EU_DUAL_USE_LIST",
        "EU_MUNITIONS_LIST",
        "FR_ANSSI",
        "FR_LIST_DOUBLE_USAGE",
        "FR_ARRETE_DU_31_JUILLET_2014_HEL",
        "UK_MILITARY_LIST",
        "UK_DUAL_USE_LIST",
        "FR_MUNITIONS_LIST",
        "FR_IMPORTATION",
      ];
    }
  }
}

export function getRegutationByCountry(id?: number): string[] {
  const notInArray = (value: any, array: any[]) => !array.includes(value);
  const otherEU = [67, 63, 168, 191];
  const base = [
    "EU_DUAL_USE_LIST",
    "EU_MUNITIONS_LIST",
    "US_DUAL_USE_LIST_EAR",
    "US_MUNITIONS_LIST_ITAR",
  ];
  const france = [
    "FR_ANSSI",
    "FR_LIST_DOUBLE_USAGE",
    "FR_ARRETE_DU_31_JUILLET_2014_HEL",
    "FR_MUNITIONS_LIST",
    "FR_IMPORTATION",
  ];
  const germany = ["DE_DUAL_USE_GUTER", "DE_MUNITIONS_LIST"];
  const unitedKingdom = ["UK_MILITARY_LIST", "UK_DUAL_USE_LIST"];
  const usa = ["US_DUAL_USE_LIST_EAR", "US_MUNITIONS_LIST_ITAR"];
  const canada = ["CA_GROUP_2_ML", "CA_GROUP_1_DU"];
  if (id === 67) {
    return usa.concat(germany);
  } else if (id === 63) {
    return usa.concat(france);
  } else if (id === 168) {
    //espagne qui pourrait aller dans le else du coup
    return base;
  } else if (id === 191) {
    return usa.concat(unitedKingdom);
  } else if (id === 33) {
    return usa.concat(canada);
  } else if (notInArray(id, otherEU)) {
    return usa.concat(["EU_DUAL_USE_LIST", "EU_MUNITIONS_LIST"]);
  } else {
    return base;
  }
}

export function base64ToBlob(base64: string, mimeType: string): Blob | void {
  if (!base64) {
    console.error("Base64 string is undefined or empty");
    return;
  }
  base64 = base64.replace(/\s+/g, "").replace(/[^A-Za-z0-9+/=]/g, "");
  const padding = "=".repeat((4 - (base64.length % 4)) % 4);
  base64 += padding;

  try {
    const byteChars = atob(base64);
    const byteNumbers = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i++) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  } catch (error) {
    console.error("Error converting base64 to blob:", error);
  }
}
