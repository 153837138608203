import * as React from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button, CircularProgress, styled } from "@mui/material";
import axios from "axios";
import configuration from "../../configuration";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface uploadFileInputProps {
  tenantId?: string;
  typeId?: number;
  slug: string;
  categorie: string;
  buttonText?: string;
  onFileUpload?: (file: File) => void;
}

const UploadFileInput: React.FC<uploadFileInputProps> = ({
  tenantId,
  typeId,
  slug,
  categorie,
  buttonText,
  onFileUpload,
}) => {
  const [loading, setLoading] = React.useState(false);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      const url = `${configuration.LARAVEL_API}/uploadFile`;
      const params = { tenantId, typeId, slug, categorie };

      try {
        await axios.post(url, formData, {
          params,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (onFileUpload) {
          onFileUpload(file);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        disabled={loading}
        sx={{ backgroundColor: "#0085AD" }}
      >
        {buttonText}
        <VisuallyHiddenInput type="file" onChange={handleFileChange} />
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      )}
    </div>
  );
};

export default UploadFileInput;
