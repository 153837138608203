import * as React from "react";
import cx from "classnames";
import classes from "./styles.module.scss";
import Fade from "../Fade";
import Incremental from "../Incremental";
import Button from "../Button";

import TimeRange from "@material-ui/icons/AccessTime";

interface ITimePickerProps {
  /** Title of the timer */
  title?: string;
  /** Defines the value of the timer */
  value?: string;
  /** Defines whether the timer dialog should be opened */
  isOpen?: boolean;
  /** Function called when the user change the time */
  onChange?: (value: string) => void;
}

interface ITimePickerState {
  hour: number;
  minute: number;
  open: boolean;
}

export default class TimePicker extends React.Component<
  ITimePickerProps,
  ITimePickerState
> {
  private hour?: number;
  private minute?: number;

  static defaultProps = {
    isOpen: false,
  };

  state = {
    hour: this.timeHour(this.props.value),
    minute: this.timeMinute(this.props.value),
    open: this.props.isOpen || false,
  };

  componentDidUpdate(prevProps: ITimePickerProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        hour: this.timeHour(this.props.value),
        minute: this.timeMinute(this.props.value),
      });
    }
  }

  render() {
    const { title, value } = this.props;

    return (
      <div className={cx(classes["ds-form-group"])}>
        <label htmlFor="textfield-label" className={cx(classes["ds-label"])}>
          {title}
        </label>
        <div className={cx(classes["ds-input-hour"])}>
          <input
            id="textfield-label"
            style={{ width: "100px", border: "1px solid black" }}
            type="time"
            onChange={this.inputChange}
            value={value}
          />
          <button
            className={cx(
              classes["ds-input-hour__btn"],
              classes["ds-input-hour--btn"],
            )}
            aria-label="Show clock"
            type="button"
            onClick={this.handleClick}
          >
            <TimeRange aria-hidden="true" />
          </button>
        </div>

        <Fade in={this.state.open}>
          <div
            className={cx(classes["ds-hours"], classes["ds-hours--dialog"], {
              [classes["ds-hours--hidden"]]: !this.state.open,
            })}
            role="dialog"
          >
            <div className={cx(classes["ds-hours__inner"])}>
              <div className={cx(classes["ds-hours__header"])}>Select Time</div>

              <div className={cx(classes["ds-hours__content"])}>
                <Incremental
                  isHour={true}
                  value={this.state.hour}
                  maxValue={23}
                  onChange={this.hourChange}
                  rollup={true}
                  className={cx(classes["ds-hours__hour-item"])}
                  classNameButtonUp={cx(
                    classes["ds-hours__navigation"],
                    classes["ds-hours__navigation--first"],
                  )}
                  classNameButtonDown={cx(
                    classes["ds-hours__navigation"],
                    classes["ds-hours__navigation--last"],
                  )}
                  labelDown="Increase hour"
                  labelUp="Decrease hour"
                />
                <Incremental
                  isHour={true}
                  value={this.state.minute}
                  maxValue={59}
                  onChange={this.minuteChange}
                  rollup={true}
                  className={cx(classes["ds-hours__minute-item"])}
                  classNameButtonUp={cx(
                    classes["ds-hours__navigation"],
                    classes["ds-hours__navigation--first"],
                  )}
                  classNameButtonDown={cx(
                    classes["ds-hours__navigation"],
                    classes["ds-hours__navigation--last"],
                  )}
                  labelDown="Increase minute"
                  labelUp="Decrease minute"
                />
              </div>

              <div className={cx(classes["ds-hours__footer"])}>
                <Button size="small" onClick={this.handleClick}>
                  Cancel
                </Button>
                <Button size="small" primary onClick={this.handleOK}>
                  OK
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }

  private timeHour(time?: string): number {
    return this.extractTime(0, time);
  }

  private timeMinute(time?: string): number {
    return this.extractTime(1, time);
  }

  private extractTime(splice: number, time?: string): number {
    if (time) {
      const val = time.split(":")[splice];
      if (val !== undefined) {
        return Number(val);
      }
    }
    return 0;
  }

  handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    this.setState({
      hour: this.timeHour(this.props.value),
      minute: this.timeMinute(this.props.value),
      open: !this.state.open,
    });
  };

  handleOK = (): void => {
    this.setState({ open: !this.state.open });
    this.handleChange(
      (this.state.hour || 0).toString().padStart(2, "0") +
        ":" +
        (this.state.minute || 0).toString().padStart(2, "0"),
    );
  };

  inputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.handleChange((e.target as HTMLInputElement).value);
  };

  hourChange = (hour: number) => {
    this.setState({ hour: hour });
  };

  minuteChange = (minute: number) => {
    this.setState({ minute: minute });
  };

  handleChange(time: string) {
    if (this.props.onChange) {
      this.props.onChange(time);
    }
  }
}
