/* Styles import */
import styles from "./eccn.module.css";

/* Global import */
import React, { Component } from "react";
import { Title } from "../../components/horizon-components-react/src/components";
import i18n from "i18next";

interface Props {
  element: Eccn;
  search: string;
  synonym: string;
  searchType: string;
  onClick: (eccn: Eccn) => void;
  onCodeClick: (eccn: Eccn) => void;
  seeDetails: boolean;
  selected?: boolean;
}
interface State {
  isFull: boolean;
}

export default class EccnComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isFull: false,
    };
  }
  NcToNotListed(chaine: string) {
    if (chaine.toUpperCase() === "NC") {
      chaine = "NOT LISTED";
    }
  
    return chaine;
  }
  affichage(chaine: string) {
    // alert(this.props.synonym)
    this.props.synonym
      .toString()
      .split(" ")
      .map((i, key) => {
        // alert(i)
        var pattern = new RegExp(i, "gi");
        if (i !== "or")
          chaine = chaine.replace(
            pattern,
            '<Text style="background-color:yellow;padding:0">' + i + "</Text>",
          );
      });

    return chaine;
  }
  render() {
    const description = this.props.element.description;

    return (
      <div
        id={this.props.element.code}
        className={`${styles.container} ${
          this.props.selected ? styles.selected : ""
        } ${
          this.props.element.code.includes(".") ||
          this.props.element.code.includes("(")
            ? styles.eccncomponentleft
            : ""
        }`}
        style={{
          width:
            this.props.element.code.includes(".") ||
            this.props.element.code.includes("(")
              ? "97%"
              : "100%",
        }}
        onClick={() => this.props.onClick(this.props.element)}
      >
        <Title tag="h4" className={styles.title}>
          <div className={styles.buttonsRow1}>
            <button
              className={styles.button1}
              type="button"
              onClick={() => this.props.onCodeClick(this.props.element)}
            >
              {this.NcToNotListed(this.props.element.code)}

              { this.props.searchType !== "CHECKLIST" && this.props.element.hs_code}
            </button>
          </div>
        </Title>
        <b>
          {this.props.element.publicListName.replace(
            "US Dual-Use List (EAR)",
            "US Commerce Control List (CCL)",
          )}
        </b>

        {
          <div>
            <p className={styles.description}>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.affichage(description),
                }}
              />
            </p>

            <b>
              {this.props.element.keywords !== "" &&
                this.props.element?.keywords &&
                this.props.element.keywords.includes(this.props.synonym) &&
                "EC Compliance info :"}
            </b>
            {this.props.element.keywords !== "" &&
              this.props.element?.keywords &&
              this.props.element.keywords.includes(this.props.synonym) && (
                <br />
              )}
            {this.props.element.keywords !== "" &&
              this.props.element?.keywords &&
              this.props.element.keywords
                .replace(/#/g, ",")
                .split(",")
                .map((i, key) => {
                  return (
                    <div key={key}>
                      {i.includes(this.props.synonym) && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.affichage(i + "."),
                          }}
                        />
                      )}
                    </div>
                  );
                })}

            {this.props.element.notes && (
              <div>
                <b>Notes : </b>
                <br />

                <div
                  dangerouslySetInnerHTML={{
                    __html: this.affichage(this.props.element.notes),
                  }}
                />
              </div>
            )}

            {this.props.element.tech_notes && (
              <p>
                <div>
                  <b>Notes Tech : </b>
                  <br />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.affichage(this.props.element.tech_notes),
                    }}
                  />
                </div>
              </p>
            )}

            {this.props.element.related_codes &&
              this.props.searchType !== "PARTNUMBER" && (
                <div>
                  <b>Related codes : </b>
                  <br />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.affichage(this.props.element.related_codes),
                    }}
                  />
                </div>
              )}

            {this.props.element.related_controls && (
              <p>
                <div>
                  <b>'Related controls :' </b>
                  <br />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.affichage(
                        this.props.element.related_controls,
                      ),
                    }}
                  />
                </div>{" "}
              </p>
            )}
          </div>
        }
        {this.props.seeDetails && (
          <div className={styles.buttonsRow}>
            <button
              className={styles.button}
              type="button"
              onClick={() => this.props.onCodeClick(this.props.element)}
            >
              {i18n.t("global.searchCode")}
            </button>
          </div>
        )}
      </div>
    );
  }
}
