import i18next from "i18next";
import detector from "i18next-browser-languagedetector";
export default i18next.use(detector).init({
  resources: {
    en: require("../../statics/locales/en.json"),
    fr: require("../../statics/locales/fr.json"),
    es: require("../../statics/locales/es.json"),
    de: require("../../statics/locales/de.json"),
    it: require("../../statics/locales/it.json"),
  },
  defaultNS: "alex",
  fallbackLng: "en",
});
