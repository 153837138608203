/* Styles import */
import styles from "./basicEntityChoose.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Title,
  GridRow,
  GridCol,
  Button,
} from "../../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../../../components/appBar";
import Footer from "../../../../components/footer";
import EntityComponent from "../../../../components/entity";
import Loader from "../../../../components/loader";
import { flowUrl } from "../../../../utils/generator";
import TimeLine from "../../../../components/timeLine";


interface InjectedProps {
  rootStore: import("../../../../stores/rootStore").default;
}
interface Props extends InjectedProps {}

const BasicEntityChoose: React.FC<Props> = ({ rootStore }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const navigateTowards = (url: string) => {
    navigate(flowUrl(url, id));
  };
  const goBack = () => {
    navigate(-1);
  };
  const [loading, setLoading] = useState(false);
  const [fuzzyResult, setFuzzyResult] = useState(false);
  const [flowId, setFlowId] = useState("");
  const [basicEntity, setBasicEntity] = useState<BasicEntity>();

  if (id) {
    setFlowId(id);
  }
useEffect(() => {

 let force = true;

    if (rootStore.screeningStore.basicEntities.length > 1)
      force = false;

 if (force) {
      handleSubmit(rootStore.screeningStore.basicEntities[0]);
    }

})
  const handleSubmit = async (value: BasicEntity) => {
    const tmpbasicEntity = value;
    //alert(tmpbasicEntity.name)

    setLoading(true);
    if (tmpbasicEntity) {
      await rootStore.screeningStore.screeningBasic(
        true,
        parseInt(flowId || "0"),
        tmpbasicEntity.source,
        tmpbasicEntity.name
      );
    } else {
      await rootStore.screeningStore.screeningBasic(
        false,
        parseInt(flowId || "0"),
        "",
        ""
      );
    }
    setLoading(false);

    const formattedUrl = flowUrl(
      "/screening/basic/us/result/" + tmpbasicEntity?.name
    );

    navigate("/screening/basic/us/result", {
      state: {
        company: tmpbasicEntity?.name
      }
    });

    //console.log(formattedUrl);
    //navigate(formattedUrl);
    setLoading(false);
  };

  const getCompany = () => {
    const company = rootStore.screeningStore.company;

    return company ? company : "";
  };

  const getCountry = () => {
    const countryId = rootStore.screeningStore.country;

    if (countryId) {
      const country = rootStore.countriesStore.getCountryById(countryId);

      return country ? country.name : "";
    }
  };

  const length = fuzzyResult
    ? rootStore.screeningStore.basicEntitiesFuzzy.length
    : rootStore.screeningStore.basicEntities.length;

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"SCREENING"} />

      {loading && <Loader />}

      <div className={styles.body}>
        <GridRow justify="space-between">
          <GridCol>
            <Title tag="h2">
              {i18n.t("pages.screening.entity.choose-entity.title")}
            </Title>
          </GridCol>
          <GridCol>
            <div className={styles.aligner}>
              {getCountry() !== undefined &&
                (rootStore.screeningStore.basicEntities.length ||
                  rootStore.screeningStore.basicEntitiesFuzzy.length > 0) && (
                  <p className={styles.message}>
                    {`${length}
                    ${i18n.t(
                      "pages.screening.entity.choose-entity.results-for"
                    )}
                    "${getCompany()}"
                    ${i18n.t("pages.screening.entity.choose-entity.in")}
                    "${getCountry()}"
                    `}
                  </p>
                )}

              {getCountry() === undefined &&
                (rootStore.screeningStore.basicEntities.length ||
                  rootStore.screeningStore.basicEntitiesFuzzy.length > 0) && (
                  <p className={styles.message}>
                    {`${length}
                    ${i18n.t(
                      "pages.screening.entity.choose-entity.results-for"
                    )}
                    "${getCompany()}"
            
                    `}
                  </p>
                )}
            </div>
          </GridCol>
        </GridRow>

        <div className={styles.entities}>
          {(rootStore.screeningStore.basicEntities.length !== 0 ||
            rootStore.screeningStore.basicEntitiesFuzzy.length !== 0) && (
            <p className={styles.message}>{i18n.t("global.fuzzy")}</p>
          )}
          {!fuzzyResult &&
            rootStore.screeningStore.basicEntities.map((basicEntity, index) => {
              return (
                <EntityComponent
                  rootStore={rootStore}
                  key={index}
                  basicEntity={basicEntity}
                  onClick={() => {
                    handleSubmit(basicEntity);
                  }}
                  selected={
                    basicEntity && basicEntity.source === basicEntity.source
                  }
                />
              );
            })}

          {fuzzyResult &&
            rootStore.screeningStore.basicEntitiesFuzzy.map(
              (basicEntityMap, index) => {
                return (
                  <EntityComponent
                    rootStore={rootStore}
                    key={index}
                    basicEntity={basicEntityMap}
                    onClick={() => {
                      handleSubmit(basicEntityMap);
                    }}
                    selected={
                      basicEntity &&
                      basicEntity.source === basicEntityMap.source
                    }
                  />
                );
              }
            )}
          {rootStore.screeningStore.basicEntitiesFuzzy.length !== 0 &&
            !fuzzyResult && (
              <div>
                <Button onClick={() => setFuzzyResult(true)}>
                  {i18n.t("global.view-more-interested")}
                </Button>
              </div>
            )}

          {rootStore.screeningStore.basicEntities.length === 0 &&
            rootStore.screeningStore.basicEntitiesFuzzy.length === 0 && (
              <p className={styles.message}>
                {i18n.t("pages.screening.entity.choose-entity.no-match")}
              </p>
            )}
        </div>
      </div>

      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => navigateTowards("/screening/basic/us"),
            label: i18n.t("global.previous"),
          },
          {
            id: "submit",
            onClick: () => handleSubmit(basicEntity!),
            label: i18n.t("global.noMatch"),
            primary: true,
          },
        ]}
      >
        {flowId && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(BasicEntityChoose));
