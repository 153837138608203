import * as React from "react";
import cx from "classnames";
import classes from "./styles.module.scss";
import uniqueId from "lodash.uniqueid";

interface IProps {
  /** The label to show before the textarea */
  label?: string;
  /** The callback for when the input value changes */
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    value: string,
  ) => void;
}

interface ITextAreaState {}

type ITextAreaProps = IProps &
  Pick<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    Exclude<keyof React.TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange">
  >;

export default class TextArea extends React.Component<
  ITextAreaProps,
  ITextAreaState
> {
  private id: string = uniqueId("TextArea-");

  render() {
    const { label, required, rows, ...rest } = this.props;
    const id = this.props.id || this.id;

    return (
      <div className={cx(classes["ds-form-group"])}>
        {label && (
          <label htmlFor={id} className={cx(classes["ds-label"])}>
            {label}
            {required && (
              <span className={cx(classes["ds-mandatory"])}> *</span>
            )}
          </label>
        )}
        <textarea
          id={id}
          {...rest}
          onChange={this.onTextChange}
          required={required}
          rows={rows || 4}
        />
      </div>
    );
  }

  onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (this.props.onChange) {
      this.props.onChange(e, e.target.value);
    }
  };
}
