/* Styles import */
import styles from "./tenants.module.css";

/* Global import */
import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Loading,
  SearchInput,
  TextInput,
  Button,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { observer, inject } from "mobx-react";

/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import Tenant from "../../../components/tenant";
import { decode } from "../../../utils/jwt";

interface State {
  search: string;
  name?: string;
  erp: boolean;
  wizard: boolean;
  search_type: number;
  screening: string;
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const Tenants: React.FC<Props> = ({ rootStore }) => {
  const jwt: JwtData =
    decode(window.localStorage.getItem("access_token") || "") || [];
  const jwtRoles: string[] = jwt.realm_access?.roles;

  const [state, setState] = useState({
    search: "",
    erp: false,
    wizard: false,
    search_type: 1,
    screening: "USE_BUILT_IN_SCREENING",
    name: "",
  });

  useEffect(() => {
    rootStore.tenantStore.getTenants();
  }, []);

  const submit = () => {
    const { name, erp, screening } = state;

    setState({
      ...state,
      name: "",
      erp: false,
      search_type: 1,
      screening: "USE_BUILT_IN_SCREENING",
    });

    if (name && screening) {
      rootStore.tenantStore.submitTenant({
        name,
        erp,
        screening,
      });
    }
  };
  const { search } = state;
  const addTenant = () => {
    return (
      jwtRoles?.includes("SUPER_ADMINISTRATOR") && (
        <tr>
          <td style={{ border: "0px solid black", width: "15%" }}>
            <TextInput
              style={{ marginBottom: "-24px" }}
              placeholder={i18n.t("pages.admin.tenants.table.name")}
              value={state.name || ""}
              onChange={(e) => setState({ ...state, name: e.target.value })}
            />
          </td>
          <td style={{ border: "0px solid black", width: "10%" }}></td>
          <td style={{ border: "0px solid black", width: "15%" }}></td>
          <td style={{ border: "0px solid black", width: "10%" }}>
            <select
              id="erp"
              value={Boolean(state.erp).toString() || ""}
              onChange={(e) =>
                setState({ ...state, erp: e.target.value === "true" })
              }
            >
              <option value="false">
                {i18n.t("pages.admin.tenants.table.erp.false-value")}
              </option>
              <option value="true">
                {i18n.t("pages.admin.tenants.table.erp.true-value")}
              </option>
            </select>
          </td>
          <td style={{ border: "0px solid black", width: "15%" }}>
            <select
              id="search_type"
              value={state.search_type}
              onChange={(e) => {}}
            >
              <option value="1">Keyword</option>
              <option value="2">Part number</option>
              <option value="3">Decision tree</option>
            </select>
          </td>
          <td style={{ border: "0px solid black", width: "10%" }}>
            <select
              id="wizard"
              value={Boolean(state.wizard).toString() || ""}
              onChange={(e) =>
                setState({ ...state, wizard: e.target.value === "true" })
              }
            >
              <option value="false">
                {i18n.t("pages.admin.tenants.table.wizard.false-value")}
              </option>
              <option value="true">
                {i18n.t("pages.admin.tenants.table.wizard.true-value")}
              </option>
            </select>
          </td>
          <td style={{ border: "0px solid black", width: "15%" }}>
            <select
              id="screening"
              value={state.screening || ""}
              onChange={(e) =>
                setState({ ...state, screening: e.target.value })
              }
            >
              <option value="USE_BUILT_IN_SCREENING">EC Compliance</option>
              <option value="USE_BVD">
                {i18n.t("pages.admin.tenants.table.screening.bvd-value")}
              </option>
            </select>
          </td>

          <td style={{ border: "0px solid black", width: "10%" }}>
            <select
              id="screening"
              value={state.screening || ""}
              onChange={(e) => {}}
            >
              <option value="No ">No </option>
              <option value="MEU">MEU</option>
            </select>
          </td>

          <td style={{ border: "0px solid black", width: "10%" }}>
            <Button disabled={!state.name} onClick={() => submit()}>
              {i18n.t("pages.admin.tenants.add")}
            </Button>
          </td>
        </tr>
      )
    );
  };

  return (
    <div>
      <AppBar selectedPage="ADMIN" rootStore={rootStore} />

      <HeaderAdmin pages="TENANTS" rootStore={rootStore} />

      <Card className={styles.body}>
        <div className={styles.headerCard}>
          <CardHeader title={i18n.t("pages.admin.tenants.title")} />
          <SearchInput
            id="search"
            value={state.search || ""}
            onChange={(e) => setState({ ...state, search: e.target.value })}
            placeholder={i18n.t("pages.admin.tenants.search-placeholder")}
          />
        </div>

        <table className="table" style={{ tableLayout: "fixed" }}>
          <th>
            <td style={{ border: "0px solid black", width: "13%" }}>
              <span>{i18n.t("pages.admin.tenants.table.name")}</span>
            </td>
            <td style={{ border: "0px solid black", width: "10%" }}></td>
            <td style={{ border: "0px solid black", width: "15%" }}></td>

            <td style={{ border: "0px solid black", width: "7%" }}>
              <span>{i18n.t("pages.admin.tenants.table.erp.title")}</span>
            </td>

            <td style={{ border: "0px solid black", width: "13%" }}>
              {" "}
              <span>{i18n.t("pages.admin.tenants.table.erp.keyword")}</span>
            </td>
            <td style={{ border: "0px solid black", width: "12%" }}>
              <span>{i18n.t("pages.admin.tenants.table.wizard.title")}</span>
            </td>
            <td style={{ border: "0px solid black", width: "15%" }}>
              <span>{i18n.t("pages.admin.tenants.table.screening.title")}</span>
            </td>

            <td style={{ border: "0px solid black", width: "10%" }}>
              <span>Custom screening</span>
            </td>
            <td style={{ border: "0px solid black", width: "10%" }}></td>
            <td style={{ border: "0px solid black", width: "5%" }}></td>
          </th>
        </table>
        <table className="table" style={{ tableLayout: "fixed" }}>
          {addTenant()}
        </table>
        {rootStore.tenantStore.tenants
          .filter((tenant) =>
            tenant.name.toLowerCase().includes(search.toLowerCase())
          )
          .map((tenant, index) => {
            //console.log(tenant);
            return (
              <table
                className="table table-striped"
                style={{ tableLayout: "fixed" }}
              >
                <thead></thead>
                <tbody>
                  <Tenant
                    rootStore={rootStore}
                    key={index}
                    id={tenant.id}
                    name={tenant.name}
                    homepageFilename={tenant.homePageFilename}
                    homepageUploadDate={tenant.homePageUploadDate}
                    erp={tenant.erpAccess.toString()}
                    wizard={tenant.wizard?.toString()!}
                    screening={tenant.screening}
                    productLineFilename={tenant.productLineFilename}
                    productLineUploadDate={tenant.productLineUploadDate}
                  />
                </tbody>
              </table>
            );
          })}

        {rootStore.tenantStore.loading && <Loading />}
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(Tenants));
