import React, { SFC } from "react";
import cx from "classnames";
import classes from "../Card/styles.module.scss";

type Props = {
  className?: string;
  title?: string;
};

const CardHeader: SFC<Props> = ({ className, title, children }) => (
  <div className={cx(className, classes["ds-card__header"])}>
    {title && <h3 className={classes["ds-card__title"]}>{title}</h3>}
    {children}
  </div>
);

export default CardHeader;
