import * as React from "react";
import { ChangeEvent, ChangeEventHandler, InputHTMLAttributes } from "react";
import ReactDOM from "react-dom";
import cx from "classnames";
import classes from "./styles.module.scss";
import uniqueId from "lodash.uniqueid";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  /** If `true`, the component will be better suited to dark backgrounds. */
  dark?: boolean;
  /** The label to show next to the <input /> */
  label?: string;
  /** Help text below input */
  helperText?: string;
  /** Error text below input */
  errorText?: string;
  /** The callback for when the input value changes */
  //onChange?: (event: React.ChangeEvent<HTMLInputElement>,value: string) => void;
  //onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  //onChange?: ChangeEventHandler<HTMLInputElement>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  capture?: boolean | "user" | "environment"; // Mettez capture en conformité avec le HTML5
}

interface ITextInputState {
  invalid: boolean;
  hasValidated: boolean;
}

type ITextInputProps = IProps &
  Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    Exclude<keyof React.InputHTMLAttributes<HTMLInputElement>, "onChange">
  >;

export default class TextInput extends React.Component<
  ITextInputProps,
  ITextInputState
> {
  private supportedType: Array<string> = [
    "email",
    "number",
    "password",
    "tel",
    "text",
    "url",
  ];
  private id: string = uniqueId("TextInput-");
  private valid: boolean = false;

  state = { invalid: false, hasValidated: false };

  render() {
    const {
      onChange,
      label,
      type,
      required,
      checked,
      placeholder,
      value,
      helperText,
      errorText,
      className,
      dark,
      ...rest
    } = this.props;
    const { invalid, hasValidated } = this.state;
    const id = this.props.id || this.id;

    if (!type || (type && this.supportedType.includes(type))) {
      return (
        <div
          className={cx(
            classes["ds-form-group"],
            {
              [classes["ds-form--dark"]]: dark,
              [classes["ds-form--error"]]: required && hasValidated && invalid,
              [classes["ds-form--success"]]:
                required && hasValidated && !invalid,
            },
            className,
          )}
        >
          {label && (
            <label htmlFor={id} className={cx(classes["ds-label"])}>
              {label}
              {required && (
                <span className={cx(classes["ds-mandatory"])}> *</span>
              )}
            </label>
          )}
          <input
            id={id}
            value={value}
            type={type || "text"}
            placeholder={placeholder || label}
            {...rest}
            onChange={this.onInputChange}
            required={required}
            aria-required={required}
            onInvalid={this.handleInvalid}
            aria-invalid={invalid}
          />
          <div className={cx(classes["ds-form-helptext"])}>
            {hasValidated ? (invalid ? errorText : helperText) : helperText}
          </div>
        </div>
      );
    } else {
      const message = `The type '${type}' is unknow or isn't supported. Please see documentation for more information.`;
      console.error(message);
      return (
        <div className={cx(classes["ds-form--error"])}>
          <label className={cx(classes["ds-label--info"])}>{message}</label>
        </div>
      );
    }
  }

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      //this.props.onChange(e, e.target.value);
      this.props.onChange(e);
    }
  };

  handleInvalid = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ invalid: true, hasValidated: true });
    e.preventDefault();
  };
}
