/* Styles import */
import styles from "./license.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Title,
  GridRow,
  GridCol,
  Checkbox,
  SearchInput,
  Table,
  TextInput,
  TableHead,
  TableRow,
  TableBody,
  TableHeaderCell,
  Pagination,
  IconButton,
  Radio,
  Flag,
  //DialogActions,
  //DialogContent,
  //Dialog,
  Button,
} from "../../components/horizon-components-react/src/components";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import i18n from "i18next";
import { Hidden, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteSweep";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { inject, observer } from "mobx-react";
import AutoComplete from "../../components/autoComplete";
import { FormLabel } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Create";
import FolderOpenIcon from "@material-ui/icons/Folder";
import ViewIcon from "@material-ui/icons/Loupe";
import fileDownload from "js-file-download";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Api from "../../utils/api";
import HeaderReport from "../../components/headerReport";
/* Custom import */
import AppBar from "../../components/appBar";
import Loader from "../../components/loader";
import {
  generateFlowId,
  redirectFlow,
  flowStatus,
  convertEntityNameToId,
  convertEntityIdToName,
} from "../../utils/generator";
import HeaderFlow from "../../components/headerFlow";
import { flowStatusList, flowType } from "../../utils/constant";
import { UnsubscribeTwoTone } from "@material-ui/icons";
import { unset } from "lodash";
import HeaderReportMenu from "../../components/headerReportMenu";
import licenseStore from "../../stores/licenseStore";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { FormGroup } from "@mui/material";
import { toJS } from "mobx";
import configuration from "../../configuration";

interface InjectedProps {
  rootStore: import("../../stores/rootStore").default;
}

type DataItem = {
  line_number: number;
  number_licence: string | number;
  first_destination_country_id: string;
  first_destinatary: string;
  destination_country_id: string;
  customerCompagny: string;
  order_num: string;
  model: string;
  partNumber: string;
  ref_tech_eth: string;
  nationalEccn: string;
  delivery_date: string;
  CNR_CUF: string;
  validationDate: string;
  amountCommand: number;
  amountDelivered: number;
  quantityCommand: number;
  quantityDelivered: number;
};

type MsgType = {
  code_msg: string;
  Regle: string;
  Message: string;
  Lib_url1: string;
  Url1: string;
  Lib_url2: string;
  Url2: string;
  Lib_url3: string;
  Url3: string;
};

interface Props extends InjectedProps {}
interface State {
  flowType?: FlowType;
  creationDateBegin?: string;
  dualUse?: boolean;
  usEar?: boolean;
  status?: FlowStatus;
  creationDateEnd?: string;
  ml?: boolean;
  usItar?: boolean;
  search?: string;
  departureCountry: number;
  endUserCountry: number;
  customer?: string;
  flows: FlowDto[];
  page: number;
  totalResults: number;
  loading: boolean;
  filter: Filter;
  filtrearchive: string;
  desId: number;
  open: boolean;
  open1: boolean;
}
type Keys = keyof State;

const ReportingDGA: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const [msg, setMsg] = useState<MsgType>({
    code_msg: "",
    Regle: "",
    Message: "",
    Lib_url1: "",
    Url1: "",
    Lib_url2: "",
    Url2: "",
    Lib_url3: "",
    Url3: "",
  });
  const [flows, setFlows] = useState<FlowDto[]>([]);
  const [newFlows, setFilteredFlows] = useState<FlowDto[]>([]);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(false);
  const [departureCountry, setDepartureCountry] = useState(0);
  const [endUserCountry, setEndUserCountry] = useState(0);
  const [customer, setCustomer] = useState("");
  const [filter, setFilter] = useState("ALL");
  const [filtrearchive, setFiltreArchive] = useState("ALL");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [desId, setDesId] = useState(0);
  const [creationDateEnd, setDateEnd] = useState("");
  const [creationDateBegin, setDateBegin] = useState("");
  const [ml, setMl] = useState(true);
  const [usItar, setUsItar] = useState(false);
  const [countries, setCountries] = useState<[]>([]);
  const [entite, setEntite] = useState<string>(
    convertEntityNameToId(
      rootStore.userStore.user?.entite,
      toJS(rootStore.tenantStore.entities)
    )
  );

  const location = useLocation();
  let urlEntite = "";
  let dpc = "";
  if (location.state) {
    urlEntite = location.state.entite;
    dpc = location.state.pays;
  } else {
    urlEntite = "ALL";
    dpc = "";
  }

  const cmsg = "EXP069";
  useEffect(() => {
    const fetchData0 = async () => {
      const response = await axios.get(
        `${configuration.LARAVEL_API}/countries_departure`
      );
      setCountries(response.data);
    };
    const fetchData = async () => {
      const msg = await api.GcodeToMsg(cmsg);
      setMsg(msg);
      rootStore.countriesStore.getCountries();
      rootStore.countriesStore.getCountriesDeparture();
    };
    const fetchData2 = async () => {
      const searchFlowObject = {
        endUserCountry: endUserCountry as number,
        page: page,
        filter: filtrearchive,
        open: open,
        open1: open1,
        desId: 0,
        ml: ml,
        usItar: usItar,
        search: "",
        status: "" as FlowStatus,
        //flowType: flowType as any,
        customer: customer as string,
        departureCountry: departureCountry as number,
        pageNumber: (page - 1) as number,
        creationDateBegin: creationDateBegin,
        creationDateEnd: creationDateEnd,
      };
      const searchResult =
        await rootStore.flowStore.searchFlow(searchFlowObject);
      const test = await matchCountryByValue(searchResult, dpc);
      setFlows(test ? (test as unknown as FlowDto[]) : ([] as FlowDto[]));
    };
    fetchData0();
    fetchData();
    fetchData2();
  }, [creationDateBegin, creationDateEnd, filtrearchive, page]);
  type Pays = {
    id: number;
    value: string;
    label: string;
  };
  const matchCountryByValue = async (data: any, countryCode: any) => {
    const response = await axios.get(
      `${configuration.LARAVEL_API}/countries_departure`
    );
    setCountries(response.data);
    const pays = response.data;

    const country = pays.find((c: { value: any }) => c.value === countryCode);
    const pays2carac = country ? country.label : null;
    const filtered = data.results.filter(
      (curr: { departureCountryName: any }) =>
        curr.departureCountryName === pays2carac
    );
    return filtered;
  };

  const search = async (params: SearchFlowParams) => {
    setLoading(true);

    const searchResult = await rootStore.flowStore.searchFlow(params);
    const params1 = new URLSearchParams(/*history.*/ location.search);
    //const entite = params1.get("entite");
    const entite = urlEntite;

    let flows = [];
    //alert(creationDateBegin)
    if (searchResult)
      searchResult.results.map((flow, index) => {
        //alert(moment(searchResult.results[index].updatedAt).format('YYYY MM DD'))
        let trouver = false;

        for (const equipment of flow.flowEquipments) {
          if (equipment.equipmentType === entite) {
            trouver = true;
          }
        }
        if (entite !== "ALL" && !trouver) delete searchResult.results[index];
        else if (
          "ARCHIVED" !== searchResult.results[index].status &&
          filtrearchive === "ARCHIVED"
        )
          delete searchResult.results[index];
        else {
          if (
            "ARCHIVED" === searchResult.results[index].status &&
            filtrearchive === "NOARCHIVED"
          )
            delete searchResult.results[index];
          else if (
            "France" !== searchResult.results[index].departureCountryName
          )
            delete searchResult.results[index];
          else if (!searchResult.results[index].hasMlEccn)
            delete searchResult.results[index];

          if (
            params.departureCountry !== undefined &&
            params.departureCountry !== 0 &&
            params.departureCountry !== flow.departureCountryId
          )
            delete searchResult.results[index];

          if (
            params.endUserCountry !== undefined &&
            params.endUserCountry !== 0 &&
            params.endUserCountry !== flow.destinationCountryId
          )
            delete searchResult.results[index];
          if (
            params.customer !== undefined &&
            params.customer !== "" &&
            params.customer !== flow.customer
          )
            delete searchResult.results[index];

          if (searchResult.results[index] !== undefined) {
            if (
              creationDateEnd &&
              moment(searchResult.results[index].updatedAt).format(
                "YYYY MM DD"
              ) > moment(creationDateEnd).format("YYYY MM DD")
            ) {
              delete searchResult.results[index];
            } else if (
              creationDateBegin &&
              moment(searchResult.results[index].updatedAt).format(
                "YYYY MM DD"
              ) < moment(creationDateBegin).format("YYYY MM DD")
            ) {
              delete searchResult.results[index];
            }
          }
        }
      });

    setLoading(false);
    setFlows((searchResult as any) ? searchResult?.results : ([] as any));
    setTotalResults(searchResult ? searchResult.totalResults : 0);
    setPage(1);
  };
  /*const handleDepartureCountry = (departureCountry: string) => {
    const intDepartureCountry = parseInt(departureCountry);
    setDepartureCountry(intDepartureCountry);
  };*/
  /*const handleChange = (key: Keys, value: string | boolean | number) => {
    let newState: Partial<State> = {};

    if (key === "filter" && value === "ALL") {
      newState.ml = false;
      newState.usItar = false;
    } else {
      newState[key] = value as any;
    }

    search({
      creationDateBegin: creationDateBegin,
      creationDateEnd: creationDateEnd,
      //flowType: flowType as any,
      departureCountry: departureCountry,
      endUserCountry: endUserCountry,
      customer: customer,
      ml: ml,
      pageNumber: page - 1,
      //search: search as any,
      status: status as any,
      usItar: usItar,
      ...newState,
    });
  };*/

  const submitDelete = async (userId: number) => {
    const searchResult = await rootStore.flowStore.deleteFlow(userId);

    window.location.reload();
  };

  /*
 async exportXLS ()  {
      //const columns = myRef.current.visibleColumns;
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

      const fileExtension = '.xlsx';
      var temp:FlowDto[]= [];
 flows.map((flow, index) => {
      temp.push(flows[index])

 })
let excel_res=await api.writeexcel(temp)
 
      //console.log(flows)
      //  const ws = XLSX.utils.json_to_sheet(temp);
      //  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      //  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //  const data = new Blob(excel_res, {type: fileType});
     //let file:File
    // fileDownload(file, $excel_res)

  //  const a = document.createElement("a");
 // a.href = `${process.env.REACT_APP_PUBLIC}/`+excel_res;
 // a.download = "reporting_DGA.xlsx";
 // a.click();
    // FileSaver.saveAs(`${process.env.REACT_APP_PUBLIC}/`+excel_res, 'reporting_DGA' + fileExtension);


 const link = document.createElement('a');

      
      /*
      link.setAttribute('href', url);
      link.setAttribute('download', "reporting_DGA.xlsx");
      link.style.position = 'absolute';
      link.style.visibility = 'hidden';
 
      document.body.appendChild(link);
    
      link.click();
    
      document.body.removeChild(link);
    }; 


*/

  const createHeaderXls = (worksheet: any) => {
    // Ajouter la ligne principale (row0) en premier
    const row0 = worksheet.addRow([
      "n° ligne",
      "Référence de la licence (1)",
      "Destination",
    ]);

    worksheet.mergeCells("A1:A2");
    row0.getCell("A").alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };

    worksheet.mergeCells("B1:B2");
    row0.getCell("B").alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };

    worksheet.mergeCells("C1:F1");
    row0.getCell("C").alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };

    worksheet.mergeCells("G1:L1");
    row0.getCell("G").alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    row0.getCell("G").value = "Fourniture";

    worksheet.mergeCells("M1:N1");
    row0.getCell("M").alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    row0.getCell("M").value =
      "Certificat de non réexportation et/ou Certificat d'utilisation finale";

    worksheet.mergeCells("O1:P1");
    row0.getCell("O").alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    row0.getCell("O").value = "Montant";

    worksheet.mergeCells("Q1:R1");
    row0.getCell("Q").alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    row0.getCell("Q").value = "Quantité";

    const row1 = worksheet.getRow(2);
    row1.getCell("C").value = "Pays de première destination";
    row1.getCell("D").value = "Premier destinataire";
    row1.getCell("E").value = "Pays destinataire";
    row1.getCell("F").value = "Destinataire final";
    row1.getCell("G").value = "Référence de l'acte liant (2)";
    row1.getCell("H").value = "Appelation industrielle (3)";
    row1.getCell("I").value = "Référence industrielle (4)";
    row1.getCell("J").value = "Référence technique étatique (5)";
    row1.getCell("K").value = "Référence nationale (ML) (6)";
    row1.getCell("L").value = "Date de l'expédition";
    row1.getCell("M").value = "CNR / CUF (7)";
    row1.getCell("N").value = "Date de validation du CNR et/ou CUF (8)";
    row1.getCell("O").value = "Commandé (9)";
    row1.getCell("P").value = "Livré au cours du semestre (10)";
    row1.getCell("Q").value = "Commandé (9)";
    row1.getCell("R").value = "Livré au cours du semestre (10)";

    row0.height = 35;
    row1.height = 35;
    row1.eachCell((cell: any) => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });
  };

  /*
   async exportXLS() {

    var dateJour = new Date();
    var afDate = moment(dateJour).format("YYYY-MM-DD");
    const workbook = new Workbook();

    for (let index = 0; index < flows.length; index++) {

      const flow = flows[index];
      if (!flow) {
        console.error(`Flow at index ${index} is undefined`);
        continue; // Passer à l'itération suivante
    }
          // Vérifie si le flux a au moins un équipement
        if (flow.flowEquipments && flow.flowEquipments.length > 0) {
            // Pour chaque flux, nous créons une nouvelle feuille
            const worksheet = workbook.addWorksheet(`data_${index + 1}`);
            createHeaderXls(worksheet);

            worksheet.columns.forEach(column => {
                column.width = 20;
                column.style = { 
                    alignment: { 
                        wrapText: true, 
                        vertical: 'middle', 
                        horizontal: 'center' 
                    } 
                };
            });

            // Ajouter les données du flux actuel à la feuille de calcul
            const dataItems = await loadDataToXlsForFlow(flow);
            dataItems.forEach((item:any) => {
                const row = worksheet.addRow([
                    item.line_number,
                    item.number_licence,
                    item.first_destination_country_id,
                    item.first_destinatary,
                    item.destination_country_id,
                    item.customerCompagny,
                    item.order_num,
                    item.model,
                    item.partNumber,
                    item.ref_tech_eth,
                    item.nationalEccn,
                    item.delivery_date,
                    item.CNR_CUF,
                    item.validationDate,
                    item.amountCommand,
                    item.amountDelivered,
                    item.quantityCommand,
                    item.quantityDelivered
                ]);

                // Appliquer le style pour chaque cellule de la ligne
                row.eachCell(cell => {
                  cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
              });
              row.height = 25;  // ajuste la hauteur de la ligne en fonction du nombre de cellules
            });
        }
    };

    workbook.xlsx.writeBuffer().then((data: any) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, afDate + " reporting_DGA " + ".xlsx");
    });
}
*/

  const loadDataToXlsForFlow = async (flow: any) => {
    const temp: DataItem[] = [];

    for (const equipment of flow.flowEquipments) {
      // Si l'orderLine est "NEW_LINE", passez à l'itération suivante
      if (equipment.orderLine === "NEW_LINE") {
        continue;
      }

      const numberLicence = await fetchLicenseId(equipment.id, "NATIONAL");

      const dataItem: DataItem = {
        line_number: temp.length + 1,
        number_licence: numberLicence ? String(numberLicence) : "",
        first_destination_country_id: flow.destinationCountryName || "",
        first_destinatary: flow.destinationCountryName || "",
        destination_country_id: flow.destinationCountryName || "",
        customerCompagny: flow.customer || "",
        order_num:
          flow.order_num && flow.order_num !== "null"
            ? flow.order_num
            : "" || "",
        model: equipment.model || "",
        partNumber: equipment.partNumber || "",
        ref_tech_eth: "", // TODO add reff_tech when we got it
        nationalEccn: equipment.nationalEccn || "",
        delivery_date: flow.delivery_date || "",
        CNR_CUF: "",
        validationDate: equipment.validationDate || "",
        amountCommand:
          equipment.orderLine === "COMPLETE_LINE" ? equipment.amount : "",
        amountDelivered:
          equipment.orderLine === "COMPLETE_LINE" ? equipment.amount : "",
        quantityCommand:
          equipment.orderLine === "COMPLETE_LINE" ||
          equipment.orderLine === "PARTIAL_LINE"
            ? equipment.quantity
            : "",
        quantityDelivered:
          equipment.orderLine === "COMPLETE_LINE" ||
          equipment.orderLine === "PARTIAL_LINE"
            ? equipment.quantity
            : "",
      };

      temp.push(dataItem);
    }

    return temp;
  };

  const handleClick = () => {
    exportXLS();
  };
  const exportXLS = async () => {
    //const columns = myRef.current.visibleColumns;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";
    var temp: FlowDto[] = [];
    flows.map((flow, index) => {
      temp.push(flows[index]);
    });
    temp = temp.filter(
      (temp) =>
        temp.entityId ===
        convertEntityNameToId(entite, toJS(rootStore.tenantStore.entities))
    );
    let excel_res = await api.writeexcel_DGA(temp);

    const link = document.createElement("a");
    const url = `${process.env.REACT_APP_PUBLIC}/` + excel_res;
    FileSaver.saveAs(
      url.replace("http:", "https:"),
      excel_res.split("/").pop()
    ); // Utilisez le nom de fichier renvoyé par l'API au lieu de fixer "reporting_DGA.xlsx"
  };

  const fetchLicenseId = async (equipmentId: number, type: string) => {
    try {
      const links = await api.getLink(equipmentId, type);
      if (links && links.length > 0 && links[0].license) {
        return links[0].license.license_number;
      }
      return null;
    } catch (error) {
      console.error("Erreur lors de la récupération du lien:", error);
      return null;
    }
  };

  const renderForm = () => {
    return (
      <form className={styles.form}>
        <div className={styles.container}>
          <GridRow>
            {/* CDC LISI <GridCol md={3}>
              <FormLabel>{i18n.t("pages.new-flow.filtrearchive")}</FormLabel>
              <select
                id="regulation"
                value={filtrearchive || ""}
                onChange={(e) => {
                  setFiltreArchive(e.target.value as Regulation);
                  //search({ pageNumber: page - 1 });
                }}
              >
                <option value="ALL">{i18n.t("pages.new-flow.lib1")}</option>
                <option value="NOARCHIVED">
                  {i18n.t("pages.new-flow.lib2")}
                </option>
                <option value="ARCHIVED">
                  {i18n.t("pages.new-flow.lib3")}
                </option>
              </select>
            </GridCol> */}

            <GridCol md={3}>
              <FormLabel>
                {i18n.t("pages.reporting.dateFrom")}{" "}
                {/* {i18n.t("pages.new-flow.general.from")} */}
              </FormLabel>
              <div className={styles.row}>
                <input
                  id="creation-date-begin"
                  type="date"
                  value={creationDateBegin || ""}
                  onChange={
                    (e) => {
                      setDateBegin(e.target.value);
                    }
                    //handleChange("creationDateBegin", e.target.value)
                  }
                />
              </div>
            </GridCol>

            <GridCol md={3}>
              <FormLabel>{i18n.t("pages.reporting.dateEnd")}</FormLabel>
              <div className={styles.row}>
                <input
                  id="creation-date-end"
                  type="date"
                  value={creationDateEnd || ""}
                  onChange={
                    (e) => {
                      setDateEnd(e.target.value);
                    }
                    //handleChange("creationDateEnd", e.target.value)
                  }
                />
              </div>
            </GridCol>
            <GridCol md={3} xs={12}>
              <FormGroup>
                <FormLabel className={styles.flowTypeLabel} required={true}>
                  {i18n.t("pages.license.add.exporter")}
                </FormLabel>
                <select
                  onChange={(e) => setEntite(e.target.value)}
                  value={entite}
                >
                  <option>Selectionner</option> {/* Option par défaut */}
                  {rootStore.userStore.user?.entite === "ALL"
                    ? rootStore.tenantStore.entities.map(
                        (entite: any, index) => (
                          <option key={index} value={entite.id_entite || ""}>
                            {entite.id_entite}
                          </option>
                        )
                      )
                    : rootStore.tenantStore.entities
                        .filter(
                          (entite: any) =>
                            entite.id_entite ===
                            rootStore.userStore.user?.entite
                        )
                        .map((entite: any, index) => (
                          <option key={index} value={entite.id_entite || ""}>
                            {entite.id_entite}
                          </option>
                        ))}
                </select>
              </FormGroup>
            </GridCol>
            <GridCol md={3}>
              <Button
                type="button"
                style={{ marginTop: 20, marginLeft: 50 }}
                onClick={handleClick}
              >
                {i18n.t("pages.new-flow.general.expxls")}
              </Button>
            </GridCol>
          </GridRow>
        </div>
      </form>
    );
  };

  useEffect(() => {
    const filteredFlows = flows.filter(
      (flow: any) =>
        flow.entityId ===
        convertEntityNameToId(entite, toJS(rootStore.tenantStore.entities))
    );
    if (filteredFlows.length > 0) {
      setFilteredFlows(filteredFlows);
    } else {
      setFilteredFlows(flows);
    }
  }, [entite]);

  const renderListFlow = () => {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);

    return (
      <div className={styles.container}>
        <Table id="myRef">
          <TableHead>
            <TableRow>
              {rootStore.userStore.user?.role == "SUPER_ADMINISTRATOR" && (
                <TableHeaderCell> Tenant</TableHeaderCell>
              )}
              <TableHeaderCell>
                {i18n.t("pages.search-flow.id1")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.creation-dateArchive")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.departure-country")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.end-user-country")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.customers-company")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.license.add.entity")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.model")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.folder")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.du-ml")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.regulation")}
              </TableHeaderCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {newFlows.map((flow: any, index: any) => {
              let label: { text: string; color: string; url: string };
              const statusKey = flow.status as keyof typeof flowStatus;
              if (statusKey && flowStatus[statusKey]) {
                label = flowStatus[statusKey];
              }

              return (
                <TableRow key={index}>
                  {rootStore.userStore.user?.role == "SUPER_ADMINISTRATOR" && (
                    <td> {flow.supplier}</td>
                  )}
                  <td>
                    {flow.order_num && flow.order_num !== "null"
                      ? flow.order_num
                      : ""}
                  </td>

                  <td>{moment(flow.updatedAt).format("MMM D YYYY")}</td>
                  <td>{flow.departureCountryName}</td>
                  <td>{flow.destinationCountryName}</td>
                  <td>{flow.customer}</td>
                  <td>
                    {convertEntityIdToName(
                      flow.entityId,
                      toJS(rootStore.tenantStore.entities)
                    )}
                  </td>

                  <td>
                    {flow.flowAircraft
                      ? flow.flowAircraft.model
                      : flow.flowEquipments.length > 0
                        ? flow.flowEquipments[0].model
                        : ""}
                  </td>
                  <td>
                    <img
                      src={require(`../../../statics/images/folder1.png`)}
                      alt={"Folder"}
                    />
                  </td>
                  <td>
                    {flow.hasDuEccn && <Flag>DU</Flag>}

                    {flow.hasMlEccn && <Flag>ML</Flag>}
                  </td>
                  <td>
                    {flow.hasEarEccn && <Flag>EAR</Flag>}

                    {flow.hasItarEccn && <Flag>ITAR</Flag>}
                  </td>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Pagination
          page={page}
          count={totalResults}
          rowsPerPage={1000}
          onPageChange={(_, page) => setPage(page)} //handleChange("page", page)}
        />
      </div>
    );
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="NONE" />

      <HeaderReportMenu pages="REPORTING" rootStore={rootStore} />

      <div className={styles.body}>
        <Title tag="h2">
          Reporting DGA{" "}
          {urlEntite && urlEntite !== "ALL"
            ? i18n.t("pages.screening.due.from_entity") + " " + urlEntite
            : ""}
          {!urlEntite && rootStore.userStore.user?.entite !== "ALL"
            ? i18n.t("pages.screening.due.from_entity") +
              " " +
              rootStore.userStore.user?.entite
            : ""}
        </Title>

        <div
          className="post__content"
          dangerouslySetInnerHTML={{ __html: msg.Message }}
        ></div>
        <p>
          <a target="_blank" rel="noopener noreferrer" href={msg.Url1}>
            {msg.Lib_url1}
          </a>
          <a
            target="_blank"
            style={{ marginLeft: "50px" }}
            rel="noopener noreferrer"
            href={msg.Url2}
          >
            {msg.Lib_url2}
          </a>
          <a
            target="_blank"
            style={{ marginLeft: "50px" }}
            rel="noopener noreferrer"
            href={msg.Url3}
          >
            {msg.Lib_url3}
          </a>
          <br />
        </p>

        {renderForm()}

        {newFlows.length > 0 ? (
          renderListFlow()
        ) : (
          <p>{i18n.t("pages.search-flow.no-result")}</p>
        )}

        {loading && <Loader />}
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(ReportingDGA));
