import React, { createContext, useContext } from "react";
import RootStore from "./rootStore";
export const MobXContext = createContext<RootStore | null>(null);

export const MobXProvider: React.FC = ({ children }) => {
  const rootStore = new RootStore();

  return (
    <MobXContext.Provider value={rootStore}>{children}</MobXContext.Provider>
  );
};
