/* Globlalement import */
import i18n from "i18next";

interface Role {
  label: string;
  value: UserRole;
}

interface FStatus {
  label: string;
  value: FlowStatus;
}

interface FType {
  label: string;
  value: FlowType;
}

interface IntType {
  label: string;
  value: IntangibleType;
}
interface ORType {
  label: string;
  value: OrderLineType;
}

interface dCountry {
  label: string;
  value: DepartureCountry;
}

interface PType {
  label: string;
  value: ProductType;
}

interface LType {
  label: string;
  value: LicenseType;
}

export interface LRegulation {
  label: string;
  value: LicenseRegulation;
}

interface LCurrency {
  label: string;
  value: Currency;
}

export interface CCountry {
  id?: number;
  label: string;
  value: EccnCountry;
}

interface IType {
  label: string;
  value: ItemType;
}

export const ROLES: Role[] = [
  {
    label: i18n.t("global.roles.AUDITOR"),
    value: "AUDITOR",
  },
  {
    label: i18n.t("global.roles.CLASSIFICATION_EXPERT"),
    value: "CLASSIFICATION_EXPERT",
  },
  {
    label: i18n.t("global.roles.USER"),
    value: "USER",
  },
  {
    label: i18n.t("global.roles.C_E_ADMIN"),
    value: "C_E_ADMIN",
  },
  {
    label: i18n.t("global.roles.SALES_PERSON"),
    value: "SALES_PERSON",
  },

  {
    label: i18n.t("global.roles.EXPORT_CONTROL_MANAGER"),
    value: "EXPORT_CONTROL_MANAGER",
  },
  {
    label: i18n.t("global.roles.ADMINISTRATOR_GROUP"),
    value: "ADMINISTRATOR_GROUP",
  },
  {
    label: i18n.t("global.roles.L_A"),
    value: "L_A",
  },
  {
    label: i18n.t("global.roles.L_R_A"),
    value: "L_R_A",
  },
  {
    label: i18n.t("global.roles.F"),
    value: "F",
  },
  {
    label: i18n.t("global.roles.F_C"),
    value: "F_C",
  },
  {
    label: i18n.t("global.roles.F_E"),
    value: "F_E",
  },

  {
    label: i18n.t("global.roles.API_E"),
    value: "API_E",
  },

  {
    label: i18n.t("global.roles.SCREENING_EXPERT"),
    value: "SCREENING_EXPERT",
  },
];

export const flowStatusList: FStatus[] = [
  {
    label: i18n.t("global.flow-status.CLASSIFICATION"),
    value: "CLASSIFICATION",
  },
  {
    label: i18n.t("global.flow-status.EMBARGO"),
    value: "EMBARGO",
  },
  {
    label: i18n.t("global.flow-status.ENTITY"),
    value: "ENTITY",
  },
  {
    label: i18n.t("global.flow-status.ENTITY_OBTAIN"),
    value: "ENTITY_OBTAIN",
  },
  {
    label: i18n.t("global.flow-status.ENTITY_REJECTED"),
    value: "ENTITY_REJECTED",
  },
  {
    label: i18n.t("global.flow-status.ENTITY_PENDING"),
    value: "ENTITY_PENDING",
  },
  {
    label: i18n.t("global.flow-status.END_USER"),
    value: "END_USER",
  },
  {
    label: i18n.t("global.flow-status.END_USER_REJECTED"),
    value: "END_USER_REJECTED",
  },
  {
    label: i18n.t("global.flow-status.END_USER_PENDING"),
    value: "END_USER_PENDING",
  },
  {
    label: i18n.t("global.flow-status.LICENSING"),
    value: "LICENSING",
  },
  {
    label: i18n.t("global.flow-status.READY"),
    value: "READY",
  },
];

export const flowType: FType[] = [
  {
    label: i18n.t("global.flow-type.NEW_SALE"),
    value: "NEW_SALE",
  },
  {
    label: i18n.t("global.flow-type.TECHNOLOGY"),
    value: "TECHNOLOGY",
  },
  {
    label: i18n.t("global.flow-type.MRO"),
    value: "MRO",
  },
  {
    label: i18n.t("global.flow-type.INTERCOMPANY"),
    value: "INTERCOMPANY",
  },
  {
    label: i18n.t("global.flow-type.RETROFIT"),
    value: "RETROFIT",
  },
  {
    label: i18n.t("global.flow-type.TEMPORARY"),
    value: "TEMPORARY",
  },

  {
    label: i18n.t("global.flow-type.IMPORT"),
    value: "IMPORT",
  },
];

export const intangibleType: IntType[] = [
  {
    label: i18n.t("global.intangible-type.phone_call"),
    value: "PHONE_CALL",
  },
  {
    label: i18n.t("global.intangible-type.assistance"),
    value: "ASSISTANCE",
  },
  {
    label: i18n.t("global.intangible-type.training"),
    value: "TRAINING",
  },
  {
    label: i18n.t("global.intangible-type.documentation"),
    value: "DOCUMENTATION",
  },
  {
    label: i18n.t("global.intangible-type.email"),
    value: "EMAIL",
  },
  {
    label: i18n.t("global.intangible-type.specifications"),
    value: "SPECIFICATIONS",
  },
];
export const OrderLine: ORType[] = [
  {
    label: i18n.t("pages.new-flow.general.order-line.new-line"),
    value: "NEW_LINE",
  },
  {
    label: i18n.t("pages.new-flow.general.order-line.confirm-line"),
    value: "CONFIRM_LINE",
  },
  {
    label: i18n.t("pages.new-flow.general.order-line.partial-line"),
    value: "PARTIAL_LINE",
  },
  {
    label: i18n.t("pages.new-flow.general.order-line.complete-line"),
    value: "COMPLETE_LINE",
  },
];

export const departureCountryField: dCountry[] = [
  {
    label: i18n.t("global.countries-text.FRANCE"),
    value: "63",
  },
  {
    label: i18n.t("global.countries-text.SPAIN"),
    value: "168",
  },
  {
    label: i18n.t("global.countries-text.GERMANY"),
    value: "67",
  },
  {
    label: i18n.t("global.countries-text.UK"),
    value: "191",
  },
  {
    label: i18n.t("global.countries-text.USA"),
    value: "202",
  },
];

export const productType: PType[] = [
  {
    label: i18n.t("global.product-type.AIRCRAFT"),
    value: "AIRCRAFT",
  },
  {
    label: i18n.t("global.product-type.HELICOPTER"),
    value: "HELICOPTER",
  },
  {
    label: i18n.t("global.product-type.SPACECRAFT"),
    value: "SPACECRAFT",
  },
  {
    label: i18n.t("global.product-type.EQUIPMENT"),
    value: "EQUIPMENT",
  },
];

export const regulation: LRegulation[] = [
  {
    label: i18n.t("global.regulation.CA_GROUP_1_DU"),
    value: "CA_GROUP_1_DU",
  },
  {
    label: i18n.t("global.regulation.CA_GROUP_2_ML"),
    value: "CA_GROUP_2_ML",
  },
  {
    label: i18n.t("global.regulation.DE_DUAL_USE_GUTER"),
    value: "DE_DUAL_USE_GUTER",
  },
  {
    label: i18n.t("global.regulation.DE_MUNITIONS_LIST"),
    value: "DE_MUNITIONS_LIST",
  },
  {
    label: i18n.t("global.regulation.ES_DUAL_USE_LIST"),
    value: "ES_DUAL_USE_LIST",
  },
  {
    label: i18n.t("global.regulation.ES_MUNITION_LIST"),
    value: "ES_MUNITION_LIST",
  },
  {
    label: i18n.t("global.regulation.EU_DUAL_USE_LIST"),
    value: "EU_DUAL_USE_LIST",
  },
  {
    label: i18n.t("global.regulation.EU_MUNITIONS_LIST"),
    value: "EU_MUNITIONS_LIST",
  },
  {
    label: i18n.t("global.regulation.FR_LIST_DOUBLE_USAGE"),
    value: "FR_LIST_DOUBLE_USAGE",
  },

  {
    label: i18n.t("global.regulation.FR_IMPORTATION"),
    value: "FR_IMPORTATION",
  },

  {
    label: i18n.t("global.regulation.FR_MUNITIONS_LIST"),
    value: "FR_MUNITIONS_LIST",
  },

  {
    label: i18n.t("global.regulation.FR_ANSSI"),
    value: "FR_ANSSI",
  },

  {
    label: i18n.t("global.regulation.FR_ARRETE_DU_31_JUILLET_2014_HEL"),
    value: "FR_ARRETE_DU_31_JUILLET_2014_HEL",
  },
  {
    label: i18n.t("global.regulation.UK_DUAL_USE_LIST"),
    value: "UK_DUAL_USE_LIST",
  },
  {
    label: i18n.t("global.regulation.UK_MILITARY_LIST"),
    value: "UK_MILITARY_LIST",
  },
  {
    label: i18n.t("global.regulation.US_DUAL_USE_LIST_EAR"),
    value: "US_DUAL_USE_LIST_EAR",
  },
  {
    label: i18n.t("global.regulation.US_MUNITIONS_LIST_ITAR"),
    value: "US_MUNITIONS_LIST_ITAR",
  },
];

export const regulation_rep: LRegulation[] = [
  {
    label: i18n.t("global.regulation_rep.DE_DUAL_USE_GUTER"),
    value: "DE_DUAL_USE_GUTER",
  },
  {
    label: i18n.t("global.regulation_rep.DE_MUNITIONS_LIST"),
    value: "DE_MUNITIONS_LIST",
  },
  {
    label: i18n.t("global.regulation_rep.ES_DUAL_USE_LIST"),
    value: "ES_DUAL_USE_LIST",
  },
  {
    label: i18n.t("global.regulation_rep.ES_MUNITION_LIST"),
    value: "ES_MUNITION_LIST",
  },
  {
    label: i18n.t("global.regulation_rep.EU_DUAL_USE_LIST"),
    value: "EU_DUAL_USE_LIST",
  },
  {
    label: i18n.t("global.regulation_rep.EU_MUNITIONS_LIST"),
    value: "EU_MUNITIONS_LIST",
  },
  {
    label: i18n.t("global.regulation_rep.FR_LIST_DOUBLE_USAGE"),
    value: "FR_LIST_DOUBLE_USAGE",
  },

  {
    label: i18n.t("global.regulation_rep.FR_MUNITIONS_LIST"),
    value: "FR_MUNITIONS_LIST",
  },

  {
    label: i18n.t("global.regulation_rep.UK_DUAL_USE_LIST"),
    value: "UK_DUAL_USE_LIST",
  },
  {
    label: i18n.t("global.regulation_rep.UK_MILITARY_LIST"),
    value: "UK_MILITARY_LIST",
  },

  {
    label: i18n.t("global.regulation_rep.US_DUAL_USE_LIST_EAR"),
    value: "US_DUAL_USE_LIST_EAR",
  },
  {
    label: i18n.t("global.regulation_rep.US_MUNITIONS_LIST_ITAR"),
    value: "US_MUNITIONS_LIST_ITAR",
  },
];

export const currency: LCurrency[] = [
  {
    label: i18n.t("global.currency.CAD"),
    value: "CAD",
  },
  {
    label: i18n.t("global.currency.EUR"),
    value: "EUR",
  },
  {
    label: i18n.t("global.currency.GBP"),
    value: "GBP",
  },
  {
    label: i18n.t("global.currency.SEK"),
    value: "SEK",
  },
  {
    label: i18n.t("global.currency.USD"),
    value: "USD",
  },
];

export const countryCurrencyMap: { [country: string]: string } = {
  FR: "EUR",
  BE: "EUR",
  DE: "EUR",
  IT: "EUR",
  NL: "EUR",
  NO: "EUR",
  TA: "EUR",
  SK: "EUR",
  ES: "EUR",
  ZA: "EUR",
  SG: "EUR",
  SE: "EUR",
  EU: "EUR",
  UK: "GBP",
  US: "USD",
};

export const countries: CCountry[] = [
  {
    label: i18n.t("global.countries.FR"),
    value: "FR",
  },
  {
    label: i18n.t("global.countries.EU4"),
    value: "ZA",
  },
  {
    label: i18n.t("global.countries.CA"),
    value: "CA",
  },
  {
    label: i18n.t("global.countries.BE"),
    value: "BE",
  },
  {
    label: i18n.t("global.countries.DK"),
    value: "DK",
  },
  {
    label: i18n.t("global.countries.DE"),
    value: "DE",
  },
  {
    label: i18n.t("global.countries.IT"),
    value: "IT",
  },
  {
    label: i18n.t("global.countries.EU3"),
    value: "NL",
  },
  {
    label: i18n.t("global.countries.PT"),
    value: "PT",
  },
  {
    label: i18n.t("global.countries.EU1"),
    value: "NO",
  },

  {
    label: i18n.t("global.countries.SP"),
    value: "ES",
  },

  {
    label: i18n.t("global.countries.SG"),
    value: "SG",
  },

  {
    label: i18n.t("global.countries.KR"),
    value: "KR",
  },
  {
    label: i18n.t("global.countries.CH"),
    value: "CH",
  },
  {
    label: i18n.t("global.countries.EU2"),
    value: "SE",
  },
  {
    label: i18n.t("global.countries.TW"),
    value: "TW",
  },
  {
    label: i18n.t("global.countries.EU"),
    value: "EU",
  },
  {
    label: i18n.t("global.countries.UK"),
    value: "UK",
  },
  {
    label: i18n.t("global.countries.US"),
    value: "US",
  },
];

export const itemType: IType[] = [
  {
    label: i18n.t("global.item-type.ALL"),
    value: "ALL",
  },
  {
    label: i18n.t("global.item-type.HARDWARE"),
    value: "HARDWARE",
  },
  {
    label: i18n.t("global.item-type.SOFTWARE"),
    value: "SOFTWARE",
  },
  {
    label: i18n.t("global.item-type.TECHNOLOGY"),
    value: "TECHNOLOGY",
  },
];
