import React, { SFC, ChangeEvent, ReactNode, Children } from "react";
import cx from "classnames";
import classes from "../Menu/styles.module.scss";

export interface MenuItemProps {
  className?: string;
  /** The number to display */
  index?: number;
  /** Is active */
  active?: boolean;
  /** Is disabled */
  disabled?: boolean;
  /** The icon to display */
  icon?: ReactNode;
  /** @ignore */
  onClick?: (index: number | undefined, value: any) => void;
}

const MenuItemLabel: SFC<MenuItemProps> = ({
  index = 0,
  icon,
  disabled = false,
  onClick,
  className,
  children,
}) => {
  return (
    <a
      onClick={(e) => {
        if (onClick && !disabled) {
          e.preventDefault();
          onClick(index, children);
        }
      }}
      {...(!disabled ? { href: "" } : {})}
      className={className}
    >
      {icon && <span aria-hidden="true">{icon}</span>}
      <span
        className={cx({
          [classes["ds-menu-expand__item__label--icon"]]: icon,
        })}
      >
        {children}
      </span>
    </a>
  );
};

const MenuItem: SFC<MenuItemProps> = ({
  className,
  index = 0,
  icon,
  active = false,
  disabled = false,
  onClick,
  ...rest
}) => {
  return (
    <li
      className={cx(
        classes["ds-menu-expand__item"],
        {
          [classes["ds-menu-expand__item--active"]]: active,
          [classes["ds-menu-expand__item--disabled"]]: disabled,
        },
        className,
      )}
    >
      <MenuItemLabel
        index={index}
        icon={icon}
        onClick={onClick}
        disabled={disabled}
        className={cx({
          [classes["ds-menu-expand__item--icon"]]: icon,
        })}
      >
        {rest.children}
      </MenuItemLabel>
    </li>
  );
};

export default MenuItem;
