import React, { ChangeEvent, Component, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import { Typography } from "@material-ui/core";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import NumbersIcon from "@mui/icons-material/Tag";
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import {
  Link,
  //  RouterLink
} from "react-router-dom";

import { Footer } from "../../customComponents/footer";

import { SelectCountry } from "../../customComponents/selectCountry";

import { useParams, useNavigate } from "react-router-dom";
import {
  flowUrl,
  convertEntityNameToId,
  convertEntityIdToName,
  getCountriesListed,
  fromRoman,
  convertCountryIdToCode,
  mapOrderLineValue,
  mapOrderLineValueReverse,
  convertCountryCodeToId,
  validateNationalEccn,
  validateUsEccn,
  isValidNumberKey,
  base64ToBlob,
} from "../../utils/generator";
import AppBar from "../../components/appBar";
import Loader from "../../components/loader";
import HeaderFlow from "../../components/headerFlow/headerFlow";
import {
  flowType,
  productType,
  CCountry,
  currency,
  OrderLine as OL,
} from "../../utils/constant";
import Api from "../../utils/api";
import fileDownload from "js-file-download";
import SearchEccn from "../../components/searchEccn";
import MatchingEquipmentToLicense from "../equipments";
import SidePanel from "../../components/sidePanel";
import { flow, toJS } from "mobx";
import {
  AccountCircle,
  Public,
  Flag,
  ProductionQuantityLimits,
  LooksOne,
  Add,
  Delete,
  Pin,
  LocalShipping,
  Done,
  Search as Magnifier,
  Save,
  NorthEast,
  Lock,
  FlightTakeoff,
  SvgIconComponent,
  PictureAsPdf,
} from "@mui/icons-material";
import {
  AiOutlineEuro,
  AiOutlineDollar,
  AiOutlineCrown,
  AiOutlinePound,
} from "react-icons/ai";
import { ConfirmDialog } from "../../customComponents/confirmDialog";
import { SnackbarMsg } from "../../customComponents/snackbarMsg";
import SkeletonUI from "./skeleton";
import configuration from "../../configuration";

interface Props extends InjectedProps {}

interface InjectedProps {
  rootStore: import("../../stores/rootStore").default;
}

interface State {
  fid?: any;
  classificationIsOpen: boolean;
  ouClick: string;
  countries: CCountry[];
  disabledInputs: boolean;
}

interface RequiredField {
  equipment_type: string;
  model: string;
  part_number: string;
  national_eccn: string;
  quantity: string;
  unit_price: string;
  currency: string;
  amount: string;
  us_eccn: string;
  order_line: string;
  order_date: string;
  confirmation_date: string;
  delivery_date: string;
  cuf_cnr: boolean;
  validation_date: string;
  destination_country: string;
  departure_country: string;
}

interface CountryType {
  id?: number;
  value: string;
  label: string;
  code?: string;
  phone?: string;
  suggested?: boolean;
}

type SnackbarProps = {
  message: string;
  severity: "success" | "error" | "info" | "warning";
  position?:
    | "top"
    | "bottom"
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right"
    | "center";
  size?: "small" | "medium" | "large" | "auto";
  autoHideDuration?: number;
  onClose?: () => void;
};

type EquipmentKey =
  | "equipment_type"
  | "model"
  | "quantity"
  | "part_number"
  | "designation"
  | "national_eccn"
  | "delivery_date"
  | "order_date"
  | "confirmation_date"
  | "currency"
  | "us_eccn"
  | "amount"
  | "cuf_cnr"
  | "validation_date"
  | "order_line"
  | "unit_price"
  | "upload_cnr"
  | "supplier";

const EditFlow: React.FC<Props> = ({ rootStore }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const navigateTowards = (url: string) => {
    navigate(flowUrl(url, id));
  };
  const api = new Api();
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [equipmentId, setEquipmentId] = useState(0);
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentFlow>(
    {} as EquipmentFlow
  );
  const [currentFlow, setCurrentFlow] = useState<Flow>({
    id: 0,
    flow_type: "NEW_SALE" as FlowType,
    customer: "",
    delivery_date: "",
    commande_date: "",
    order_num: "",
    order_type: "",
    user_id: rootStore?.userStore?.user?.id,
    num_licence_export: "",
    num_licence_import: "",
    num_licence_us: "",
    departure_country_id: 0,
    destination_country_id: 0,
    transit_country_id: 0,
    status: "CLASSIFICATION" as FlowStatus,
    entity_id: 0,
    flow_equipments: [] as EquipmentFlow[],
  } as Flow);
  const [dpCountry, setDpCountry] = useState<string | number>();
  const [dtCountry, setDtCountry] = useState<string | number>();
  const [TCountry, setTCountry] = useState<string | number>();
  const [entite, setEntite] = useState<string>(
    rootStore.userStore.user?.entite
      ? convertEntityNameToId(
          rootStore.userStore.user?.entite,
          toJS(rootStore.tenantStore.entities)
        )
      : ""
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>({
    message: "",
    severity: "success",
    position: "top",
    size: "medium",
    autoHideDuration: 3000,
  });
  const [openModal, setOpenModal] = useState(false);
  const [selectedEquipmentId, setSelectedEquipmentId] = useState<number | null>(
    null
  );
  const [indexEquipment, setIndexEquipment] = useState<number | null>(null);
  const [licenseTypeSearch, setLicenseTypeSearch] = useState("");
  const handleOpenPanel = (currentEquipment: any) => {
    setSelectedEquipment(currentEquipment);
    setOpenPanel(true);
  };
  const handleClosePanel = () => {
    setOpenPanel(false);
  };
  const [countries, setCountries] = useState<Country[]>([]);
  const [countriesDestination, setCountriesDestination] = useState<any[]>(
    [] as any[]
  );
  const handleChangeDpCountry = (selectedCountry: CountryType | null) => {
    setDpCountry(selectedCountry ? selectedCountry.value : undefined);
    setCurrentFlow((prevFlow) => ({
      ...prevFlow,
      departure_country_id: convertCountryCodeToId(
        selectedCountry?.value as string,
        toJS(rootStore.countriesStore.countryTable)
      ),
    }));
  };
  const handleChangeDtCountry = (selectedCountry: CountryType | null) => {
    setDtCountry(selectedCountry ? selectedCountry.value : undefined);
    setCurrentFlow((prevFlow) => ({
      ...prevFlow,
      destination_country_id: convertCountryCodeToId(
        selectedCountry?.value as string,
        toJS(rootStore.countriesStore.countryTable)
      ),
    }));
  };
  const handleChangeTCountry = (selectedCountry: CountryType | null) => {
    setTCountry(selectedCountry ? selectedCountry.value : undefined);
    setCurrentFlow((prevFlow) => ({
      ...prevFlow,
      transit_country_id: convertCountryCodeToId(
        selectedCountry?.value as string,
        toJS(rootStore.countriesStore.countryTable)
      ),
    }));
  };
  const handleBasicFlowInfoChange = (
    event:
      | React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;
    setCurrentFlow((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const initialEquipment: EquipmentFlow = {
    fiche: {},
    id: null,
    flow_id: currentFlow.id,
    isMatched: false,
    matched: {},
    equipment_type: "",
    model: "",
    quantity: 0,
    part_number: "",
    designation: "",
    us_eccn: "",
    national_regulation: "",
    us_regulation: "",
    supplier: "",
    amount: 0,
    order_line: "NEW_LINE",
    currency: "",
    cuf_cnr: false,
    upload_cnr: "",
    delivery_date: "",
    order_date: "",
    confirmation_date: "",
    unit_price: 0,
    validation_date: "",
    order_line_num: "",
    license: "",
    national_eccn: "",
    nationalEccn: "",
  };
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [basicFormErrors, setBasicFormErrors] = useState({
    departure_country: "",
    destination_country: "",
    customer: "",
    flow_type: "",
    exporter: "",
  });
  const handleFieldChange = (index: number, name: string, value: string) => {
    setCurrentFlow((prevState) => ({
      ...prevState,
      flow_equipments: prevState.flow_equipments.map((item, idx) =>
        idx === index ? { ...item, [name]: value } : item
      ),
    }));
  };

  const handleInputEquipmentChange = (
    index: number,
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
    name: string
  ) => {
    if ("target" in event) {
      const { type, checked, value } = event.target as HTMLInputElement;
      const newValue = type === "checkbox" ? checked : value;
      handleFieldChange(index, name, newValue as any);
      const newErrors = { ...formErrors };
      if (value.trim() === "") {
        newErrors[`${name}_${index}`] =
          `${name.charAt(0).toUpperCase() + name.slice(1)} est requis.`;
      } else {
        delete newErrors[`${name}_${index}`];
      }
      setFormErrors(newErrors);
    }
  };

  const addNewEquipment = () => {
    const newEquipment: EquipmentFlow = {
      ...initialEquipment,
      id: null,
      flow_id: currentFlow.id,
    };
    setCurrentFlow((prevState) => ({
      ...prevState,
      flow_equipments: [...prevState.flow_equipments, newEquipment],
    }));
  };

  const removeEquipment = (
    equipmentId: number | null,
    index: number | null
  ) => {
    setIndexEquipment(index);
    setSelectedEquipmentId(equipmentId);
    setOpenModal(true);
  };

  const showSnackbar = (props: SnackbarProps) => {
    setSnackbarProps({
      ...props,
      onClose: () => setOpenSnackbar(false),
    });
    setOpenSnackbar(true);
  };

  const validateBasicForm = () => {
    let isValid = true;

    const errors = {
      departure_country: "",
      destination_country: "",
      customer: "",
      flow_type: "",
      exporter: "",
    };

    if (!currentFlow.departure_country_id && !dpCountry) {
      isValid = false;

      errors.departure_country = i18n.t("errors.form.flow.departureCountry");
    }
    if (!currentFlow.destination_country_id && !dtCountry) {
      isValid = false;

      errors.destination_country = i18n.t(
        "errors.form.flow.destinationCountry"
      );
    }
    if (!currentFlow.customer) {
      isValid = false;

      errors.customer = i18n.t("errors.form.flow.customer");
    }
    if (!currentFlow.flow_type) {
      isValid = false;

      errors.flow_type = i18n.t("errors.form.flow.type");
    }
    // if (!currentFlow.entity_id && (entite === "" || !entite)) {
    //   isValid = false;

    //   errors.exporter = i18n.t("errors.form.flow.exporter");
    // }

    setBasicFormErrors(errors);
    return isValid;
  };

  const openPdfInNewTab = (file?: any) => {
    if (file) {
      const fileBlob = base64ToBlob(file.b64, "application/pdf");
      if (fileBlob) {
        const fileUrl = URL.createObjectURL(fileBlob);
        window.open(fileUrl, "_blank");
        setTimeout(() => {
          URL.revokeObjectURL(fileUrl);
        }, 1000);
      }
    }
  };
  const deleteFile = async (id: number, equipId: number) => {
    const url = `${configuration.LARAVEL_API}/resources/documents/${id}`;

    try {
      const res = await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setCurrentFlow((prevState) => ({
        ...prevState,
        flow_equipments: prevState.flow_equipments.map((item) =>
          item.id === equipId
            ? {
                ...item,
                documents: item.documents?.filter((doc) => doc.id !== id),
              }
            : item
        ),
      }));
    } catch (error) {
      console.error("Erreur lors de la suppression du fichier : ", error);
    }
  };
  const getMostRecentDocument = (documents: any[]) => {
    if (id) {
      if (documents?.length === 0) return null;

      const sortedDocuments = documents?.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
      if (sortedDocuments) {
        return sortedDocuments[0];
      }
    }
  };

  const validate = (): boolean => {
    let isValid = true;
    const newErrors: { [key: string]: string } = {};

    currentFlow.flow_equipments.forEach((equipment, index) => {
      if (equipment.national_eccn) {
        isValid = validateNationalEccn(
          equipment.national_eccn,
          currentFlow.departure_country_id
        ) as boolean;
        isValid
          ? ""
          : (newErrors[`national_eccn_${index}`] = i18n.t(
              "errors.form.flow.classementNat"
            ));
      } else if (!equipment.national_eccn) {
        isValid = false;
        newErrors[`national_eccn_${index}`] = i18n.t(
          "errors.form.flow.classementNat"
        );
      }
      if (!equipment.us_eccn || !validateUsEccn(equipment.national_eccn)) {
        newErrors[`us_eccn_${index}`] = i18n.t("errors.form.flow.classementUs");
      }
      if (!equipment.amount) {
        isValid = false;
        newErrors[`amount_${index}`] = i18n.t("errors.form.flow.amount");
      }
      if (!equipment.quantity) {
        isValid = false;
        newErrors[`quantity_${index}`] = i18n.t("errors.form.flow.quantity");
      }
      if (!equipment.order_line) {
        isValid = false;
        newErrors[`order_line_${index}`] = i18n.t("errors.form.flow.orderLine");
      }
    });

    setFormErrors(newErrors);
    return isValid;
  };
  const handleSave = async () => {
    if (validate() && validateBasicForm()) {
      try {
        const updatedFlow = { ...currentFlow };
        updatedFlow.tenantId = rootStore.userStore.user?.tenantId as string;
        updatedFlow.flow_equipments.forEach((equipment) => {
          equipment.order_line = mapOrderLineValueReverse(equipment.order_line);
          equipment.amount = parseInt(
            equipment.amount?.toString().replace(/\s/g, ""),
            10
          );
          equipment.quantity = parseInt(
            equipment.quantity?.toString().replace(/\s/g, ""),
            10
          );
          equipment.unit_price = parseInt(
            equipment.unit_price?.toString().replace(/\s/g, ""),
            10
          );
        });
        const newFlow = await api.createOrUpdateFlow(updatedFlow);
        showSnackbar({
          message: i18n.t("errors.form.messages.flowSuccess"),
          severity: "success",
          position: "top-right",
          size: "auto",
          autoHideDuration: 3000,
        });
        window.location.reload();
      } catch (error) {
        console.error("Error updating flow:", error);
        showSnackbar({
          message: i18n.t("errors.form.messages.flowFailure"),
          severity: "error",
          position: "top-right",
          size: "auto",
          autoHideDuration: 3000,
        });
      }
    } else {
      showSnackbar({
        message: i18n.t("errors.form.messages.missing"),
        severity: "error",
        position: "top-right",
        size: "auto",
        autoHideDuration: 3000,
      });
    }
  };

  async function handleConfirmDelete() {
    if (selectedEquipmentId !== null) {
      try {
        const response = await api.deleteFlowEquipmentById(selectedEquipmentId);
        if (response.status === 204) {
          setCurrentFlow((prevState) => ({
            ...prevState,
            flow_equipments: prevState.flow_equipments.filter(
              (equipment) => equipment.id !== selectedEquipmentId
            ),
          }));
          showSnackbar({
            message: i18n.t("errors.form.messages.equipmentSuccess"),
            severity: "success",
            position: "top-right",
            size: "auto",
            autoHideDuration: 3000,
          });
        } else {
          showSnackbar({
            message: i18n.t("errors.form.messages.equipmentFailure"),
            severity: "error",
            position: "top-right",
            size: "auto",
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        showSnackbar({
          message: i18n.t("errors.form.messages.equipmentFailure"),
          severity: "error",
          position: "top-right",
          size: "auto",
          autoHideDuration: 3000,
        });
      } finally {
        setOpenModal(false);
        setSelectedEquipmentId(null);
      }
    } else {
      if (
        indexEquipment !== null &&
        indexEquipment >= 0 &&
        indexEquipment < currentFlow.flow_equipments.length
      ) {
        setCurrentFlow((prevState) => ({
          ...prevState,
          flow_equipments: [
            ...prevState.flow_equipments.slice(0, indexEquipment),
            ...prevState.flow_equipments.slice(indexEquipment + 1),
          ],
        }));
        setOpenModal(false);
      }
    }
  }

  const handleCancelDelete = () => {
    setOpenModal(false);
    setSelectedEquipmentId(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      await rootStore.countriesStore.getCountries();
      await rootStore.countriesStore.setCountryTable();
      rootStore.countriesStore.getCountriesDeparture();
      try {
        if (countries.length === 0) {
          const countriesResponse = await axios.get(
            `${configuration.LARAVEL_API}/countries_departure`
          );
          await rootStore.countriesStore.getCountries();
          setCountries(countriesResponse.data);
          setCountriesDestination(toJS(rootStore.countriesStore.countryTable));
        }
        const flowId = id;
        if (flowId) {
          await getFlow(parseInt(flowId));
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    setLoading(true);
    fetchData();
    if (!id) {
      addNewEquipment();
      setLoading(false);
    }
  }, [openPanel]);

  const getFlow = async (fid: any) => {
    const flow = await api.newGetFlowById(fid);
    const test = convertEntityIdToName(
      flow.entity_id,
      toJS(rootStore.tenantStore.entities)
    );
    setCurrentFlow(flow);
    setEntite(test);
    if (rootStore.countriesStore.countryTable.length <= 0) {
      setDpCountry(
        convertCountryIdToCode(
          flow.departure_country_id,
          toJS(rootStore.countriesStore.countryTable)
        )
      );
      setDtCountry(
        convertCountryIdToCode(
          flow.destination_country_id,
          toJS(rootStore.countriesStore.countryTable)
        )
      );
      setTCountry(
        convertCountryIdToCode(
          flow.transitCountryId,
          toJS(rootStore.countriesStore.countryTable)
        )
      );
    }
    setLoading(false);
  };

  const isOrderLineMappedValue = (orderLine: string) => {
    return ["0", "1", "2", "3"].includes(orderLine);
  };

  //fix temporaire en attente de virer le java :
  function mapEquipments(equipments: EquipmentFlow[]) {
    return equipments
      .map((equipment) => {
        const minEquip =
          equipment.quantity === undefined ||
          (equipment.quantity === 0 &&
            Object.values(equipment).some((value) => value !== undefined))
            ? 1
            : equipment.quantity;
        const cleanedEquipment: any = {
          id: equipment.id,
          designation: equipment.designation || "",
          equipmentType: equipment.equipment_type || "",
          model: equipment.model || "",
          nationalEccn: equipment.national_eccn,
          partNumber: equipment.part_number || "",
          cufCnr: equipment.cuf_cnr,
          currency: equipment.currency || "",
          amount:
            equipment.amount !== undefined
              ? parseInt(equipment.amount.toString(), 10)
              : 0,
          quantity: minEquip,
          orderLine: mapOrderLineValueReverse(equipment.order_line),
          unitPrice: equipment.unit_price || 0,
          usEccn: equipment.us_eccn || null,
        };
        // cleanedEquipment.validationDate = equipment.validation_date || "";
        // cleanedEquipment.deliveryDate = equipment.delivery_date || "1970-01-01";
        // cleanedEquipment.confirmationDate =
        // equipment.confirmation_date || "1970-01-01";
        // cleanedEquipment.orderDate = equipment.order_date || "1970-01-01";
        return cleanedEquipment;
      })
      .filter((equipment) => equipment.quantity !== 0);
  }

  const handleSubmit = async () => {
    try {
      setLoadingExport(true);

      const flowId = await rootStore.flowStore.submitFlow({
        id: currentFlow.id,
        sapId: currentFlow.sapId || "",
        flowType: currentFlow.flow_type as FlowType,
        intangibleType:
          (currentFlow.intangibleType as IntangibleType) || "PHONE_CALL",
        customerCompagny: currentFlow.customer,
        departureCountry: currentFlow.departure_country_id,
        endUserCountry: currentFlow.destination_country_id,
        productType: "EQUIPMENT",
        equipments: mapEquipments(currentFlow.flow_equipments),
        flowConsignees: currentFlow.flowConsignees || [],
        model: "",
        quantity: 0,
        amount: 0,
        supplier: "",
        partNumber: "",
        designation: "",
        nationalEccn: "",
        usEccn: "",
      });

      if (currentFlow.id) {
        if (
          rootStore.userStore.user?.role == "L_A" ||
          rootStore.userStore.user?.role == "L_R_A"
        ) {
          navigate(flowUrl("/shipping", id));
        } else {
          navigate({
            pathname: `/flow/${currentFlow.id}/exportability/results_new`,
          });
        }
      }
    } catch (error) {
      console.error("Error during submitFlow or navigation:", error);
      // Vous pouvez également afficher une notification d'erreur à l'utilisateur ici
    } finally {
      setLoadingExport(false);
    }
  };

  const activeSaveButton = () => {
    return true;
  };

  const activeSubmitButtonShipping = () => {
    return true;
  };

  const activeDepreciateButton = () => {
    return true;
  };

  const user = rootStore.userStore.user;
  let roles: string | undefined;

  if (user) {
    roles = user.role;
  }
  var leftButtons:
    | {
        id: string;
        onClick: () => void;
        label: string;
        primary?: boolean;
        disabled?: boolean;
        bg?: string;
        icon?: SvgIconComponent;
        iconPosition?: "left" | "right" | string;
      }[]
    | undefined = [];
  if (roles === "F_E" || roles === "ADMINISTRATOR_GROUP" || user?.id === 5) {
    leftButtons = [
      {
        id: "next",
        onClick: () => handleSubmit(),
        label: i18n.t("global.sub-expo"),
        primary: false,
        bg: "#0085ad",
        disabled: !activeSubmitButtonShipping(),
        icon: FlightTakeoff,
        iconPosition: "right",
      },
    ];
  } else {
    leftButtons = [];
  }

  if (
    roles === "ADMINISTRATOR_GROUP" ||
    roles === "EXPORT_CONTROL_MANAGER" ||
    roles === "SUPER_ADMINISTRATOR" ||
    roles === "L_A" ||
    roles === "L_R_A"
  ) {
    leftButtons.push({
      id: "licensing",
      onClick: async () => {
        navigate(`/license/${id}/impute/`);
      },
      label: i18n.t("pages.license.menu.depreciate_licence"),
      primary: false,
      bg: "#0085ad",
      disabled: !activeDepreciateButton(),
      icon: Lock,
      iconPosition: "right",
    });
  }

  const rightButton = [
    {
      id: "save",
      onClick: () => handleSave(),
      label: i18n.t("global.save"),
      primary: true,
      disabled: !activeSaveButton(),
      bg: "#0085ad",
      icon: Save,
      iconPosition: "left",
    },
  ];

  const formatNumberWithSpaces = (number: number) => {
    if (number) {
      let formatted = number?.toString().replace(/\s/g, "");

      const parts = formatted?.split(".");
      let integerPart = parts[0];
      const decimalPart = parts.length > 1 ? "." + parts[1] : "";

      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      return integerPart + decimalPart;
    } else {
      return null;
    }
  };

  const renderSkeleton = () => <SkeletonUI />;

  const FlowLines = () => {
    return (
      <Stack spacing={4} sx={{ width: "100%", margin: "auto", marginTop: 2 }}>
        {currentFlow.flow_equipments?.map((item, index) => (
          <Paper
            key={index}
            elevation={2}
            sx={{
              padding: 5,
              borderRadius: 1,
            }}
          >
            <Stack sx={{ marginBottom: 2, width: "100%" }} direction="row">
              <Box sx={{ width: "30%" }}>
                <Typography variant="h6">
                  {i18n.t("components.searchEccn.commande")} n°{index + 1}
                </Typography>
              </Box>

              <Box sx={{ width: "60%" }}>
                {item.matched?.license?.id && (
                  <Typography variant="h6">
                    {i18n.t("pages.license.matching.linked-license")}{" "}
                    <Link
                      style={{ color: "#0085ad" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`/license/${item.matched?.license?.id}/edit`}
                    >
                      {item.matched?.license?.license_number}
                    </Link>{" "}
                    <Done sx={{ color: "green" }} />
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "10%",
                }}
              >
                <Button
                  sx={{ minWidth: 44 }}
                  size="small"
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    removeEquipment(item.id, index);
                  }}
                >
                  <Delete />
                </Button>
              </Box>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Stack direction="column" spacing={2} sx={{ flex: 3 }}>
                <TextField
                  fullWidth
                  label={i18n.t("components.searchEccn.line")}
                  value={item.equipment_type || ""}
                  onChange={(e) =>
                    handleInputEquipmentChange(index, e, "equipment_type")
                  }
                  variant="standard"
                />
                <TextField
                  fullWidth
                  label={i18n.t("components.searchEccn.Produit")}
                  value={item.model || ""}
                  onChange={(e) =>
                    handleInputEquipmentChange(index, e, "model")
                  }
                  variant="standard"
                />
                <TextField
                  fullWidth
                  label={i18n.t("pages.new-flow.product.equipment.part-number")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Pin color="action" />{" "}
                      </InputAdornment>
                    ),
                  }}
                  value={item.part_number || ""}
                  onChange={(e) =>
                    handleInputEquipmentChange(index, e, "part_number")
                  }
                  variant="standard"
                />
                <TextField
                  fullWidth
                  label={i18n.t(
                    "pages.new-flow.product.equipment.national-eccn"
                  )}
                  error={!!formErrors[`national_eccn_${index}`]}
                  helperText={formErrors[`national_eccn_${index}`]}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Public color="action" />{" "}
                      </InputAdornment>
                    ),
                  }}
                  value={item.national_eccn || ""}
                  onChange={(e) =>
                    handleInputEquipmentChange(index, e, "national_eccn")
                  }
                  variant="standard"
                />

                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="end"
                  sx={{ marginTop: "2em" }}
                >
                  <Button
                    variant="outlined"
                    hidden={item.id ? false : true}
                    size="small"
                    onClick={() => {
                      handleOpenPanel(item as any);
                      setEquipmentId(item.id as number);
                      setLicenseTypeSearch("NATIONAL");
                    }}
                  >
                    <Magnifier />{" "}
                    {i18n.t("pages.new-flow.general.num_licence_export")}
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="column" spacing={2} sx={{ flex: 3 }}>
                <TextField
                  sx={{ textAlign: "right" }}
                  fullWidth
                  label={i18n.t("pages.new-flow.product.equipment.quantity")}
                  error={!!formErrors[`quantity_${index}`]}
                  helperText={formErrors[`quantity_${index}`]}
                  FormHelperTextProps={{
                    sx: {
                      position: "absolute",
                      bottom: "-1.5em",
                      height: "1.5em",
                      whiteSpace: "nowrap",
                    },
                  }}
                  value={formatNumberWithSpaces(item.quantity) || ""}
                  required
                  InputProps={{
                    onKeyDown: (e) => {
                      if (!isValidNumberKey(e.key)) {
                        e.preventDefault();
                      }
                    },
                    sx: {
                      "& .MuiInputBase-input": { textAlign: "right" },
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <ProductionQuantityLimits color="action" />{" "}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    handleInputEquipmentChange(index, e, "quantity")
                  }
                  variant="standard"
                />
                <TextField
                  sx={{ textAlign: "right" }}
                  fullWidth
                  InputProps={{
                    onKeyDown: (e) => {
                      if (!isValidNumberKey(e.key)) {
                        e.preventDefault();
                      }
                    },
                    sx: {
                      "& .MuiInputBase-input": { textAlign: "right" },
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <LooksOne color="action" />{" "}
                      </InputAdornment>
                    ),
                  }}
                  label={i18n.t("pages.new-flow.product.equipment.unit-price")}
                  value={formatNumberWithSpaces(item.unit_price) || ""}
                  onChange={(e) =>
                    handleInputEquipmentChange(index, e, "unit_price")
                  }
                  variant="standard"
                />
                <Stack direction="row" spacing={1} alignItems="flex-end">
                  <FormControl fullWidth variant="standard">
                    <InputLabel>
                      {i18n.t("pages.license.add.currency")}
                    </InputLabel>
                    <Select
                      value={item.currency || ""}
                      onChange={(e) =>
                        handleInputEquipmentChange(index, e, "currency")
                      }
                      name="currency"
                      label={i18n.t("pages.license.add.currency")}
                    >
                      {currency.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value || ""}
                          sx={{
                            alignItems: "center",
                            direction: "row",
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                          >
                            {option.value === "EUR" && (
                              <AiOutlineEuro fontSize={24} />
                            )}
                            {(option.value === "USD" ||
                              option.value === "CAD") && (
                              <AiOutlineDollar fontSize={24} />
                            )}
                            {option.value === "SEK" && (
                              <AiOutlineCrown fontSize={24} />
                            )}
                            {option.value === "GBP" && (
                              <AiOutlinePound fontSize={24} />
                            )}
                            <Box>{option.label}</Box>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label={i18n.t("pages.new-flow.product.equipment.amount")}
                    fullWidth
                    variant="standard"
                    value={formatNumberWithSpaces(item.amount) || ""}
                    error={!!formErrors[`amount_${index}`]}
                    helperText={formErrors[`amount_${index}`]}
                    FormHelperTextProps={{
                      sx: {
                        position: "absolute",
                        bottom: "-1.5em",
                        height: "1.5em",
                        whiteSpace: "nowrap",
                      },
                    }}
                    onChange={(e) =>
                      handleInputEquipmentChange(index, e, "amount")
                    }
                    InputProps={{
                      onKeyDown: (e) => {
                        if (!isValidNumberKey(e.key)) {
                          e.preventDefault();
                        }
                      },
                      sx: {
                        "& .MuiInputBase-input": { textAlign: "right" },
                      },
                    }}
                  />
                </Stack>
                <TextField
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Flag color="action" />{" "}
                      </InputAdornment>
                    ),
                  }}
                  label={i18n.t("pages.new-flow.product.equipment.us-eccn")}
                  value={item.us_eccn || ""}
                  onChange={(e) =>
                    handleInputEquipmentChange(index, e, "us_eccn")
                  }
                  variant="standard"
                />
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="end"
                  sx={{ marginTop: "2em" }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    hidden={item.id ? false : true}
                    onClick={() => {
                      handleOpenPanel(item as any);
                      setEquipmentId(item.id as number);
                      setLicenseTypeSearch("US");
                    }}
                  >
                    <Magnifier />{" "}
                    {i18n.t("pages.new-flow.general.num_licence_us")}
                  </Button>
                </Stack>

                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "100%",
                    padding: "2px",
                  }}
                >
                  {i18n.t("pages.license.add.analyticInformation")} :
                  <Stack
                    key={item.id}
                    direction="row"
                    sx={{ justifyContent: "flex-end", alignItems: "center" }}
                  >
                    <Button
                      variant="text"
                      onClick={() =>
                        openPdfInNewTab(
                          getMostRecentDocument(item?.documents as any[])
                        )
                      }
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "350px",
                      }}
                    >
                      {(item?.documents?.length as number) > 0
                        ? getMostRecentDocument(item?.documents as any[])
                            .filename
                        : i18n.t("pages.new-flow.general.nofile")}
                    </Button>
                    {!getMostRecentDocument(item?.documents as any[]) && (
                      <IconButton component="label">
                        <Add
                          sx={{
                            cursor: "pointer",
                            ":hover": { color: "green" },
                          }}
                        />
                        <VisuallyHiddenInput
                          type="file"
                          accept="application/pdf"
                          onChange={(e) =>
                            handleFiche(e, "ficheAnalytique", item.id as number)
                          }
                        />
                      </IconButton>
                    )}
                    {getMostRecentDocument(item?.documents as any[]) && (
                      <Delete
                        onClick={() =>
                          deleteFile(
                            getMostRecentDocument(item?.documents as any[]).id,
                            item.id as number
                          )
                        }
                        sx={{
                          color: "red",
                          verticalAlign: "bottom",
                          cursor: "pointer",
                          ":hover": { color: "black" },
                        }}
                      />
                    )}
                  </Stack>
                </Box>
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Stack direction="column" spacing={2} sx={{ width: "30%" }}>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!formErrors[`order_line_${index}`]}
                >
                  <InputLabel id="order-line-label">
                    {i18n.t("pages.new-flow.general.order-line.label")}
                  </InputLabel>
                  <Select
                    id="flow-type"
                    value={
                      (isOrderLineMappedValue(item.order_line)
                        ? mapOrderLineValue(parseInt(item.order_line))
                        : item.order_line) || ""
                    }
                    onChange={(e) =>
                      handleInputEquipmentChange(index, e, "order_line")
                    }
                    required={true}
                    startAdornment={
                      <InputAdornment position="start">
                        <LocalShipping />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="">
                      {i18n.t("pages.new-flow.select-value")}
                    </MenuItem>
                    {OL.map((type) => (
                      <MenuItem key={type.value} value={type.value || ""}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!formErrors[`order_line_${index}`] && (
                    <FormHelperText error>
                      {formErrors[`order_line_${index}`]}
                    </FormHelperText>
                  )}
                </FormControl>
                <TextField
                  fullWidth
                  label={i18n.t("pages.new-flow.product.equipment.order-date")}
                  value={item.order_date || ""}
                  onChange={(e) =>
                    handleInputEquipmentChange(index, e, "order_date")
                  }
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                />
                <TextField
                  fullWidth
                  label={i18n.t(
                    "pages.new-flow.product.equipment.confirmation-date"
                  )}
                  value={item.confirmation_date || ""}
                  onChange={(e) =>
                    handleInputEquipmentChange(index, e, "confirmation_date")
                  }
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                />
                <TextField
                  fullWidth
                  label={i18n.t("pages.search-flow.creation-dateArchive")}
                  value={item.delivery_date || ""}
                  onChange={(e) =>
                    handleInputEquipmentChange(index, e, "delivery_date")
                  }
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                />
                <Stack direction="row" spacing={2}>
                  <FormControlLabel
                    label={i18n.t("pages.new-flow.product.equipment.cuf-cnr")}
                    sx={{ width: "50%" }}
                    control={
                      <Checkbox
                        checked={item.cuf_cnr}
                        onChange={(e) =>
                          handleInputEquipmentChange(index, e, "cuf_cnr")
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                  <TextField
                    fullWidth
                    sx={{ width: "50%" }}
                    label={i18n.t(
                      "pages.new-flow.product.equipment.validation-date"
                    )}
                    value={item.validation_date || ""}
                    onChange={(e) =>
                      handleInputEquipmentChange(index, e, "validation_date")
                    }
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                  />
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        ))}
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Button
            variant="contained"
            sx={{ width: "auto", background: "#0085ad", color: "white" }}
            onClick={addNewEquipment}
            size="large"
            endIcon={<Add />}
          >
            {i18n.t("pages.new-flow.product.equipment.addGeneric")}
          </Button>
        </Box>
      </Stack>
    );
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  async function handleFiche(
    file: React.ChangeEvent<HTMLInputElement>,
    slug: string,
    equipId: number
  ) {
    const url = `${configuration.LARAVEL_API}/uploadFile`;
    const params = {
      tenantId: rootStore.userStore.user?.tenantId,
      slug: slug,
      categorie: "flow",
      typeId: currentFlow.id,
      equipmentId: equipId,
    };
    const formData = new FormData();
    formData.append("file", file.target.files?.[0] as Blob);
    const fileinfo = await axios.post(url, formData, {
      params,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (slug === "ficheAnalytique") {
      setCurrentFlow((prevState) => {
        const updatedFlowEquipments = prevState.flow_equipments.map((item) =>
          item.id === equipId
            ? {
                ...item,
                documents: [...(item.documents || []), fileinfo.data.data], // Assure-toi que fileinfo.data.data est le bon fichier
              }
            : item
        );

        return {
          ...prevState,
          flow_equipments: updatedFlowEquipments,
        };
      });
    }
  }

  const basicFlowInfos = () => {
    return (
      <Card variant="outlined" sx={{ p: 4 }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={3}
          sx={{ marginBottom: 3 }}
          width={"100%"}
        >
          <Box
            height={"auto"}
            width={"100%"}
            display="flex"
            gap={4}
            alignItems={"center"}
            flexDirection={"row"}
            sx={{ mt: "10px", mb: "" }}
          >
            <FormControl
              fullWidth
              required
              size="small"
              error={basicFormErrors.departure_country !== ""}
            >
              <SelectCountry
                id="departureCountrySelect"
                onChangeInherited={handleChangeDpCountry}
                selected={convertCountryIdToCode(
                  currentFlow.departure_country_id,
                  countries
                )}
                size="small"
                width="100%"
                countries={countries as CountryType[]}
                label={i18n.t("pages.exportability.departure-country")}
              />
              <FormHelperText
                error
                sx={{
                  position: "absolute",
                  bottom: "-1.5em",
                  height: "1.5em",
                  whiteSpace: "nowrap",
                }}
              >
                {basicFormErrors.departure_country}
              </FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              required
              size="small"
              error={basicFormErrors.destination_country !== ""}
            >
              <SelectCountry
                id="destinationCountrySelect"
                onChangeInherited={handleChangeDtCountry}
                selected={convertCountryIdToCode(
                  currentFlow.destination_country_id,
                  countriesDestination
                )}
                size="small"
                width="100%"
                countries={countriesDestination as CountryType[]}
                label={i18n.t("pages.license.add.tdestination-country")}
              />
              <FormHelperText
                error
                sx={{
                  position: "absolute",
                  bottom: "-1.5em",
                  height: "1.5em",
                  whiteSpace: "nowrap",
                }}
              >
                {basicFormErrors.destination_country}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth size="small">
              <SelectCountry
                onChangeInherited={handleChangeTCountry}
                selected={convertCountryIdToCode(
                  currentFlow.transit_country_id,
                  countriesDestination
                )}
                size="small"
                width="100%"
                countries={countriesDestination as CountryType[]}
                label={i18n.t("pages.new-flow.general.pays_transit")}
              />
            </FormControl>
            <TextField
              size="small"
              fullWidth
              required
              variant="standard"
              label={i18n.t("pages.new-flow.general.customer-compagny")}
              name="customer"
              value={currentFlow.customer || ""}
              onChange={handleBasicFlowInfoChange}
              error={!!basicFormErrors.customer}
              helperText={basicFormErrors.customer}
              FormHelperTextProps={{
                sx: {
                  position: "absolute",
                  bottom: "-1.5em",
                  height: "1.5em",
                  whiteSpace: "nowrap",
                },
              }}
            />
          </Box>
          <Box
            height={"auto"}
            width={"100%"}
            display="flex"
            gap={4}
            alignItems={"flex-end"}
            flexDirection={"row"}
            sx={{ mt: "10px", mb: "10px" }}
          >
            <FormControl fullWidth required size="small">
              <InputLabel id="flowType-label">
                {i18n.t("pages.new-flow.general.flow-type")}
              </InputLabel>
              <Select
                fullWidth
                label={i18n.t("pages.new-flow.general.flow-type")}
                name="flow_type"
                value={currentFlow.flow_type || ""}
                onChange={handleBasicFlowInfoChange}
                variant="standard"
                required
              >
                {flowType.map((type) => (
                  <MenuItem key={type.value} value={type.value || ""}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                sx={{
                  position: "absolute",
                  bottom: "-1.5em",
                  height: "1.5em",
                  whiteSpace: "nowrap",
                }}
                error
              >
                {basicFormErrors.flow_type}
              </FormHelperText>
            </FormControl>
            <TextField
              size="small"
              fullWidth
              variant="standard"
              label={i18n.t("pages.new-flow.general.order_num")}
              name="order_num"
              required
              value={currentFlow.order_num || ""}
              onChange={handleBasicFlowInfoChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <NumbersIcon color="action" />{" "}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              size="small"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              type="date"
              variant="standard"
              label={i18n.t("pages.new-flow.general.commande_date")}
              name="commande_date"
              value={currentFlow.commande_date || ""}
              onChange={handleBasicFlowInfoChange}
            />
            <FormControl
              fullWidth
              size="small"
              // required : hotfix demande du 02/08/2024, probleme exosens + fm logistics pas d'entité
              // error={basicFormErrors.exporter !== ""}
            >
              <InputLabel id="exporter-label">
                {i18n.t("pages.license.add.exporter")}
              </InputLabel>
              <Select
                size="small"
                fullWidth
                labelId="exporter-label"
                value={entite || ""}
                variant="standard"
                onChange={(e) => {
                  setEntite(e.target.value);
                  setCurrentFlow((prevFlow) => ({
                    ...prevFlow,
                    entity_id: convertEntityNameToId(
                      e.target.value,
                      toJS(rootStore.tenantStore.entities)
                    ),
                  }));
                }}
                label={i18n.t("pages.licence.add.exporter")}
              >
                <MenuItem value="">{i18n.t("global.select")}</MenuItem>
                {rootStore.userStore.user?.entite === "ALL"
                  ? rootStore.tenantStore.entities.map((entite, index) => (
                      <MenuItem key={index} value={entite.id_entite || ""}>
                        {entite.id_entite}
                      </MenuItem>
                    ))
                  : rootStore.tenantStore.entities
                      .filter(
                        (entite) =>
                          entite.id_entite === rootStore.userStore.user?.entite
                      )
                      .map((entite, index) => (
                        <MenuItem key={index} value={entite.id_entite || ""}>
                          {entite.id_entite}
                        </MenuItem>
                      ))}
              </Select>
              {/* <FormHelperText
                error
                sx={{
                  position: "absolute",
                  bottom: "-1.5em",
                  height: "1.5em",
                  whiteSpace: "nowrap",
                }}
              >
                {basicFormErrors.exporter}
              </FormHelperText> */}
            </FormControl>
          </Box>
        </Stack>
      </Card>
    );
  };

  return (
    <Container maxWidth={false} disableGutters>
      {openSnackbar && (
        <SnackbarMsg
          message={snackbarProps.message}
          severity={snackbarProps.severity}
          position={snackbarProps.position}
          size={snackbarProps.size}
          autoHideDuration={snackbarProps.autoHideDuration}
          onClose={snackbarProps.onClose}
        />
      )}
      <AppBar rootStore={rootStore} selectedPage={"FLOW"} />
      <HeaderFlow pages="ADD" />

      {openPanel && (
        <SidePanel
          open={openPanel}
          onClose={handleClosePanel}
          width="regular"
          side="right"
        >
          <MatchingEquipmentToLicense
            flow={currentFlow}
            rootStore={rootStore}
            flowId={parseInt(id as string)}
            equipmentId={selectedEquipment?.id as number}
            type={licenseTypeSearch}
            equipment={selectedEquipment}
          />
        </SidePanel>
      )}

      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{ mt: "3em", minHeight: "70vh", paddingBottom: "3em" }}
      >
        {loadingExport && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backdropFilter: "blur(5px)",
              zIndex: 1300,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <Typography variant="h4" style={{ color: "#0085ad" }}>
              {i18n.t("loaders.exportability")}
            </Typography>
            <CircularProgress sx={{ margin: 3, color: "#0085ad" }} />
          </Box>
        )}
        <Stack direction="column" spacing={4} width={"80%"}>
          <Box justifyContent="flex-start">
            <Typography variant="h5" style={{ color: "#0085ad" }}>
              {i18n.t("pages.license.matching.flow")}
            </Typography>
          </Box>
          {basicFlowInfos()}
          <Box sx={{ marginTop: 5, marginBottom: 5 }} />

          {loading ? renderSkeleton() : FlowLines()}
        </Stack>
      </Stack>

      <Box height="3em" />
      <Footer
        leftButtons={leftButtons}
        rightButtons={rightButton}
        height="medium"
        show={!openPanel}
      />
      <ConfirmDialog
        open={openModal}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        confirmLabel={i18n.t("pages.license.add.licenseLineConfirm")}
        cancelLabel={i18n.t("pages.license.add.licenseLineCancel")}
        dialogText={i18n.t("pages.license.add.licenseModalTitle")}
        size="medium"
        confirmButtonColor="primary"
        cancelButtonColor="primary"
        confirmButtonVariant="contained"
        cancelButtonVariant="outlined"
      />
    </Container>
  );
};

export default inject("rootStore")(observer(EditFlow));
