/* Styles import */
import styles from "./license.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Title,
  GridRow,
  GridCol,
  Checkbox,
  SearchInput,
  Table,
  TextInput,
  TableHead,
  TableRow,
  TableBody,
  TableHeaderCell,
  Pagination,
  IconButton,
  Radio,
  Flag,
  DialogActions,
  DialogContent,
  Dialog,
  Button,
} from "../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { Hidden, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteSweep";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { inject, observer } from "mobx-react";
import AutoComplete from "../../components/autoComplete";
import { FormLabel } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Create";
import FolderOpenIcon from "@material-ui/icons/Folder";
import ViewIcon from "@material-ui/icons/Loupe";
import moment from "moment";
import HeaderReport from "../../components/headerReport";
/* Custom import */
import AppBar from "../../components/appBar";
import Loader from "../../components/loader";
import {
  generateFlowId,
  redirectFlow,
  flowStatus,
} from "../../utils/generator";
import HeaderFlow from "../../components/headerFlow";
import { flowStatusList, flowType } from "../../utils/constant";
import { UnsubscribeTwoTone } from "@material-ui/icons";
import { unset } from "lodash";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import HeaderReportMenu from "../../components/headerReportMenu";
import Api from "../../utils/api";

interface InjectedProps {
  rootStore: import("../../stores/rootStore").default;
}
interface Props extends InjectedProps {}
interface State {
  flowType?: FlowType;
  dualUse?: boolean;
  usEar?: boolean;
  status?: FlowStatus;
  creationDateBegin?: string;
  creationDateEnd?: string;
  ml?: boolean;
  usItar?: boolean;
  search?: string;
  departureCountry: number;
  endUserCountry: number;
  customer?: string;
  flows: FlowDto[];
  page: number;
  totalResults: number;
  filter: Filter;
  filtrearchive: string;
  desId: number;
  open: boolean;
  open1: boolean;
}

type MsgType = {
  code_msg: string;
  Regle: string;
  Message: string;
  Lib_url1: string;
  Url1: string;
  Lib_url2: string;
  Url2: string;
  Lib_url3: string;
  Url3: string;
};

type Keys = keyof State;
interface Props {
  rootStore: import("../../stores/rootStore").default;
}

const ReportingPays: React.FC<Props> = ({ rootStore }) => {
  const navigate = useNavigate();
  const api = new Api();
  const [msg, setMsg] = useState({
    code_msg: "",
    Regle: "",
    Message: "",
    Lib_url1: "",
    Url1: "",
    Lib_url2: "",
    Url2: "",
    Lib_url3: "",
    Url3: "",
  });
  const [state, setState] = useState({
    flows: [] as FlowDto[],
    page: 1,
    totalResults: 0,
    departureCountry: 0,
    endUserCountry: 0,
    customer: "",
    filter: "ALL",
    filtrearchive: "ALL",
    open: false,
    open1: false,
    desId: 0,
    creationDateBegin: "",
    creationDateEnd: "",
    usItar: false,
    usEar: false,
    ml: false,
    search: "",
    flowStatus: "",
    flowType: "" as FlowType,
    status: "",
  });
  //const { reg, pays, departureCountry } = useParams();
  const location = useLocation();

  const pays = location.state.pays;
  const reg = location.state.reg;

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    rootStore.countriesStore.getCountriesDeparture();
    const fetchData = async () => {
      if (pays === "SE") {
        const cmsg = "EXP100";
        const msg = await api.GcodeToMsg(cmsg);
      }
      if (pays === "GB") {
        if (reg === "UK_DUAL_USE_LIST") {
          const cmsg = "EXP101";
          const msg = await api.GcodeToMsg(cmsg);
        }
        if (reg === "UK_MILITARY_LIST") {
          const cmsg = "EXP102";
          const msg = await api.GcodeToMsg(cmsg);
        }
      }

      rootStore.countriesStore.getCountries();
      search({
        pageNumber: state.page - 1,
        departureCountry: paystoid(pays as any),
      });
    };

    fetchData();
  }, []);

  const paystoid = (S: string) => {
    let code = 0;
    if (S === "DE") code = 67;
    if (S === "ES") code = 168;
    if (S === "UK") code = 191;
    if (S === "SE") code = 173;
    if (S === "NO") code = 134;
    if (S === "NL") code = 129;
    if (S === "BE") code = 18;
    if (S === "IT") code = 86;
    if (S == "GB") code = 191;
    return code;
  };

  const search = async (params: SearchFlowParams, value?: any) => {
    params.departureCountry = pays;
    setLoading(true);

    const searchResult = await rootStore.flowStore.searchFlow(params);
    if (searchResult)
      searchResult.results.map((flow, index) => {
        if (
          "ARCHIVED" !== searchResult.results[index].status &&
          state.filtrearchive === "ARCHIVED"
        )
          delete searchResult.results[index];
        else {
          if (
            "ARCHIVED" === searchResult.results[index].status &&
            state.filtrearchive === "NOARCHIVED"
          )
            delete searchResult.results[index];
          else {
            if (reg)
              if (reg.includes("MU") || reg.includes("UK_MILITARY_LIST")) {
                if (
                  !searchResult.results[index].hasItarEccn &&
                  !searchResult.results[index].hasMlEccn
                )
                  delete searchResult.results[index];
              }

            if (reg)
              if (reg.includes("DU")) {
                if (!searchResult.results[index].hasDuEccn)
                  delete searchResult.results[index];
              }
          }

          if (
            params.departureCountry !== undefined &&
            params.departureCountry !== 0 &&
            params.departureCountry !== flow.departureCountryId
          )
            delete searchResult.results[index];

          if (
            params.endUserCountry !== undefined &&
            params.endUserCountry !== 0 &&
            params.endUserCountry !== flow.destinationCountryId
          )
            delete searchResult.results[index];
          if (
            params.customer !== undefined &&
            params.customer !== "" &&
            params.customer !== flow.customer
          )
            delete searchResult.results[index];
          if (searchResult.results[index] !== undefined) {
            if (
              state.creationDateEnd &&
              moment(searchResult.results[index].updatedAt).format(
                "YYYY MM DD"
              ) > moment(state.creationDateEnd).format("YYYY MM DD")
            ) {
              delete searchResult.results[index];
            } else if (
              state.creationDateBegin &&
              moment(searchResult.results[index].updatedAt).format(
                "YYYY MM DD"
              ) < moment(state.creationDateBegin).format("YYYY MM DD")
            ) {
              delete searchResult.results[index];
            }
          }
        }
      });
    setState({
      ...state,
      creationDateBegin: params.creationDateBegin
        ? params.creationDateBegin
        : state.creationDateBegin,
      creationDateEnd: params.creationDateEnd
        ? params.creationDateEnd
        : state.creationDateEnd,
      filtrearchive: value ? (value as Regulation) : state.filtrearchive,
      flows: searchResult ? (searchResult.results as any) : ([] as any),
      totalResults: searchResult ? searchResult.totalResults : 0,
      page: 1,
    });
    setLoading(false);
  };
  const handleChange = (key: Keys, value: string | boolean | number) => {
    search({
      creationDateBegin:
        key === "creationDateBegin"
          ? (value as string)
          : state.creationDateBegin,
      creationDateEnd:
        key === "creationDateEnd" ? (value as string) : state.creationDateEnd,
      flowType: state.flowType,
      departureCountry: state.departureCountry,
      endUserCountry: state.endUserCountry,
      customer: state.customer,
      ml: key === "filter" && value === "ALL" ? false : state.ml,
      pageNumber: state.page - 1,
      search: state.search,
      status: state.status as FlowStatus,
      usItar: key === "filter" && value === "ALL" ? false : state.usItar,
    });
  };

  const exportXLS = () => {
    //const columns = myRef.current.visibleColumns;

    if (pays === "SE") {
      reportingSweden();
    }
    if (pays === "GB") {
      reportingUk();
    } else {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

      const fileExtension = ".xlsx";
      var temp: Flow_rep[] = [];
      var dateJour = new Date();
      var afDate = moment(dateJour).format("YYYY-MM-DD");

      state.flows.map((flow, index) => {
        const temp_courant: Flow_rep = {
          Order: "",
          Release_date: "",
          Departure: "",
          Destination: "",
          Customer: "",
          Product: "",
          National_classift: "",
          US_classif: "",
        };

        temp_courant.Order = flow.order_num;
        temp_courant.Release_date = moment(flow.createdAt).format("MMM D YYYY");
        temp_courant.Departure = flow.departureCountryName;
        temp_courant.Destination = flow.destinationCountryName;
        temp_courant.Customer = flow.customer;
        temp_courant.Product =
          (flow.flowAircraft
            ? flow.flowAircraft.model
            : flow.flowEquipments.length > 0
              ? flow.flowEquipments[0].model
              : "") || "";
        temp_courant.National_classift =
          (flow.flowAircraft
            ? flow.flowAircraft.model
            : flow.flowEquipments.length > 0
              ? flow.flowEquipments[0].nationalEccn
              : "") || "";
        temp_courant.US_classif =
          (flow.flowAircraft
            ? flow.flowAircraft.model
            : flow.flowEquipments.length > 0
              ? flow.flowEquipments[0].usEccn
              : "") || "";

        temp.push(temp_courant);
      });

      const ws = XLSX.utils.json_to_sheet(temp);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        afDate + " Reporting " + convertpay(pays || "") + fileExtension
      );
    }
  };

  const submitDelete = async (userId: number) => {
    const searchResult = await rootStore.flowStore.deleteFlow(userId);

    window.location.reload();
  };
  const convertpay = (s: string) => {
    if (s === "FR") return i18n.t("global.countries.FR");
    if (s === "BE") return i18n.t("global.countries.BE");
    if (s === "SG") return i18n.t("global.countries.SG");
    if (s === "UK") return i18n.t("global.countries.UK");
    if (s === "ES") return i18n.t("global.countries.SP");
    if (s === "IT") return i18n.t("global.countries.IT");
    if (s === "DE") return i18n.t("global.countries.DE");
    if (s === "NE") return i18n.t("global.countries.EU1");
    if (s === "SE") return i18n.t("global.countries.EU2");
    if (s === "NL") return i18n.t("global.countries.EU3");
    if (s === "US") return i18n.t("global.countries.US");
    if (s === "TW") return i18n.t("global.countries.TW");
    if (s === "KR") return i18n.t("global.countries.KR");
    if (s === "GB") return i18n.t("global.countries.GB");

    return s;
  };

  const reportingSweden = async () => {
    //const columns = myRef.current.visibleColumns;

    var temp: FlowDto[] = [];
    state.flows.map((flow, index) => {
      temp.push(state.flows[index]);
    });
    let excel_res = await api.reporting_sweden(temp);

    const link = document.createElement("a");
    const url = `${process.env.REACT_APP_PUBLIC}/` + excel_res;
    FileSaver.saveAs(
      url.replace("http:", "https:"),
      excel_res.split("/").pop()
    ); // Utilisez le nom de fichier renvoyé par l'API au lieu de fixer "reporting_DGA.xlsx"
  };

  const reportingUk = async () => {
    //const columns = myRef.current.visibleColumns;

    var temp: FlowDto[] = [];
    state.flows.map((flow, index) => {
      temp.push(state.flows[index]);
    });
    let excel_res = await api.reporting_uk(temp);
    const link = document.createElement("a");
    const url = `${process.env.REACT_APP_PUBLIC}/` + excel_res;
    FileSaver.saveAs(
      url.replace("http:", "https:"),
      excel_res.split("/").pop()
    ); // Utilisez le nom de fichier renvoyé par l'API au lieu de fixer "reporting_DGA.xlsx"
  };

  const renderForm = () => {
    return (
      <form className={styles.form}>
        <div className={styles.container}>
          <GridRow>
            {/* <GridCol md={3}>
              <FormLabel>{i18n.t("pages.new-flow.filtrearchive")}</FormLabel>
              <select
                id="regulation"
                value={state.filtrearchive || ""}
                onChange={(e) => {
                  search({ pageNumber: state.page - 1 }, e.target.value);
                }}
              >
                <option value="ALL">{i18n.t("pages.new-flow.lib1")}</option>
                <option value="NOARCHIVED">
                  {i18n.t("pages.new-flow.lib2")}
                </option>
                <option value="ARCHIVED">
                  {i18n.t("pages.new-flow.lib3")}
                </option>
              </select>
            </GridCol> */}

            <GridCol md={3}>
              <FormLabel>
                {i18n.t("pages.reporting.dateFrom")}{" "}
                {/* {i18n.t("pages.new-flow.general.from")} */}
              </FormLabel>
              <div className={styles.row}>
                <input
                  id="creation-date-begin"
                  type="date"
                  value={state.creationDateBegin || ""}
                  onChange={(e) =>
                    handleChange("creationDateBegin", e.target.value)
                  }
                />
              </div>
            </GridCol>

            <GridCol md={3}>
              <FormLabel>{i18n.t("pages.reporting.dateEnd")}</FormLabel>
              <div className={styles.row}>
                <input
                  id="creation-date-end"
                  type="date"
                  value={state.creationDateEnd || ""}
                  onChange={(e) =>
                    handleChange("creationDateEnd", e.target.value)
                  }
                />
              </div>
            </GridCol>
            <GridCol md={3}>
              <Button
                type="button"
                style={{ marginTop: 20, marginLeft: 50 }}
                onClick={() => {
                  exportXLS();
                }}
              >
                {i18n.t("pages.new-flow.general.expxls")}
              </Button>
            </GridCol>
          </GridRow>
        </div>
      </form>
    );
  };

  const renderListFlow = () => {
    const handleOpen = () => setState({ ...state, open: true });
    const handleClose = () => setState({ ...state, open: false });
    const handleOpen1 = () => setState({ ...state, open1: true });
    const handleClose1 = () => setState({ ...state, open1: false });
    return (
      <div className={styles.container}>
        <Table>
          <TableHead>
            <TableRow>
              {rootStore.userStore.user?.role == "SUPER_ADMINISTRATOR" && (
                <TableHeaderCell> Tenant</TableHeaderCell>
              )}
              <TableHeaderCell>
                {i18n.t("pages.search-flow.id1")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.creation-dateArchive")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.departure-country")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.end-user-country")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.customers-company")}
              </TableHeaderCell>

              <TableHeaderCell>
                {i18n.t("pages.search-flow.model")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.folder")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.du-ml")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.regulation")}
              </TableHeaderCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {state.flows.map((flow, index) => {
              let label = flowStatus["CLASSIFICATION"];

              if (flowStatus[flow.status]) {
                label = flowStatus[flow.status];
              }

              return (
                <TableRow key={index}>
                  {rootStore.userStore.user?.role == "SUPER_ADMINISTRATOR" && (
                    <td> {flow.supplier}</td>
                  )}
                  <td>
                    {flow.order_num && flow.order_num !== "null"
                      ? flow.order_num
                      : ""}
                  </td>
                  <td>{moment(flow.createdAt).format("MMM D YYYY")}</td>
                  <td>{flow.departureCountryName}</td>
                  <td>{flow.destinationCountryName}</td>
                  <td>{flow.customer}</td>

                  <td>
                    {flow.flowAircraft
                      ? flow.flowAircraft.model
                      : flow.flowEquipments.length > 0
                        ? flow.flowEquipments[0].model
                        : ""}
                  </td>
                  <td>
                    <img
                      src={require(`../../../statics/images/folder1.png`)}
                      alt={"Folder"}
                    />
                  </td>
                  <td>
                    {flow.hasDuEccn && <Flag>DU</Flag>}

                    {flow.hasMlEccn && <Flag>ML</Flag>}
                  </td>
                  <td>
                    {flow.hasEarEccn && <Flag>EAR</Flag>}

                    {flow.hasItarEccn && <Flag>ITAR</Flag>}
                  </td>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Pagination
          page={state.page}
          count={state.totalResults}
          rowsPerPage={1000}
          onPageChange={(_, page) => handleChange("page", page)}
        />
      </div>
    );
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="NONE" />

      <HeaderReportMenu pages="REPORTING" rootStore={rootStore} />

      <div className={styles.body}>
        <Title tag="h2">
          {i18n.t("pages.search-flow.Reporting")} {convertpay(pays || "")}
        </Title>
        <div
          className="post__content"
          dangerouslySetInnerHTML={{ __html: msg.Message }}
        ></div>
        <div>
          <a target="_blank" rel="noopener noreferrer" href={msg.Url1}>
            {msg.Lib_url1}
          </a>
          <a
            target="_blank"
            style={{ marginLeft: "50px" }}
            rel="noopener noreferrer"
            href={msg.Url2}
          >
            {msg.Lib_url2}
          </a>
        </div>
        {renderForm()}
        {state.flows.length > 0 ? (
          renderListFlow()
        ) : (
          <p>{i18n.t("pages.search-flow.no-result")}</p>
        )}
        {loading && <Loader />}
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(ReportingPays));
