import * as React from "react";
import cx from "classnames";
import classes from "./styles.module.scss";
import CloseIcon from "@material-ui/icons/Close";

interface INotificationProps {
  /** Determines the status of the notification */
  status?: "info" | "warning" | "error" | "success";
  /** Communicate an important or an advisory information to the user*/
  role?: "status" | "alert";
  /** Title of the notification */
  title?: string;
  /** Delai in milli seconde to hide notification */
  autoHideDuration?: number;
  /** The content of the notification */
  children?: React.ReactNode;
  /** The callback for when the notification closed */
  onClose?: () => void;
}

interface INotificationState {
  visible: boolean;
}

const style = {
  close: {
    color: "white",
    fontSize: 14,
  },
};

export default class Notification extends React.Component<
  INotificationProps,
  INotificationState
> {
  timerAutoHide?: NodeJS.Timeout;

  static defaultProps = {
    status: "info",
    role: "status",
  };

  constructor(props: INotificationProps) {
    super(props);
    this.state = { visible: true };
  }

  componentDidMount() {
    this.setAutoHideTimer();
  }

  componentWillUnmount() {
    if (this.timerAutoHide) clearTimeout(this.timerAutoHide);
  }

  render() {
    const { status, role, title, children } = this.props;
    return (
      <div
        className={
          this.state.visible
            ? cx(classes["ds-notification"], {
                [classes["ds-notification--info"]]: status === "info",
                [classes["ds-notification--success"]]: status === "success",
                [classes["ds-notification--warning"]]: status === "warning",
                [classes["ds-notification--error"]]: status === "error",
              })
            : cx(classes["ds-notification--hidden"])
        }
        role={role}
      >
        <span className={cx(classes["ds-notification__title"])}>{title}</span>
        <span className={cx(classes["ds-notification__text"])}>{children}</span>
        <button
          className={cx(classes["ds-button--close"])}
          aria-label="Close"
          type="button"
          onClick={this.handleClick}
        >
          <CloseIcon style={style.close} />
        </button>
      </div>
    );
  }

  handleClick = (): void => {
    this.setState({ visible: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  private setAutoHideTimer() {
    if (this.timerAutoHide) clearTimeout(this.timerAutoHide);

    if (this.props.autoHideDuration && this.props.autoHideDuration > 0) {
      this.timerAutoHide = setTimeout(() => {
        this.handleClick();
      }, this.props.autoHideDuration);
    }
  }
}
