/* Global import */
import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
window.moment = moment;
/* Custom import */
import App from "./app/App";
import { MobXProvider } from "./app/stores/mobXContext"; // Le fournisseur MobX que vous avez créé
import * as serviceWorker from "./serviceWorker";
import RootStore from "./app/stores/rootStore";
const rootStore = new RootStore(); // Créez une instance de RootStore

ReactDOM.render(
  <MobXProvider>
    <App rootStore={rootStore} />
  </MobXProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
