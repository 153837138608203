/* Styles import */
import styles from "./searchEccn.module.css";
import Api from "../../utils/api";
import moment from "moment";
import "moment/locale/fr";
import jsPDF from "jspdf";
import itarml from "./itarMl.json";
import Modal from "@material-ui/core/Modal";
//CONSTANT
import { countries } from "../../utils/constant";
import Box, { BoxProps } from "@mui/material/Box";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
/* Global import */
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { observer, inject } from "mobx-react";
import {
  FormGroup,
  TextArea,
  Checkbox,
  TextInput,
  Title,
  SearchInput,
  IconButton,
  Loading,
  Button,
} from "../../components/horizon-components-react/src/components";
import Visibility from "@material-ui/icons/Close";
import i18n from "i18next";
import html2canvas from "html2canvas";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useNavigate } from "react-router-dom";
/* Custom import */
import RightPanel from "../rightPanel";
import EccnComponent from "../eccn";
import EccnComponentOrigine from "../eccn origine";
import { CCountry, itemType } from "../../utils/constant";
import { generateSelectOption } from "../../utils/generator";
import { promises } from "fs";
import axios from "axios";
import { getCountry } from "../../utils/navigator";
import TextFieldMUI from "@mui/material/TextField";
import IconButtonMUI from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InputMUI from "@mui/material/OutlinedInput";

import CrossIcon from "@mui/icons-material/Clear";
import Magnifier from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { wait } from "@testing-library/react";
import configuration from "../../configuration";

interface Props {
  isOpen: boolean;
  onClose: (eccn?: ClassificationEccn) => void;
  rootStore: import("../../stores/rootStore").default;
  choix_rule: any;
  choix_index_rule: number;
  exportabilityMode?: boolean;
  ouClick: string;
  onChoixRuleChange: (nouveauChoixRule: any, index: number) => void;
  setShowSearchEccn: (showSearchEccn: boolean) => void;
}

interface State {
  selectedEccn?: Eccn;
  countries?: CCountry[];
  selectedEccnUS?: Eccn;
  prevSearchDeepSearch: string;
  checkbox: boolean;
  go: boolean;
  forcemono: boolean;
  noSoloEccn: Eccn[];
  Q_checklist: Q_checklist[];
  Reponses: string[];
  ReponsesSousQ: { type: string; reponse: string }[][];
  textlibre: string;
  all_liste: boolean;
  titre_modal: boolean;
  titre_modal_text: string;
  intangible: boolean;
  wizard: boolean;
  search_type: number;
  texte_libre: boolean;
  open: boolean;
  setOpen: boolean;
  open1: boolean;
  setOpen1: boolean;
}

const SearchEccnWizard: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [searchTypeFromStore, setSearchTypeFromStore] =
    useState<string>("KEYWORD");
  const [selectedEccn, setSelectedEccn] = useState<Eccn | undefined>();
  //const [countries, setCountries] = useState<any[]>();
  const [selectedEccnUS, setSelectedEccnUS] = useState<Eccn | undefined>();
  const [prevSearchDeepSearch, setPrevSearchDeepSearch] = useState<string>("");
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [go, setGo] = useState<boolean>(false);
  const [forcemono, setForcemono] = useState<boolean>(false);
  const [noSoloEccn, setNoSoloEccn] = useState<Eccn[]>([]);
  const [Q_checklist, setQChecklist] = useState<Q_checklist[]>([]);
  const [Reponses, setReponses] = useState<string[]>([]);
  const [ReponsesSousQ, setReponsesSousQ] = useState<
    { type: string; reponse: string }[][]
  >([]);
  const [textlibre, setTextlibre] = useState<string>("");
  const [all_liste, setAllListe] = useState<boolean>(false);
  const [titre_modal, setTitreModal] = useState<boolean>(false);
  const [titre_modal_text, setTitreModalText] = useState<string>("");
  const [intangible, setIntangible] = useState<boolean>(false);
  const [wizard, setWizard] = useState<boolean>(false);
  const [search_type, setSearch_type] = useState<number>(1);
  const [search_templete, setSearch_templete] = useState<number>(0);
  const [texte_libre, setTexteLibre] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [open1, setOpen1] = useState<boolean>(false);
  const [searchInputLocal, setSearchInput] = useState<string>("");
  const [countryCode, setCountryCode] = useState<EccnCountry>(getCountry());
  const [usOrigin, setUsOrigin] = useState<boolean>(false);
  //const [ReponsesSousQ, setReponsesSousQ] = useState<any[][][]>([]);
  const resultRef = useRef<HTMLDivElement>(null);

  const api = new Api();

  const handleChangeSousQ = (
    event: any,
    qIndex: number,
    sqIndex: number,
    type: string
  ) => {
    setReponsesSousQ(ReponsesSousQ.slice());
    const newReponsesSousQ: any[][] = [...ReponsesSousQ];
    newReponsesSousQ[qIndex][sqIndex] = {
      type: type,
      reponse: event.target.value,
    };
    setReponsesSousQ(newReponsesSousQ);
    props.rootStore.eclabsStore.setReponseSousQ(newReponsesSousQ);
  };

  const handleChangequestion = (e: any, index: number) => {
    let updatedReponses = [...Reponses]; // Créer une copie du tableau Reponses
    updatedReponses[index] = e.target.value; // Mettre à jour la valeur à l'index spécifié
    setReponses(updatedReponses);

    for (let i = 0; i < ReponsesSousQ[index].length; i++) {
      ReponsesSousQ[index][i] = {
        type: ReponsesSousQ[index][i].type || "",
        reponse: "",
      };
    }

    props.rootStore.eclabsStore.setReponse(updatedReponses);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tenant = props.rootStore.userStore.user?.tenantId;

        if (!props.rootStore.tenantStore.questionsResponse)
          props.rootStore.tenantStore.getQuestionsResponse();

        const questionsData = props.rootStore.tenantStore.questionsResponse;
        // Initialisation des Réponses
        const initialReponses = questionsData.map(() => "");

        const initialReponsesSousQ = questionsData.map(
          (q: { sous_q: { yes_no: any }[] }) =>
            q.sous_q.map((sq: { yes_no: any }) => ({
              type: sq.yes_no || "",
              reponse: "",
            }))
        );

        if (!props.rootStore.tenantStore.tenantAhData)
          props.rootStore.tenantStore.getTenantAhData();
        const tenantAhData = props.rootStore.tenantStore.tenantAhData;

        const tenanttempleteData =
          props.rootStore.tenantStore.tenanttempleteData;

        //setCountries(countriesResponse.data);
        setQChecklist(questionsData);
        setReponses(initialReponses);
        setReponsesSousQ(initialReponsesSousQ);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [props.rootStore.tenantStore.questionsResponse]);

  const reset = async () => {
    const tenant = props.rootStore.userStore.user?.tenantId;

    // Appel API pour les questions
    const questionsResponse = await fetch(
      `${
        configuration.LARAVEL_API
      }/get_Question?tenant=${encodeURIComponent(tenant || "")}`
    );
    const questionsData = await questionsResponse.json();

    // Initialisation des Réponses
    const initialReponses = questionsData.map(() => "");

    const initialReponsesSousQ = questionsData.map(
      (q: { sous_q: { yes_no: any }[] }) =>
        q.sous_q.map((sq: { yes_no: any }) => ({
          type: sq.yes_no || "",
          reponse: "",
        }))
    );

    setQChecklist(questionsData);
    setReponses(initialReponses);
    setReponsesSousQ(initialReponsesSousQ);
    props.rootStore.eclabsStore.eccns = [];
    props.rootStore.eclabsStore.clear();
    setGo(false);
  };

  const compact_rule = () => {
    let resutat = "";
    for (let i = 0; i < Reponses.length; i++) {
      if (Reponses[i] == "OUI") {
        resutat = resutat + "Y";
      } else if (Reponses[i] == "NON") {
        resutat = resutat + "N";
      } else {
        resutat = resutat + "*";
      }

      if (ReponsesSousQ[i] && (Reponses[i] == "OUI" || Reponses[i] == "NON")) {
        resutat = resutat + ">";
        for (let j = 0; j < ReponsesSousQ[i].length; j++) {
          if (ReponsesSousQ[i][j].reponse == "OUI") {
            resutat = resutat + "Y";
          } else if (ReponsesSousQ[i][j].reponse == "NON") {
            resutat = resutat + "N";
          } else {
            resutat = resutat + "*";
          }

          resutat = resutat + "+";
        }

        if (resutat.length > 0) {
          resutat = resutat.slice(0, -1);
        }
      }
      resutat = resutat + ",";
    }

    if (resutat.length > 0) {
      resutat = resutat.slice(0, -1);
    }
    // alert(props.choix_index_rule)
    while (resutat.includes("+*,")) {
      resutat = resutat.replace("+*,", ",");
    }
    while (resutat.includes(">*,")) {
      resutat = resutat.replace(">*,", ",");
    }
    while (resutat.endsWith("+*")) {
      resutat = resutat.slice(0, -2);
    }
    while (resutat.endsWith(">*")) {
      resutat = resutat.slice(0, -2);
    }
    props.onChoixRuleChange(resutat, props.choix_index_rule);
    props.setShowSearchEccn(false);
  };

  const dispatch_rule = (rule: string) => {
    if (rule) {
      let result = rule.split(",");
      let initialReponses = result.map((item) =>
        item.charAt(0) == "Y" ? "OUI" : item.charAt(0) == "N" ? "NON" : ""
      );

      setReponses(initialReponses);

      for (let i = 0; i < result.length; i++) {
        if (result[i].includes(">")) {
          // Traitement pour les éléments contenant '>'
          const parts = result[i].split(">");
          const afterGreaterThan = parts[1];

          const final = afterGreaterThan.split("+");

          for (let j = 0; j < final.length; j++) {
            setReponsesSousQ(ReponsesSousQ.slice());
            const newReponsesSousQ: any[][] = [...ReponsesSousQ];
            newReponsesSousQ[i][j] = {
              type: parts[0],
              reponse: final[j] == "Y" ? "OUI" : final[j] == "N" ? "NON" : "",
            };
            setReponsesSousQ(newReponsesSousQ);
          }
        }
      }
    }
  };

  const renderEccn = () => {
    let eccnLists: { [key: string]: Eccn[] };
    let pays_temp = "";
    eccnLists = props.rootStore.eclabsStore.eccnLists;

    // console.log(eccnLists)
    //console.log(props.rootStore.eclabsStore.eccns[0])
    return (
      <div id="print">
        <FormGroup>
          {Q_checklist.map((question, index) => (
            <Grid
              container
              alignItems="center"
              spacing={2}
              justify="space-between"
              key={index}
            >
              <Grid item xs={10}>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: question.libelle!.replace(/\n/g, "<br />"),
                  }}
                ></Typography>
              </Grid>
              <Grid item xs={2}>
                <RadioGroup
                  aria-label={question.libelle}
                  name={question.libelle}
                  row
                  value={Reponses[index]}
                  onChange={(e) => handleChangequestion(e, index)}
                >
                  <FormControlLabel
                    value="OUI"
                    control={<Radio color="primary" size="small" />}
                    label={i18n.t(
                      "pages.admin.tenants.table.wizard.true-value"
                    )}
                  />
                  <FormControlLabel
                    value="NON"
                    control={<Radio color="primary" size="small" />}
                    label={i18n.t(
                      "pages.admin.tenants.table.wizard.false-value"
                    )}
                  />
                </RadioGroup>
              </Grid>
              {question.sous_q &&
                question.sous_q.map(
                  (sous_q, sous_q_index) =>
                    ((Reponses[index] === "OUI" && sous_q.yes_no === "Y") ||
                      (Reponses[index] === "NON" && sous_q.yes_no === "N")) && (
                      <Grid
                        container
                        style={{ marginLeft: "20px" }}
                        key={sous_q_index}
                      >
                        <Grid item xs={10}>
                          <Typography
                            variant="body2"
                            style={{ paddingTop: "12px" }}
                            dangerouslySetInnerHTML={{
                              __html: sous_q.libelle!.replace(/\n/g, "<br />"),
                            }}
                          ></Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2">
                            <RadioGroup
                              aria-label={sous_q.libelle}
                              name={sous_q.libelle}
                              row
                              value={ReponsesSousQ[index][sous_q_index].reponse}
                              onChange={(e) =>
                                handleChangeSousQ(
                                  e,
                                  index,
                                  sous_q_index,
                                  sous_q.yes_no || ""
                                )
                              }
                            >
                              <FormControlLabel
                                value="OUI"
                                control={<Radio color="primary" size="small" />}
                                label={
                                  <Typography variant="body2">
                                    {i18n.t(
                                      "pages.admin.tenants.table.wizard.true-value"
                                    )}
                                  </Typography>
                                }
                              />
                              <FormControlLabel
                                value="NON"
                                control={<Radio color="primary" size="small" />}
                                label={
                                  <Typography variant="body2">
                                    {" "}
                                    {i18n.t(
                                      "pages.admin.tenants.table.wizard.false-value"
                                    )}
                                  </Typography>
                                }
                              />
                            </RadioGroup>
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                )}
            </Grid>
          ))}
          <br />
        </FormGroup>
      </div>
    );
  };
  useEffect(() => {
    dispatch_rule(props.choix_rule);
  }, [props.isOpen]);

  return (
    <RightPanel display={props.isOpen} onClose={() => props.onClose()}>
      <Title tag="h2">{i18n.t("components.searchEccn.titrehelp")}</Title>

      <div className={styles.divider}></div>

      {renderEccn()}
      <div className={styles.footer} ref={resultRef}>
        <div style={{ marginRight: "auto" }}>
          <Button
            id="exportpdf"
            size="large"
            primary
            type="button"
            className={styles.button}
            onClick={() => {
              compact_rule();
              setOpen1(false);
            }}
          >
            {i18n.t("global.save")}
          </Button>
        </div>

        <div style={{ marginLeft: "10px" }}>
          <Button
            id="reset"
            size="large"
            type="button"
            className={styles.button}
            onClick={() => {
              reset();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    </RightPanel>
  );
};

export default inject("rootStore")(observer(SearchEccnWizard));
