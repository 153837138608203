import React, { useState, useEffect } from "react";
import { TextField, InputAdornment } from "@mui/material";
import {
  AiOutlineEuro,
  AiOutlineDollar,
  AiOutlineCrown,
  AiOutlinePound,
} from "react-icons/ai";
import { isValidNumberKey } from "../../utils/generator";

type Currency = "EUR" | "USD" | "CAD" | "SEK" | "GBP";

type MonetaryInputProps = {
  sx?: any;
  value: number;
  label?: string;
  id?: string;
  currency?: Currency;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  showCurrency?: boolean;
  onChange: (value: number) => void;
};

const getCurrencyIcon = (currency?: Currency) => {
  switch (currency) {
    case "EUR":
      return <AiOutlineEuro fontSize={24} />;
    case "USD":
    case "CAD":
      return <AiOutlineDollar fontSize={24} />;
    case "SEK":
      return <AiOutlineCrown fontSize={24} />;
    case "GBP":
      return <AiOutlinePound fontSize={24} />;
    default:
      return null;
  }
};

const formatNumber = (num: number) => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const parseFormattedNumber = (formatted: string) => {
  return parseFloat(formatted.replace(/\s/g, ""));
};

const MonetaryInput: React.FC<MonetaryInputProps> = ({
  sx,
  value,
  currency,
  label,
  id,
  disabled,
  error,
  helperText,
  showCurrency = true,
  onChange,
}) => {
  const [displayValue, setDisplayValue] = useState(formatNumber(value));

  useEffect(() => {
    setDisplayValue(formatNumber(value));
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/\s/g, "");

    if (rawValue === "") {
      setDisplayValue("");
      onChange(0);
    } else if (!isNaN(Number(rawValue))) {
      const numericValue = parseFloat(rawValue);
      setDisplayValue(formatNumber(numericValue));
      onChange(numericValue);
    } else {
      setDisplayValue(event.target.value);
    }
  };

  return (
    <TextField
      sx={sx}
      label={label}
      id={id}
      error={error || false}
      helperText={helperText || ""}
      disabled={disabled || false}
      value={displayValue}
      onChange={handleChange}
      InputProps={{
        onKeyDown: (e) => {
          if (!isValidNumberKey(e.key)) {
            e.preventDefault();
          }
        },
        sx: {
          "& .MuiInputBase-input": { textAlign: "right" },
        },
        endAdornment: currency && showCurrency && (
          <InputAdornment position="end">
            {getCurrencyIcon(currency)}
          </InputAdornment>
        ),
      }}
      fullWidth
      variant="standard"
    />
  );
};

export default MonetaryInput;