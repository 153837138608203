/* Styles import */
import styles from "./autoComplete.module.css";

/* Global import */
import React, { Component } from "react";
import { TextInput } from "../../components/horizon-components-react/src/components";

interface Props {
  label: string;
  onChange?: (value: any) => void;
  options: Option[];
  required?: boolean;
  errorText?: string;
  id?: string;
  value?: any;
  disabled?: boolean;
  returnLabel?: boolean;
}
interface State {
  search: string;
  isFinish: boolean;
}

export default class AutoComplete extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      search: "",
      isFinish: false,
    };
  }
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(props: Props) {
    if (props.value) {
      if (props.returnLabel) {
        this.setState({
          search: props.value,
          isFinish: true,
        });
      } else {
        const option = props.options.find((_) => _.value === props.value);

        this.setState({
          search: option ? option.label : "",
          isFinish: true,
        });
      }
    }
  }

  search(value: string) {
    this.setState({ search: value, isFinish: false });
  }

  handleChange(option: Option) {
    this.setState({ isFinish: true });

    if (this.props.onChange) {
      if (this.props.returnLabel) {
        this.props.onChange(option.label);
      } else {
        this.props.onChange(option.value);
      }
    }
  }

  render() {
    return (
      <div className={styles.body}>
        <TextInput
          className={styles.input}
          id={this.props.id}
          type="text"
          label={this.props.label}
          value={this.state.search || ""}
          required={Boolean(this.props.required)}
          errorText={this.props.errorText}
          disabled={this.props.disabled}
          onChange={(e) => this.search(e.target.value)}
        />

        {this.state.search.length > 0 && this.state.isFinish === false && (
          <ul className={styles.list}>
            {this.props.options
              .filter(
                (option) =>
                  option.label
                    .toLowerCase()
                    .indexOf(this.state.search.toLowerCase()) !== -1,
              )
              .map((option, index) => {
                return (
                  <li key={index} onClick={() => this.handleChange(option)}>
                    {option.label}
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    );
  }
}
