/* Styles import */
import styles from "../subHeader/subHeader.module.css";

/* Global import */
import React from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";

/* Custom import */
import SubHeader from "../subHeader";
import configuration from "../../configuration";
interface Props {
  pages: Pages;
  type: ScreeningMode;
  rootStore: import("../../stores/rootStore").default;
}
type ScreeningMode = "BASIC" | "CLASSIC";
type Pages =
  | "ENTITY"
  | "INDIVIDUAL"
  | "MASS"
  | "EU"
  | "US"
  | "OTHER"
  | "ALL"
  | "AHMENU"
  | "DUEDILIGENCE";

let menu = "NO";

const headerScreeningComponent = ({ pages, type, rootStore }: Props) => {
  fetch(
    `${configuration.LARAVEL_API}/getTenantAh?name=` +
      encodeURIComponent(`${rootStore.userStore.user?.tenantId}`),
    {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((ok) => {
      menu = ok.ah;
    });

  if (type === "BASIC") {
    return (
      <SubHeader>
        <Link
          id="entity"
          className={`${styles.link} ${pages === "ALL" ? styles.selected : {}}`}
          to="/screening/basic/All"
        >
          {i18n.t("pages.screening.menu.all")}
        </Link>

        <Link
          id="entity"
          className={`${styles.link} ${pages === "US" ? styles.selected : {}}`}
          to="/screening/basic/us"
        >
          {i18n.t("pages.screening.menu.us")}
        </Link>

        <Link
          id="individual"
          className={`${styles.link} ${pages === "EU" ? styles.selected : {}}`}
          to="/screening/basic/eu"
        >
          {i18n.t("pages.screening.menu.eu")}
        </Link>

        <Link
          id="other"
          className={`${styles.link} ${
            pages === "OTHER" ? styles.selected : {}
          }`}
          to="/screening/basic/other"
        >
          {i18n.t("pages.screening.menu.other")}
        </Link>

        {menu == "MEU" && (
          <Link
            id="individual"
            className={`${styles.link} ${
              pages === "AHMENU" ? styles.selected : {}
            }`}
            to="/screening/basic/ah"
          >
            AH MEU list
          </Link>
        )}
        <Link
          id="individual"
          className={`${styles.link} ${
            pages === "DUEDILIGENCE" ? styles.selected : {}
          }`}
          to="/screening/basic/due_diligence"
        >
          Due diligence
        </Link>
      </SubHeader>
    );
  }
  return (
    <SubHeader>
      <Link
        id="entity"
        className={`${styles.link} ${
          pages === "ENTITY" ? styles.selected : {}
        }`}
        to="/screening/entity"
      >
        {i18n.t("pages.screening.menu.entity")}
      </Link>

      <Link
        id="individual"
        className={`${styles.link} ${
          pages === "INDIVIDUAL" ? styles.selected : {}
        }`}
        to="/screening/end-user"
      >
        {i18n.t("pages.screening.menu.individual")}
      </Link>
    </SubHeader>
  );
};

export default headerScreeningComponent;
