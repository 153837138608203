import React, { ReactNode, SFC, Fragment } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";

type LabelProps = {
  /** A label ID to link the label and the flag */
  id: string;
  /** The label */
  value: string;
};

type FlagProps = {
  /** A label to display before the flag */
  label?: LabelProps;

  /** The content of the flag */
  children: ReactNode;

  /** Additional classes. */
  className?: string;
};

const Flag: SFC<FlagProps> = ({ label, children, className }) => (
  <Fragment>
    {label && (
      <span id={label.id} className={cx(classes["ds-flag-label"])}>
        {label.value}
      </span>
    )}
    <span className={cx(classes["ds-flag"], className)}>{children}</span>
  </Fragment>
);

export default Flag;
