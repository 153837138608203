/* Styles import */
import styles from "../license.module.css";
import AutocompleteMUI from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Title,
  GridContainer,
  GridRow,
  GridCol,
  FormGroup,
  TextInput,
  Button,
  RadioGroup,
  Radio,
  TextArea,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import fileDownload from "js-file-download";

import { inject, observer } from "mobx-react";
import { FormLabel, FormControlLabel } from "@material-ui/core";
// eslint-disable-next-line

/* Custom import */
import AppBar from "../../../components/appBar";
import Footer from "../../../components/footer";
import HeaderLicense from "../../../components/headerLicense";
import {
  generateSelectOption,
  generateLicenseTypeOptions,
  licenseStatus,
} from "../../../utils/generator";
import {
  regulation,
  currency,
  countryCurrencyMap,
  CCountry,
} from "../../../utils/constant";
import AutoComplete from "../../../components/autoComplete";
import ConsigneeComponent from "../../../components/consignee";
import EquipmentComponent from "../../../components/equipment";
import license from "../../../components/matching/license";
import Api from "../../../utils/api";
import UploadInput from "../../../components/uploadInput";
import moment from "moment";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import configuration from "../../../configuration";

interface State {
  id: number;
  licenseNumber?: string;
  model?: string;
  countries: CCountry[];
  issuanceDate?: string;
  expirationDate?: string;
  uploadLicense?: File | null;
  uploadLicense1?: File | null;
  uploadLicense2?: File | null;
  uploadLicense3?: File | null;
  orderContract?: File | null;
  productInformation?: File | null;
  departureCountryId?: string;
  destinationCountryId?: number;
  endUser?: string;
  supplier?: string;
  destinataire?: string;
  provisos?: string;
  num_order?: string;
  manufacturer?: string;
  currency?: string;
  totalAmount?: number;
  consignees: Consignee[];
  endUseComment?: string | "";
  comment?: string;
  loading: boolean;
  cnr?: boolean;
  cuf?: boolean;
  disabledInputs: boolean;
  firstRecipient?: string;
  flowId?: string;
  temporaryLicense?: boolean;
  endUse: EndUseType | "";
  licenseType?: string;
  regulation?: string;
  equipments: LicenseEquipment[];
  status: string;
}
type MsgType = {
  code_msg: string;
  Regle: string;
  Message: string;
  Lib_url1: string;
  Url1: string;
  Lib_url2: string;
  Url2: string;
  Lib_url3: string;
  Url3: string;
};

interface Props {
  rootStore: import("../../../stores/rootStore").default;
  msgType: MsgType;
}
const RequestLicense: React.FC<Props> = ({ rootStore, msgType }) => {
  const api = new Api();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [state, setState] = useState({
    id: 0,
    model: "",
    supplier: "",
    destinataire: "",
    provisos: "",
    num_order: "",
    manufacturer: "",
    departureCountryId: "",
    destinationCountryId: 0,
    endUser: "",
    currency: "",
    cnr: false,
    cuf: false,
    totalAmount: 0,
    uploadLicense: new File([""], ""),
    uploadLicense1: new File([""], ""),
    uploadLicense2: new File([""], ""),
    uploadLicense3: new File([""], ""),
    orderContract: new File([""], ""),
    productInformation: new File([""], ""),
    firstRecipient: "",
    temporaryLicense: false,
    licenseNumber: "",
    issuanceDate: "",
    expirationDate: "",
    equipments: [{}],
    consignees: [
      {
        consigneeCountryId: 0,
        consigneeName: "",
      },
    ],
    loading: false,
    disabledInputs: false,
    endUseComment: "",
    comment: "",
    ITARmsg: msgType,
    EARmsg: msgType,
    msg: msgType,
    MLmsg: msgType,

    regulation: "",
    licenseType: "",
    endUse: "CIVIL",
    status: "PENDING",
  });
  const [countries, setCountries] = useState([
    {
      label: "",
      value: "",
      id: 0,
    },
  ]);

  const [entite, setEntite] = useState<string | undefined>(
    rootStore.licenseStore.entite !== "ALL"
      ? rootStore.licenseStore.entite
      : "ALL"
  );
  const getLicense = async (id: number) => {
    const licenseInfo = await rootStore.licenseStore.searchLicenseById(id);

    if (licenseInfo) {
      setState({
        ...state,
        ITARmsg: {
          code_msg: "",
          Regle: "",
          Message: "",
          Lib_url1: "",
          Url1: "",
          Lib_url2: "",
          Url2: "",
          Lib_url3: "",
          Url3: "",
        },
        EARmsg: {
          code_msg: "",
          Regle: "",
          Message: "",
          Lib_url1: "",
          Url1: "",
          Lib_url2: "",
          Url2: "",
          Lib_url3: "",
          Url3: "",
        },
        msg: {
          code_msg: "",
          Regle: "",
          Message: "",
          Lib_url1: "",
          Url1: "",
          Lib_url2: "",
          Url2: "",
          Lib_url3: "",
          Url3: "",
        },
        MLmsg: {
          code_msg: "",
          Regle: "",
          Message: "",
          Lib_url1: "",
          Url1: "",
          Lib_url2: "",
          Url2: "",
          Lib_url3: "",
          Url3: "",
        },
        id: licenseInfo.id || 0,
        regulation: licenseInfo.regulation,
        model: licenseInfo.model || "",
        supplier: licenseInfo.supplier || "",
        destinataire: licenseInfo.destinataire || "",
        provisos: licenseInfo.provisos || "",
        num_order: licenseInfo.num_order || "",
        manufacturer: licenseInfo.manufacturer || "",
        licenseType: licenseInfo.licenseType,
        departureCountryId: licenseInfo.departureCountryId || "",
        destinationCountryId: licenseInfo.destinationCountryId || 0,
        endUser: licenseInfo.companyName || "",
        currency: licenseInfo.currency || "",
        cnr: licenseInfo.cnr || false,
        cuf: licenseInfo.cuf || false,
        consignees: (licenseInfo.consignees.length &&
          licenseInfo.consignees) || [
          {
            consigneeCountryId: 0,
            consigneeName: "",
          },
        ],
        totalAmount: licenseInfo.totalAmount || 0,
        equipments: (licenseInfo.equipments.length &&
          licenseInfo.equipments) || [{}],
        endUse: licenseInfo.endUse || "CIVIL",
        endUseComment: licenseInfo.endUseComment || "",
        //disabledInputs: rootStore.userStore.isAuthorized(['EXPORT_CONTROL_MANAGER']) ? false : licenseInfo.status !== 'PENDING',
        disabledInputs: false,
        comment: licenseInfo.comment || "",
        firstRecipient: licenseInfo.recipient || "",
        temporaryLicense: licenseInfo.temporaryLicense || false,
        licenseNumber: licenseInfo.licenseNumber || "",
        issuanceDate:
          (licenseInfo.issuanceDate &&
            moment(licenseInfo.issuanceDate).format("YYYY-MM-DD")) ||
          "",
        expirationDate:
          (licenseInfo.expirationDate &&
            moment(licenseInfo.expirationDate).format("YYYY-MM-DD")) ||
          "",
      });

      let regulelabel = "";
      if (regulation) {
        regulation.map((reg, index) => {
          if (reg.value === licenseInfo.regulation) regulelabel = reg.label;
        });
      }
      const logr = api.AddLog(
        rootStore.userStore.user?.tenantId || "",
        rootStore.userStore.user?.email || "",
        rootStore.userStore.user?.role || "",
        "Licence load",
        "",
        regulelabel,
        "" + state.departureCountryId,
        "" + licenseInfo.destinationCountryName,
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      );

      if (licenseInfo.mediaId) {
        let mediaLicense = await rootStore.licenseStore.getMedia(
          licenseInfo.mediaId
        );

        if (mediaLicense) {
          setState({ ...state, uploadLicense: mediaLicense });
        }
      }

      if (licenseInfo.mediaId1) {
        let mediaLicense1 = await rootStore.licenseStore.getMedia(
          licenseInfo.mediaId1
        );

        if (mediaLicense1) {
          setState({ ...state, uploadLicense1: mediaLicense1 });
        }
      }

      if (licenseInfo.mediaId2) {
        let mediaLicense2 = await rootStore.licenseStore.getMedia(
          licenseInfo.mediaId2
        );

        if (mediaLicense2) {
          setState({ ...state, uploadLicense2: mediaLicense2 });
        }
      }

      if (licenseInfo.mediaId3) {
        let mediaLicense3 = await rootStore.licenseStore.getMedia(
          licenseInfo.mediaId3
        );

        if (mediaLicense3) {
          setState({ ...state, uploadLicense3: mediaLicense3 });
        }
      }

      if (licenseInfo.mediaId4) {
        let mediaLicense4 = await rootStore.licenseStore.getMedia(
          licenseInfo.mediaId4
        );

        if (mediaLicense4) {
          setState({ ...state, orderContract: mediaLicense4 });
        }
      }

      if (licenseInfo.mediaId5) {
        let mediaLicense5 = await rootStore.licenseStore.getMedia(
          licenseInfo.mediaId5
        );

        if (mediaLicense5) {
          setState({ ...state, productInformation: mediaLicense5 });
        }
      }
    }
  };

  const selection_pay = (s: string) => {
    //alert(s);
    var options = document.querySelectorAll("#regulation option");
    const sb = document.getElementById("regulation") as HTMLSelectElement;
    const currencyForCountry = getCurrencyForCountry(s);
    setState({ ...state, departureCountryId: s, currency: currencyForCountry });
    options.forEach((o) => o.remove());

    const option_tmp1 = new Option(i18n.t("pages.new-flow.select-value"), "");
    sb.options.add(option_tmp1, undefined);
    if (regulation) {
      regulation.map((option, index) => {
        if (
          option.label.substring(0, 2) == "EU" &&
          (s == "BE" || s == "NL" || s == "SE" || s == "SG" || s == "IT")
        ) {
          const option_tmp = new Option(option.label, option.value);
          if (sb) {
            sb.options.add(option_tmp, undefined);
          }
        }
      });

      regulation.map((option, index) => {
        if (
          option.label.substring(0, 2) == s ||
          option.label.substring(0, 2) == "US" ||
          s == "AL"
        ) {
          const option_tmp = new Option(option.label, option.value);
          if (sb) {
            sb.options.add(option_tmp, undefined);
          }
        }
      });
    }
  };

  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Récupérer la liste des pays
        const response = await axios.get(
          `${configuration.LARAVEL_API}/countries_departure`
        );
        setCountries(response.data);

        // Convertir les codes en messages
        setState({
          ...state,
          msg: await api.GcodeToMsg("EXP063"),
          MLmsg: await api.GcodeToMsg("EXP064"),
          ITARmsg: await api.GcodeToMsg("EXP066"),
          EARmsg: await api.GcodeToMsg("EXP065"),
        });

        if (id) {
          const parsedId = parseInt(id, 10);
          if (!isNaN(parsedId)) {
            const licenseId = parseInt(id);
            if (licenseId) {
              getLicense(licenseId);
            }
          }
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    // Code à exécuter lorsque le composant est monté
    rootStore.countriesStore.getCountries();
    rootStore.countriesStore.getCountriesDeparture();
    fetchData();
  }, []);

  /** Check for regulation FR */

  const isRegulationApplicableFR = () => {
    const { regulation } = state;
    return (
      regulation === "FR_LIST_DOUBLE_USAGE" ||
      regulation === "EU_DUAL_USE_LIST" ||
      regulation === "FR_MUNITIONS_LIST" ||
      regulation === "EU_MUNITIONS_LIST"
    );
  };

  const removeEquipment = (index: number) => {
    const equipments = [state.equipments];
    equipments.splice(index, 1);
    setState({ ...state, equipments });
  };

  const isRegulationApplicableFR_DUAL = () => {
    const { regulation } = state;

    return (
      regulation === "FR_LIST_DOUBLE_USAGE" || regulation === "EU_DUAL_USE_LIST"
    );
  };

  const isRegulationApplicableFR_Munition = () => {
    const { regulation } = state;
    return (
      regulation === "FR_MUNITIONS_LIST" || regulation === "EU_MUNITIONS_LIST"
    );
  };

  const handleEquipment = (index: number, equipment: LicenseEquipment) => {
    const equipments = [...state.equipments];

    equipments[index] = equipment;
    setState({ ...state, equipments });
  };

  const addEquipment = () => {
    const equipments = [...state.equipments];

    equipments.push({});
    setState({ ...state, equipments });
  };

  const handleConsignee = (index: number, consignee: Consignee) => {
    const consignees = [...state.consignees];

    consignees[index] = consignee;
    setState({ ...state, consignees });
  };

  const addConsignee = () => {
    const consignees = [...state.consignees];

    consignees.push({
      consigneeCountryId: 0,
      consigneeName: "",
    });
    setState({ ...state, consignees });
  };

  const downloadFile = (file: File) => {
    setState({ ...state, loading: true });
    fileDownload(file, file.name);
    setState({ ...state, loading: false });
  };

  const handleSubmit = async () => {
    setState({ ...state, loading: true });

    if (
      state.regulation &&
      state.destinationCountryId &&
      state.licenseType &&
      state.endUser
    ) {
      const licenseId = await rootStore.licenseStore.postLicense(
        {
          id: state.id || undefined,
          regulation: state.regulation as LicenseRegulation,
          model: state.model,
          supplier: state.supplier,
          destinataire: state.destinataire,
          licenseType: state.licenseType as LicenseType,
          departureCountryId: state.departureCountryId!,
          destinationCountryId: state.destinationCountryId,
          companyName: state.endUser,
          currency: state.currency === "" ? undefined : state.currency,
          totalAmount: state.totalAmount,
          equipments: state.equipments,
          consignees: state.consignees,
          endUse: state.endUse as EndUseType,
          endUseComment: state.endUseComment,
          status: state.status as LicenseStatus,
          entite: entite ? entite : "ALL",
        },
        state.uploadLicense,
        state.uploadLicense1,
        state.uploadLicense2,
        state.uploadLicense3,
        state.orderContract,
        state.productInformation
      );
      let regulelabel = "";
      const departureCountry = await rootStore.countriesStore.getCountryById(
        state.destinationCountryId
      );
      if (regulation) {
        regulation.map((reg, index) => {
          if (reg.value === state.regulation) regulelabel = reg.label;
        });
      }
      //Addlog
      const logr = api.AddLog(
        rootStore.userStore.user?.tenantId || "",
        rootStore.userStore.user?.email || "",
        rootStore.userStore.user?.role || "",
        "Licence request",
        "",
        regulelabel,
        "" + state.departureCountryId,
        "" + departureCountry?.name,
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      );

      if (state.status === "DRAFT") {
        navigate("/license/search");
      } else {
        navigate(`/license/request/send/${state.regulation}/${licenseId}`);
      }
    }

    setState({ ...state, loading: false });
  };

  const getCurrencyForCountry = (countryCode: string) => {
    const currencyCode = countryCurrencyMap[countryCode];
    const currencyObj = currency.find((cur) => cur.value === currencyCode);

    return currencyObj ? currencyObj.value : "";
  };
  const show_FR = isRegulationApplicableFR();
  const showFR_DUAL = isRegulationApplicableFR_DUAL();
  const showFR_Munition = isRegulationApplicableFR_Munition();
  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <AppBar rootStore={rootStore} selectedPage={"LICENSE_MNGT"} />

      <HeaderLicense pages="REQUEST" rootStore={rootStore} />

      <div className={styles.body}>
        <Title tag="h2">{i18n.t("pages.license.menu.request")}</Title>

        <div className={styles.form}>
          <GridContainer>
            <GridRow>
              <GridCol md={4} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.label} required={true}>
                    {i18n.t("pages.exportability.departure-country")}
                  </FormLabel>
                  <select
                    id="departure-country"
                    required={false}
                    value={state.departureCountryId}
                    onChange={(e) => {
                      selection_pay(e.target.value);
                    }}
                  >
                    <option value="AL">
                      {i18n.t("pages.new-flow.select-value")}
                    </option>
                    {generateSelectOption(countries)}
                  </select>
                </FormGroup>
              </GridCol>

              {rootStore.tenantStore.entities.length >
                0 /*&& entite !== ""*/ && (
                // rootStore.userStore.user?.entite === "ALL" &&
                <GridCol md={2} xs={12} alignSelf="center">
                  <FormGroup>
                    <FormLabel className={styles.label} required={true}>
                      {i18n.t("pages.license.add.exporter")}
                    </FormLabel>
                    <select
                      onChange={(e) => setEntite(e.target.value)}
                      value={entite}
                    >
                      {/* Option "ALL" ajoutée ici */}
                      <option value="ALL">ALL</option>
                      {rootStore.tenantStore.entities.map(
                        (entite: any, index) => {
                          return (
                            <option key={index} value={entite.id_entite || ""}>
                              {entite.id_entite}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </FormGroup>
                </GridCol>
              )}
              <GridCol md={4} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.label} required={true}>
                    {i18n.t("pages.license.add.regulation")}
                  </FormLabel>
                  <select
                    id="regulation"
                    value={state.regulation || ""}
                    required={true}
                    onChange={(e) =>
                      setState({ ...state, regulation: e.target.value })
                    }
                  >
                    <option value="">
                      {i18n.t("pages.new-flow.select-value")}
                    </option>
                    {generateSelectOption(regulation)}
                  </select>
                </FormGroup>
              </GridCol>

              <GridCol md={4} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.label} required={true}>
                    {i18n.t("pages.license.add.license-type")}
                  </FormLabel>
                  <select
                    id="license-type"
                    value={state.licenseType || ""}
                    required={true}
                    onChange={(e) =>
                      setState({
                        ...state,
                        licenseType: e.target.value as LicenseType,
                      })
                    }
                  >
                    <option value="">
                      {i18n.t("pages.new-flow.select-value")}
                    </option>
                    {state.regulation === "US_DUAL_USE_LIST_EAR" &&
                      generateLicenseTypeOptions(["INDIVIDUAL"])}

                    {state.regulation === "US_MUNITIONS_LIST_ITAR" &&
                      generateLicenseTypeOptions([
                        "DSP5_PERMANENT",
                        "DSP73_TEMPORARY",
                        "WDA_WAREHOUSE_AGREEMENT",
                      ])}

                    {(state.regulation === "EU_DUAL_USE_LIST" ||
                      state.regulation === "FR_ARRETE_DU_31_JUILLET_2014_HEL" ||
                      state.regulation === "FR_LIST_DOUBLE_USAGE" ||
                      state.regulation === "FR_ANSSI") &&
                      generateLicenseTypeOptions([
                        "INDIVIDUAL",
                        "GLOBAL",
                        "EU001_AUTOR_GENERAL",
                        "PERMANENT",
                      ])}

                    {state.regulation === "FR_MUNITIONS_LIST" &&
                      generateLicenseTypeOptions([
                        "INDIVIDUAL",
                        "GLOBAL",
                        "TEMPORARY",
                        "LGT",
                      ])}

                    {state.regulation === "FR_IMPORTATION" &&
                      generateLicenseTypeOptions([
                        "AIPE",
                        "AIMG",
                        "AITMG",
                        "AGIMG",
                      ])}

                    {(state.regulation === "DE_DUAL_USE_GUTER" ||
                      state.regulation === "DE_MUNITIONS_LIST") &&
                      generateLicenseTypeOptions([
                        "INDIVIDUAL",
                        "GLOBAL",
                        "COLLECTIVE",
                      ])}

                    {(state.regulation === "UK_DUAL_USE_LIST" ||
                      state.regulation === "UK_MILITARY_LIST") &&
                      generateLicenseTypeOptions([
                        "GLOBAL_OGEL",
                        "INDIVIDUAL",
                        "TEMPORARY",
                      ])}

                    {(state.regulation === "ES_DUAL_USE_LIST" ||
                      state.regulation === "ES_MUNITION_LIST" ||
                      state.regulation === "EU_MUNITIONS_LIST") &&
                      state.departureCountryId != "NL" &&
                      generateLicenseTypeOptions([
                        "GLOBAL",
                        "GLOBAL",
                        "IMPORT",
                        "INDIVIDUAL",
                        "TEMPORARY",
                      ])}

                    {state.departureCountryId === "NL" &&
                      state.regulation === "EU_MUNITIONS_LIST" &&
                      generateLicenseTypeOptions([
                        "INDIVIDUAL",
                        "GLOBAL",
                        "NL005_AUTOR_GENERAL",
                        "NL006_AUTOR_GENERAL",
                      ])}
                  </select>
                </FormGroup>
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                {state.regulation === "FR_LIST_DOUBLE_USAGE" && (
                  <div>
                    <p>
                      <div
                        className="post__content"
                        dangerouslySetInnerHTML={{ __html: state.msg.Message }}
                      ></div>
                    </p>

                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={state.msg.Url1}
                      >
                        {state.msg.Lib_url1}
                      </a>
                      <a
                        target="_blank"
                        style={{ marginLeft: "50px" }}
                        rel="noopener noreferrer"
                        href={state.msg.Url2}
                      >
                        {state.msg.Lib_url2}
                      </a>
                      <a
                        target="_blank"
                        style={{ marginLeft: "50px" }}
                        rel="noopener noreferrer"
                        href={state.msg.Url3}
                      >
                        {state.msg.Lib_url3}
                      </a>
                      <br />
                    </p>
                  </div>
                )}
                {state.regulation === "FR_MUNITIONS_LIST" && (
                  <div>
                    <p>
                      <div
                        className="post__content"
                        dangerouslySetInnerHTML={{
                          __html: state.MLmsg.Message,
                        }}
                      ></div>
                    </p>

                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={state.MLmsg.Url1}
                      >
                        {state.MLmsg.Lib_url1}
                      </a>
                      <a
                        target="_blank"
                        style={{ marginLeft: "50px" }}
                        rel="noopener noreferrer"
                        href={state.MLmsg.Url2}
                      >
                        {state.MLmsg.Lib_url2}
                      </a>
                      <a
                        target="_blank"
                        style={{ marginLeft: "50px" }}
                        rel="noopener noreferrer"
                        href={state.MLmsg.Url3}
                      >
                        {state.MLmsg.Lib_url3}
                      </a>
                      <br />
                    </p>
                  </div>
                )}

                {state.regulation === "US_MUNITIONS_LIST_ITAR" && (
                  <div>
                    <p>
                      <div
                        className="post__content"
                        dangerouslySetInnerHTML={{
                          __html: state.ITARmsg.Message,
                        }}
                      ></div>
                    </p>

                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={state.ITARmsg.Url1}
                      >
                        {state.ITARmsg.Lib_url1}
                      </a>
                      <a
                        target="_blank"
                        style={{ marginLeft: "50px" }}
                        rel="noopener noreferrer"
                        href={state.ITARmsg.Url2}
                      >
                        {state.ITARmsg.Lib_url2}
                      </a>
                      <a
                        target="_blank"
                        style={{ marginLeft: "50px" }}
                        rel="noopener noreferrer"
                        href={state.ITARmsg.Url3}
                      >
                        {state.ITARmsg.Lib_url3}
                      </a>
                      <br />
                    </p>
                  </div>
                )}

                {state.regulation === "US_DUAL_USE_LIST_EAR" && (
                  <div>
                    <p>
                      <div
                        className="post__content"
                        dangerouslySetInnerHTML={{
                          __html: state.EARmsg.Message,
                        }}
                      ></div>
                    </p>

                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={state.EARmsg.Url1}
                      >
                        {state.EARmsg.Lib_url1}
                      </a>
                      <a
                        target="_blank"
                        style={{ marginLeft: "50px" }}
                        rel="noopener noreferrer"
                        href={state.EARmsg.Url2}
                      >
                        {state.EARmsg.Lib_url2}
                      </a>
                      <a
                        target="_blank"
                        style={{ marginLeft: "50px" }}
                        rel="noopener noreferrer"
                        href={state.EARmsg.Url3}
                      >
                        {state.EARmsg.Lib_url3}
                      </a>
                      <br />
                    </p>
                  </div>
                )}
              </GridCol>
            </GridRow>
            <GridRow>
              {state.licenseType != "EU001_AUTOR_GENERAL" && (
                <GridCol md={4} xs={12}>
                  <AutocompleteMUI
                    style={{ width: 300 }}
                    size="small"
                    id="destination-country"
                    options={
                      rootStore.countriesStore.countriesDepartureToOptions
                    }
                    getOptionLabel={(option) => option.label}
                    value={
                      state.destinationCountryId !== undefined
                        ? rootStore.countriesStore.countriesToOptions.find(
                            (option) =>
                              option.value === state.destinationCountryId
                          ) || null
                        : null
                    }
                    onChange={(event, value) => {
                      if (value) {
                        setState({
                          ...state,
                          destinationCountryId: value.value,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={i18n.t("pages.license.add.tdestination-country")}
                        required
                        error={Boolean("")}
                        helperText={""}
                      />
                    )}
                  />
                  {/* <AutoComplete
                    id="destination-country"
                    label={
                      state.regulation === "FR_MUNITIONS_LIST"
                        ? i18n.t("pages.license.add.destination-country-ml")
                        : i18n.t("pages.license.add.destination-country")
                    }
                    required={true}
                    errorText={i18n.t("errors.empty-field")}
                    onChange={(value) => {
                      setState({
                        ...state,
                        destinationCountryId: value,
                      });
                    }}
                    options={rootStore.countriesStore.countriesToOptions}
                    value={state.destinationCountryId || ""}
                  /> */}
                </GridCol>
              )}
              {state.licenseType != "EU001_AUTOR_GENERAL" && (
                <GridCol md={4} xs={12}>
                  <TextInput
                    id="end-user"
                    label={i18n.t("pages.license.add.end-user")}
                    value={state.endUser || ""}
                    required={true}
                    errorText={i18n.t("errors")}
                    onChange={(value) =>
                      setState({ ...state, endUser: value.target.value })
                    }
                  />
                </GridCol>
              )}

              {(state.regulation === "US_DUAL_USE_LIST_EAR" ||
                state.regulation === "US_MUNITIONS_LIST_ITAR" ||
                state.regulation === "FR_IMPORTATION") &&
                state.licenseType != "EU001_AUTOR_GENERAL" && (
                  <GridCol md={4} xs={12}>
                    <TextInput
                      label={i18n.t("pages.license.add.supplier")}
                      value={state.supplier || ""}
                      errorText={i18n.t("errors")}
                      onChange={(value) =>
                        setState({ ...state, supplier: value.target.value })
                      }
                      required={state.regulation !== "FR_IMPORTATION"}
                    />
                  </GridCol>
                )}
            </GridRow>

            {/*  fichier a upload   */}
            <GridRow>
              {!showFR_DUAL && (
                <GridCol md={3} xs={12}>
                  {state.regulation === "US_DUAL_USE_LIST_EAR" && (
                    <FormLabel className={styles.label} required={false}>
                      {i18n.t("pages.license.add.usupload-license2")}
                    </FormLabel>
                  )}

                  {state.regulation === "US_MUNITIONS_LIST_ITAR" && (
                    <FormLabel className={styles.label} required={false}>
                      {i18n.t("pages.license.add.itarupload-license1")}
                    </FormLabel>
                  )}
                  {!showFR_Munition &&
                    state.regulation !== "US_DUAL_USE_LIST_EAR" &&
                    state.regulation !== "US_MUNITIONS_LIST_ITAR" && (
                      <FormLabel className={styles.label} required={false}>
                        {i18n.t("pages.license.add.CNR")}
                      </FormLabel>
                    )}

                  {showFR_Munition && (
                    <FormLabel className={styles.label} required={false}>
                      {i18n.t("pages.license.add.CNR_FR")}
                    </FormLabel>
                  )}

                  <UploadInput
                    value={state.uploadLicense2}
                    disabled={state.disabledInputs}
                    onChange={(file) =>
                      setState({ ...state, uploadLicense2: file })
                    }
                  />
                  {state.uploadLicense2 && (
                    <Button
                      type="button"
                      className={styles.preview}
                      onClick={() => downloadFile(state.uploadLicense2 as File)}
                    >
                      {i18n.t("pages.license.add.preview-upload1")}
                    </Button>
                  )}
                </GridCol>
              )}

              {state.regulation !== "FR_MUNITIONS_LIST" &&
                state.regulation !== "EU_MUNITIONS_LIST" && (
                  <GridCol md={3} xs={12}>
                    {state.regulation === "US_DUAL_USE_LIST_EAR" && (
                      <FormLabel className={styles.label} required={false}>
                        {i18n.t("pages.license.add.usupload-license1")}
                      </FormLabel>
                    )}

                    {state.regulation === "US_MUNITIONS_LIST_ITAR" && (
                      <FormLabel className={styles.label} required={false}>
                        {i18n.t("pages.license.add.itarupload-license2")}
                      </FormLabel>
                    )}
                    {!showFR_DUAL &&
                      state.regulation !== "US_DUAL_USE_LIST_EAR" &&
                      state.regulation !== "US_MUNITIONS_LIST_ITAR" && (
                        <FormLabel className={styles.label} required={false}>
                          {i18n.t("pages.license.add.CUF")}
                        </FormLabel>
                      )}

                    {/** For Fr */}
                    {showFR_DUAL && (
                      <FormLabel className={styles.label} required={false}>
                        {i18n.t("pages.license.add.endUserCertificate")}
                      </FormLabel>
                    )}

                    <UploadInput
                      value={state.uploadLicense1}
                      disabled={state.disabledInputs}
                      onChange={(file) =>
                        setState({ ...state, uploadLicense1: file })
                      }
                    />
                    {state.uploadLicense1 && (
                      <Button
                        type="button"
                        className={styles.preview}
                        onClick={() =>
                          downloadFile(state.uploadLicense1 as File)
                        }
                      >
                        {i18n.t("pages.license.add.preview-upload1")}
                      </Button>
                    )}
                  </GridCol>
                )}

              {/**Fichier  Commande ou contrat */}
              {show_FR && (
                <GridCol md={3} xs={12}>
                  <FormLabel className={styles.label} required={false}>
                    {i18n.t("pages.license.add.orderContract")}
                  </FormLabel>
                  <UploadInput
                    value={state.orderContract}
                    disabled={state.disabledInputs}
                    onChange={(file) =>
                      setState({ ...state, orderContract: file })
                    }
                  />
                  {state.orderContract && (
                    <Button
                      type="button"
                      className={styles.preview}
                      onClick={() => downloadFile(state.orderContract as File)}
                    >
                      {i18n.t("pages.license.add.preview-upload1")}
                    </Button>
                  )}
                </GridCol>
              )}

              {/** Fiche Produit */}
              {show_FR && (
                <GridCol md={3} xs={12}>
                  {showFR_DUAL && (
                    <FormLabel className={styles.label} required={false}>
                      {i18n.t("pages.license.add.productInformation")}
                    </FormLabel>
                  )}
                  {showFR_Munition && (
                    <FormLabel className={styles.label} required={false}>
                      {i18n.t("pages.license.add.analyticInformation")}
                    </FormLabel>
                  )}
                  <UploadInput
                    value={state.productInformation}
                    disabled={state.disabledInputs}
                    onChange={(file) =>
                      setState({ ...state, productInformation: file })
                    }
                  />
                  {state.productInformation && (
                    <Button
                      type="button"
                      className={styles.preview}
                      onClick={() =>
                        downloadFile(state.productInformation as File)
                      }
                    >
                      {i18n.t("pages.license.add.preview-upload1")}
                    </Button>
                  )}
                </GridCol>
              )}

              <GridCol md={3} xs={12}>
                {state.regulation === "US_DUAL_USE_LIST_EAR" && (
                  <FormLabel className={styles.label} required={false}>
                    {i18n.t("pages.license.add.usupload-license3")}
                  </FormLabel>
                )}

                {state.regulation === "US_MUNITIONS_LIST_ITAR" && (
                  <FormLabel className={styles.label} required={false}>
                    {i18n.t("pages.license.add.itarupload-license3")}
                  </FormLabel>
                )}
                {!show_FR &&
                  state.regulation !== "US_DUAL_USE_LIST_EAR" &&
                  state.regulation !== "US_MUNITIONS_LIST_ITAR" && (
                    <FormLabel className={styles.label} required={false}>
                      {i18n.t("pages.license.add.autre")}
                    </FormLabel>
                  )}

                {show_FR && (
                  <FormLabel className={styles.label} required={false}>
                    {i18n.t("pages.license.add.otherDocument")}
                  </FormLabel>
                )}

                <UploadInput
                  value={state.uploadLicense3}
                  disabled={state.disabledInputs}
                  onChange={(file) =>
                    setState({ ...state, uploadLicense3: file })
                  }
                />
                {state.uploadLicense3 && (
                  <Button
                    type="button"
                    className={styles.preview}
                    onClick={() => downloadFile(state.uploadLicense3 as File)}
                  >
                    {i18n.t("pages.license.add.preview-upload1")}
                  </Button>
                )}
              </GridCol>
            </GridRow>

            <GridRow>
              <GridCol md={12}>
                <TextArea
                  id="model"
                  label={i18n.t("pages.license.add.model")}
                  required={false}
                  //  errorText={ i18n.t('errors') }
                  onChange={(value) =>
                    setState({ ...state, model: value.target.value })
                  }
                  // options={ rootStore.modelStore.modelToOptions }
                  value={state.model || ""}
                  //returnLabel={ true }
                />
              </GridCol>
            </GridRow>

            <GridRow>
              <GridCol md={4} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.label}>
                    {i18n.t("pages.license.add.currency")}
                  </FormLabel>
                  <select
                    id="currency"
                    value={state.currency || ""}
                    onChange={(e) =>
                      setState({ ...state, currency: e.target.value })
                    }
                  >
                    <option value="">
                      {i18n.t("pages.new-flow.select-value")}
                    </option>
                    {generateSelectOption(currency)}
                  </select>
                </FormGroup>
              </GridCol>

              <GridCol md={4} xs={12}>
                <TextInput
                  id="total-amount"
                  label={i18n.t("pages.license.add.total-amount")}
                  value={state.totalAmount || ""}
                  type="number"
                  min={0}
                  step={1}
                  errorText={i18n.t("errors")}
                  onChange={(value) =>
                    setState({
                      ...state,
                      totalAmount: parseInt(value.target.value),
                    })
                  }
                />
              </GridCol>
            </GridRow>

            {state.equipments &&
              state.equipments.map((_, index) => {
                return (
                  <EquipmentComponent
                    removeEquipment={removeEquipment}
                    key={index}
                    index={index}
                    historyValues={state.equipments[index]}
                    onChange={(value) => handleEquipment(index, value)}
                    rootStore={rootStore}
                  />
                );
              })}

            <Button
              id="add-equipment"
              className={styles.addButton}
              type="button"
              size="medium"
              onClick={() => addEquipment()}
            >
              {i18n.t("pages.license.add.add-equipment")}
            </Button>

            {(state.departureCountryId === "US" ||
              state.regulation?.includes("MUNITIONS_LIST")) &&
              state.consignees &&
              state.consignees.map((consignee, index) => {
                return (
                  <ConsigneeComponent
                    key={index}
                    index={index}
                    historyValues={state.consignees[index]}
                    countries={rootStore.countriesStore.countriesToOptions}
                    consignee={consignee}
                    onChange={(value) => handleConsignee(index, value)}
                  />
                );
              })}
            {(state.departureCountryId === "US" ||
              state.regulation?.includes("MUNITIONS_LIST")) && (
              <Button
                id="add-consignee"
                className={styles.addButton}
                type="button"
                size="medium"
                onClick={() => addConsignee()}
              >
                {i18n.t("pages.license.add.add-consignee")}
              </Button>
            )}

            <GridRow>
              <GridCol md={12}>
                <FormLabel required={true}>
                  {i18n.t("pages.license.add.end-use.label")}
                </FormLabel>
                <RadioGroup
                  aria-label="endUse"
                  name="endUse"
                  value={state.endUse || ""}
                  required={true}
                  onChange={(e) =>
                    setState({ ...state, endUse: e.target.value as EndUseType })
                  }
                >
                  <FormControlLabel
                    id="civil-button"
                    value="CIVIL"
                    control={<Radio onChange={() => {}} color="primary" />}
                    label={i18n.t("pages.license.add.end-use.civil")}
                  />
                  <FormControlLabel
                    id="military-button"
                    value="MILITARY"
                    control={<Radio onChange={() => {}} color="primary" />}
                    label={i18n.t("pages.license.add.end-use.military")}
                  />
                </RadioGroup>
              </GridCol>
            </GridRow>

            <GridRow>
              <GridCol md={12}>
                <TextArea
                  id="end-use-comment"
                  label={i18n.t("pages.license.add.end-use-comment")}
                  placeholder={i18n.t("pages.license.add.end-use-comment")}
                  maxLength={4000}
                  required={true}
                  value={state.endUseComment || ""}
                  onChange={(value) =>
                    setState({ ...state, endUseComment: value.target.value })
                  }
                />
              </GridCol>
            </GridRow>
          </GridContainer>
        </div>
      </div>

      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => goBack(),
            label: i18n.t("global.previous"),
          },
          {
            id: "draft",
            onClick: () => {
              setState({ ...state, status: "DRAFT" });
            },
            label: i18n.t("global.draft"),
          },
          {
            id: "submit",
            label: i18n.t("global.submit_request"),
            primary: true,
          },
        ]}
      />
    </form>
  );
};

export default inject("rootStore")(observer(RequestLicense));
