import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

const sizeStyles = {
  small: { width: "300px" },
  medium: { width: "500px" },
  large: { width: "700px" },
};

type DialogProps = {
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  dialogText?: string;
  size?: "small" | "medium" | "large";
  confirmButtonColor?: "primary" | "secondary" | "inherit";
  cancelButtonColor?: "primary" | "secondary" | "inherit";
  confirmButtonVariant?: "text" | "outlined" | "contained";
  cancelButtonVariant?: "text" | "outlined" | "contained";
};

const ConfirmDialog: React.FC<DialogProps> = ({
  open,
  onClose,
  onConfirm,
  onCancel,
  confirmLabel = "Confirm",
  cancelLabel = "Cancel",
  dialogText = "Are you sure you want to proceed?",
  size = "medium",
  confirmButtonColor = "primary",
  cancelButtonColor = "primary",
  confirmButtonVariant = "contained",
  cancelButtonVariant = "text",
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
      PaperProps={{
        style: sizeStyles[size] || sizeStyles.medium,
      }}
    >
      <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          color={cancelButtonColor}
          variant={cancelButtonVariant}
        >
          {cancelLabel}
        </Button>
        <Button
          onClick={onConfirm}
          color={confirmButtonColor}
          variant={confirmButtonVariant}
          autoFocus
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
