/* Styles import */
import styles from "./us.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  GridCol,
  GridRow,
  TextInput,
  Title,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
/* Custom import */
import AppBar from "../../../components/appBar";
import Footer from "../../../components/footer";
import HeaderScreeningComponent from "../../../components/headerScreening";
import TimeLine from "../../../components/timeLine";
import { flowUrl } from "../../../utils/generator";
import AutoComplete from "../../../components/autoComplete";
import Loader from "../../../components/loader";

interface Props extends InjectedProps {}
interface InjectedProps {
  rootStore: import("../../../stores/rootStore").default;
}
interface State {
  company?: string;
  country?: number;
  flowId?: string;
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const OtherScreening: React.FC<Props> = ({ rootStore }) => {
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState(
    rootStore.exportabilityStore.countryOfDestination,
  );
  const [radioChoice, setRadioChoice] = useState(
    rootStore.screeningStore.liste,
  );
  //const [flowId, setFlowId] = useState('');
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const { id } = useParams();
  const flowId = id;

  useEffect(() => {
    rootStore.countriesStore.getCountries();
    const fetchData = async () => {
      if (flowId) {
        try {
          const flow = await rootStore.flowStore.getById(parseInt(flowId));

          if (flow) {
            setCompany(flow.customer);
            setCountry(flow.destinationCountryId);
            //setFlowId(flowId);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération du flux:", error);
        }
      } else if (rootStore.screeningStore.company !== "") {
        setCompany(getCompany());
      }
    };

    fetchData(); // Appeler la fonction fetchData une fois, équivalent à componentDidMount

    return () => {};
  }, []);

  const getCompany = () => {
    const company = rootStore.screeningStore.company;

    rootStore.screeningStore.company = "";
    return company ? company : "";
  };

  const getCountry = () => {
    const countryId = rootStore.screeningStore.country;

    rootStore.screeningStore.country = -1;

    return countryId ? countryId : 0;
  };
  const handleChangeSearchType = (event: any) => {
    if (event.target.value !== null) {
      setRadioChoice(event.target.value);
      rootStore.screeningStore.setListe(event.target.value);
    }
  };

  const submitIsDisabled = () => {
    return !company;
  };

  const handleSubmit = () => {
    if (company) {
      rootStore.screeningStore.setCompany(company);
      // rootStore.screeningStore.setCountry(country)
      if (rootStore.screeningStore.liste == "FRANCE") {
        rootStore.screeningStore.getBasicEntitiesfr(company);
        navigate(flowUrl("/screening/basic/fr/choose-basic-entity", id));
      }

      if (rootStore.screeningStore.liste == "CANADA") {
        rootStore.screeningStore.getBasicEntitiescanada(company);
        navigate(flowUrl("/screening/basic/canada/choose-basic-entity", id));
      }

      if (rootStore.screeningStore.liste == "UK") {
        rootStore.screeningStore.getBasicEntitiesuk(company);
        navigate(flowUrl("/screening/basic/uk/choose-basic-entity", id));
      }
    }
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"SCREENING"} />

      <HeaderScreeningComponent
        type="BASIC"
        pages="OTHER"
        rootStore={rootStore}
      />

      {rootStore.screeningStore.entitiesState === "PENDING" && <Loader />}

      <div className={styles.image}></div>

      <div className={styles.body}>
        <Title tag="h2" className={styles.pStyles}>
          {i18n.t("pages.screening.entity.title_fr")}
        </Title>

        <Title tag="h5" className={styles.pStyles}>
          {i18n.t("pages.screening.entity.title_fr1")}
        </Title>

        <form className={styles.form}>
          <div className={styles.row}>
            <div className={styles.input}>
              <TextInput
                id="company"
                type="text"
                label={i18n.t("pages.screening.entity.company")}
                value={company || ""}
                required={true}
                errorText={i18n.t("errors.company-not-defined")}
                disabled={flowId !== undefined}
                onChange={(e) => setCompany(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
              />
            </div>

            <RadioGroup
              aria-label="search-type"
              name="search-type"
              className={styles.radioButtons}
              value={radioChoice}
              //value={rootStore.screeningStore.liste || ""}
              onChange={(e) => handleChangeSearchType(e)}
            >
              <FormControlLabel
                id="keyword-button"
                value="FRANCE"
                control={<Radio color="primary" />}
                label="France – Registre national des gels"
              />

              <FormControlLabel
                id="sap-button"
                value="CANADA"
                control={<Radio color="primary" />}
                label="Canada - Consolidated Canadian Autonomous Sanctions List"
              />

              <FormControlLabel
                id="sap-button"
                value="UK"
                control={<Radio color="primary" />}
                label="UK - UK Sanctions List"
              />
            </RadioGroup>
          </div>
        </form>
      </div>

      <Footer
        buttons={[
          {
            id: "previous",
            primary: false,
            onClick: () => goBack(),
            label: i18n.t("global.previous"),
          },
          {
            id: "submit",
            primary: true,
            onClick: () => handleSubmit(),
            label: i18n.t("components.searchEccn.search-button"),

            disabled: submitIsDisabled(),
          },
        ]}
      >
        {flowId && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(OtherScreening));
