/* Global import */
import { observable, action, computed, makeObservable } from "mobx";
import i18n from "i18next";

/* Custom import */
import Api from "../utils/api";

export default class countriesStore {
  api: Api;
  rootStore: import("./rootStore").default;

  @observable loadingCountries: boolean = false;
  @observable countries: Country[] = [];
  @observable countriesDeparture: Country[] = [];
  @observable countryTable: CountryTable[] = [];
  @observable SanctionEU: Sanction[] = [];
  @observable SanctionUS: Sanction[] = [];
  @observable SanctionUN: Sanction[] = [];
  @observable AutoEU: Sanction[] = [];
  @observable loadingControlCategories: boolean = false;
  @observable countryControlCategories: CountryControlCategory[] = [];
  @observable
  countryControlCategoriesSanctions: CountryControlSanctionCategory = {
    present: false,
    empty: false,
  };

  constructor(rootStore: import("./rootStore").default) {
    makeObservable(this);
    this.api = new Api();
    this.rootStore = rootStore;
    if (this.countryTable.length <= 0) {
      this.setCountryTable;
    }
  }

  @action async setCountryTable() {
    try {
      this.countryTable = await this.api.getCountryTable();
      sessionStorage.setItem("countryTable", JSON.stringify(this.countryTable));
    } catch (e) {}
  }

  @action getCountryById(id: number): Country | undefined {
    return this.countries.find((country) => country.id === id);
  }

  @computed get countriesToOptions() {
    return this.countries.map((country) => {
      return {
        label: country.name,
        value: country.id,
      };
    });
  }
  @computed get countriesToOptionsName() {
    return this.countries.map((country) => {
      return {
        label: country.name,
        id: country.name,
      };
    });
  }
  @computed get countriesDepartureToOptions() {
    return this.countriesDeparture.map((country) => {
      return {
        label: country.name,
        value: country.id,
      };
    });
  }

  @action async getCountriesDeparture() {
    this.loadingCountries = true;

    try {
      this.countriesDeparture = await this.api.getDepartureCountry();
    } catch (e) {}

    this.loadingCountries = false;
  }

  @action async getCountries() {
    this.loadingCountries = true;

    try {
      this.countries = await this.api.getCountries();
    } catch (e) {}

    this.loadingCountries = false;
  }

  @action async getSanctionEU() {
    this.loadingCountries = true;

    try {
      this.SanctionEU = await this.api.getSanctionEU();
    } catch (e) {}

    this.loadingCountries = false;
  }

  @action async getAutoEU() {
    this.loadingCountries = true;

    try {
      this.AutoEU = await this.api.getAutoEU();
    } catch (e) {}

    this.loadingCountries = false;
  }

  @action async getSanctionUS() {
    this.loadingCountries = true;

    try {
      this.SanctionUS = await this.api.getSanctionUS();
    } catch (e) {}

    this.loadingCountries = false;
  }

  @action async getSanctionUN() {
    this.loadingCountries = true;

    try {
      this.SanctionUN = await this.api.getSanctionUN();
    } catch (e) {}

    this.loadingCountries = false;
  }

  @action async getControlCategoriesByCountry(id: number) {
    this.loadingControlCategories = true;
    this.countryControlCategories = [];

    try {
      this.countryControlCategories =
        await this.api.getControlCategoriesByCountry(id);

      this.countryControlCategories = this.countryControlCategories.sort(
        function (a, b) {
          return a.controlCategory.affichage - b.controlCategory.affichage;
        }
      );

      //console.log(this.countryControlCategories) ;
      this.countryControlCategoriesSanctions =
        await this.api.getControlCategoriesSanctionsByCountry(id);
    } catch (e) {}

    this.loadingControlCategories = false;
  }

  @action setControlCategory(controlCategoryId: number, value: boolean) {
    this.countryControlCategories = this.countryControlCategories.map(
      (countryControlCategory) => {
        if (countryControlCategory.controlCategory.id === controlCategoryId) {
          countryControlCategory.active = value;
        }

        return countryControlCategory;
      }
    );
  }

  @action async setCountryControlCategory(countryId: number) {
    try {
      const controlCategoryIds = this.getControlCategoryIds(
        this.countryControlCategories
      );

      await this.api.setCountryControlCategory(countryId, controlCategoryIds);
      this.rootStore.notificationStore.setNotification(
        i18n.t("global.success"),
        i18n.t("pages.admin.countries.saveSuccess"),
        "success"
      );
    } catch (e) {}
  }

  @action async setCountryControlCategorySanction(
    countryId: number,
    active: boolean
  ) {
    try {
      await this.api.setCountryControlCategorySanction(countryId, active);
    } catch (e) {}
  }

  getControlCategoryIds(
    countryControlCategories: CountryControlCategory[]
  ): number[] {
    return countryControlCategories
      .filter(
        (countryControlCategory) => countryControlCategory.active === true
      )
      .map(
        (countryControlCategory) => countryControlCategory.controlCategory.id
      );
  }

  async findCountryByCode(countryCode: EccnCountry) {
    try {
      return await this.api.getCountryByCode(countryCode);
    } catch (e) {}
  }
}
