import React, { SFC, ChangeEvent, ReactNode } from "react";
import cx from "classnames";
import classes from "../Stepper/styles.module.scss";

export interface StepContentProps {
  className?: string;
}

export const StepContent: SFC<StepContentProps> = ({ className, children }) => (
  <div className={cx(classes["ds-steppers__content"], className)}>
    {children}
  </div>
);

export default StepContent;
