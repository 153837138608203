import * as React from "react";
import ReactDOM from "react-dom";
import Transition, {
  TransitionStatus,
  TransitionProps,
} from "react-transition-group/Transition";
//import EventListener from "react-event-listener";
import debounce from "debounce"; // < 1kb payload overhead when lodash/debounce is > 3kb.

interface IProps {
  /** Direction of the transition */
  direction?:
    | "bottom"
    | "top"
    | "top-left"
    | "bottom-left"
    | "top-right"
    | "bottom-right"
    | "right"
    | "left";
  /** Duration of the transition (in milliseconds)*/
  duration?: number;
}

interface ISlideState {
  mounted: boolean;
  showContent: boolean;
}

type ISlideProps = IProps & TransitionProps;

const _DURATION = 300;
const _ZORDER = 1400;

interface scrollBarSize {
  height: number;
  width: number;
}

export default class Slide extends React.Component<ISlideProps, ISlideState> {
  transitionRef: any;
  readonly defaultStyle = {};
  mounted: boolean = false;

  static defaultProps = {
    direction: "top",
    duration: _DURATION,
  };

  constructor(props: ISlideProps) {
    super(props);
    this.state = { mounted: false, showContent: this.props.in || false };
    this.defaultStyle = {
      transition: `transform  ${this.props.duration}ms ease-out`,
      zIndex: _ZORDER,
      width: "inherit",
      maxWidth: "inherit",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.mounted = true;
    if (!this.props.in) this.updatePosition();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate() {
    if (
      !this.state.showContent ||
      this.props.direction === "top" ||
      this.props.direction === "top-left"
    ) {
      return;
    }

    if (this.transitionRef.current) {
      this.setTranslateValue(this.transitionRef.current);
    }
  }
  render() {
    return (
      //<EventListener target="window" onResize={this.handleResize}>
      <Transition
        timeout={this.props.duration}
        onEnter={this.handleEnter}
        onEntered={this.handleEntered}
        onExit={this.handleExit}
        onExiting={this.handleExiting}
        onExited={this.handleExited}
        ref={(ref) => {
          this.transitionRef = ReactDOM.findDOMNode(ref as React.ReactInstance);
        }}
        appear={true}
        {...this.props}
      >
        {(state: TransitionStatus) => {
          const style = this.props.style; // Remplacez ceci par le style que vous souhaitez appliquer
          const children = this.props.children; // Remplacez ceci par le contenu que vous souhaitez afficher
          return <div style={style}>{children}</div>;
        }}
      </Transition>
      //</EventListener>
    );
  }

  handleResize = debounce(() => {
    if (
      !this.state.showContent ||
      this.props.direction === "top" ||
      this.props.direction === "top-left"
    ) {
      return;
    }

    if (this.transitionRef.current) {
      this.setTranslateValue(this.transitionRef.current);
    }
  }, 166);

  handleEnter = (node: HTMLElement) => {
    this.setTranslateValue(node);
    this.setState({ showContent: true });
  };

  handleEntered = (node: HTMLElement) => {
    this.setTranslateValue(node);
    this.setState({ showContent: true });
  };

  handleExit = (node: HTMLElement) => {
    node.style.webkitTransform = "translate(0, 0)";
    node.style.transform = "translate(0, 0)";
    this.setState({ showContent: true });
  };

  handleExiting = (node: HTMLElement) => {};

  handleExited = (node: HTMLElement) => {};

  getTranslateValue(node: HTMLElement) {
    const { direction } = this.props;
    const rect = node.getBoundingClientRect();
    node.style.position = "fixed";
    const scrollBarSize = this.getScrollBarSize();

    if (
      rect.height === 0 &&
      rect.width === 0 &&
      rect.top === 0 &&
      rect.left === 0
    )
      return "";

    let height = rect.height;
    if (node.firstElementChild) {
      let elem: HTMLElement = node.firstElementChild as HTMLElement;
      height = elem.offsetHeight;
    }

    let width = rect.width;
    if (node.firstElementChild) {
      let elem: HTMLElement = node.firstElementChild as HTMLElement;
      width = elem.offsetWidth;
    }

    let style = {
      height: `${height}px`,
      width: `${width}px`,
    };

    switch (direction) {
      case "bottom":
        return {
          ...style,
          left: `0px`,
          width: `${window.innerWidth - scrollBarSize.width}px`,
          top: `${window.innerHeight - scrollBarSize.height}px`,
          transform: `translateY(-${height}px)`,
        };

      case "top":
      default:
        return {
          ...style,
          left: `0px`,
          width: `${window.innerWidth - scrollBarSize.width}px`,
          top: `-${height}px`,
          transform: `translateY(${height}px)`,
        };

      case "right":
        return {
          ...style,
          top: `0px`,
          left: `${window.innerWidth - scrollBarSize.width}px`,
          transform: `translateX(-${width}px)`,
        };

      case "left":
        return {
          ...style,
          top: `0px`,
          left: `-${width}px`,
          transform: `translateX(${width}px)`,
        };

      case "top-left":
        return {
          ...style,
          top: `0px`,
          left: `-${width}px`,
          transform: `translateX(${width}px)`,
        };

      case "bottom-left":
        return {
          ...style,
          top: `${window.innerHeight - height}px`,
          left: `-${width}px`,
          transform: `translateX(${width}px)`,
        };

      case "top-right":
        return {
          ...style,
          top: `0px`,
          left: `${window.innerWidth - scrollBarSize.width}px`,
          transform: `translateX(-${width}px)`,
        };

      case "bottom-right":
        return {
          ...style,
          top: `${window.innerHeight - height}px`,
          left: `${window.innerWidth - scrollBarSize.width}px`,
          transform: `translateX(-${width}px)`,
        };
    }
  }

  setTranslateValue(node: HTMLElement): void {
    node.style.visibility = "inherit";
    node.style.position = "relative";
    const position = this.getTranslateValue(node);

    if (position) {
      if (this.props.in) {
        node.style.top = position.top;
        node.style.left = position.left;
        node.style.width = position.width;
        node.style.height = position.height;
        node.style.webkitTransform = position.transform;
        node.style.transform = position.transform;
      }
    }
  }

  updatePosition(): void {
    if (this.transitionRef) {
      this.setTranslateValue(this.transitionRef);
    }
  }

  getScrollBarSize(): scrollBarSize {
    let size: scrollBarSize = { height: 0, width: 0 };
    if (
      window &&
      window.scrollbars.visible &&
      window.document.documentElement
    ) {
      size.height =
        window.innerHeight - window.document.documentElement.clientHeight;
      size.width =
        window.innerWidth - window.document.documentElement.clientWidth;
    }
    return size;
  }
}
