import React, { SFC } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";

type Props = {
  className?: string;
  /** The color of the component */
  color?: "primary" | "error" | "success" | "warning" | "info";
  /** The count to show */
  count?: number;
  /** Max count to show (with +) */
  max?: number;
};

const Badge: SFC<Props> = ({
  className,
  color,
  count = 0,
  max = 99,
  children,
}) => {
  if (count === 0) {
    return null;
  }
  return (
    <span className={classes["ds-badge-wrapper"]}>
      {children}
      <span
        className={cx(
          classes["ds-badge"],
          {
            [classes["ds-badge--primary"]]: color === "primary",
            [classes["ds-badge--error"]]: color === "error",
            [classes["ds-badge--success"]]: color === "success",
            [classes["ds-badge--warning"]]: color === "warning",
            [classes["ds-badge--info"]]: color === "info",
          },
          className,
        )}
      >
        {count > max ? `${max}+` : count}
      </span>
    </span>
  );
};

Badge.defaultProps = {
  max: 99,
  count: 0,
};

export default Badge;
