import React, { Component, RefObject, InputHTMLAttributes } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";
import uniqueId from "lodash.uniqueid";
import Check from "@material-ui/icons/Check";
import Indeterminate from "@material-ui/icons/Remove";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  /** The label to show next to the <input /> */
  label?: string;
  /** If `true` the checkbox will be larger */
  large?: boolean;
  /** Indicates that checkbox is in indeterminate state */
  indeterminate?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  capture?: boolean | "user" | "environment"; // Mettez capture en conformité avec le HTML5
}

interface ICheckBoxState {}

type ICheckBoxProps = IProps & InputHTMLAttributes<HTMLInputElement>;

export default class Checkbox extends Component<
  ICheckBoxProps,
  ICheckBoxState
> {
  private id: string = uniqueId("checkbox-");
  private inputRef: RefObject<HTMLInputElement> = React.createRef();

  componentDidMount() {
    if (this.props.indeterminate && this.inputRef.current) {
      this.inputRef.current.indeterminate = true;
    }
  }

  render() {
    const { label, large, indeterminate, ...rest } = this.props;
    const id = this.props.id || this.id;

    return (
      <div
        className={cx(classes["ds-checkbox"], {
          [classes["ds-checkbox--large"]]: large,
        })}
      >
        <input ref={this.inputRef} id={id} type="checkbox" {...rest} />
        <div>
          {this.renderIcon()}
          <label htmlFor={id}>
            {label}
            &nbsp;
          </label>
        </div>
      </div>
    );
  }

  private renderIcon(): JSX.Element {
    if (this.props.indeterminate) {
      return <Indeterminate className={cx(classes["ds-checkbox-icon"])} />;
    } else {
      return <Check className={cx(classes["ds-checkbox-icon"])} />;
    }
  }
}
