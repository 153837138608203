/* Styles import */
import "./animation.css";
import styles from "./appBar.module.css";

/* Global import */
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import React, { Component, useEffect, useState } from "react";
import { Link, Route, useNavigate } from "react-router-dom";
import Login from "../../pages/login";
import { CSSTransition } from "react-transition-group";
import {
  Menu,
  MenuItem,
  Notification,
  Title,
} from "../../components/horizon-components-react/src/components";

/* Custom import */
import i18next from "i18next";
import SearchEccn from "../../components/searchEccn";
import { decode } from "../../utils/jwt";

interface Props {
  selectedPage?: Page;
  rootStore: import("../../stores/rootStore").default;
}
interface InjectedProps extends Props {
  rootStore: import("../../stores/rootStore").default;
}
interface State {
  showMenu: boolean;
  showMinMenu: boolean;
  showLgMenu: boolean;
  showSearchEccn: boolean;
}
type Page =
  | "HOME"
  | "FLOW"
  | "CLASSIFICATION"
  | "EXPORTABILITY"
  | "SCREENING"
  | "LICENSE_MNGT"
  | "NONE"
  | "ADMIN"
  | "ANALYTICS"
  | "REGULATION";

const AppBar: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  // Initialisation de l'état avec les setters
  const [showMenu, setShowMenu] = useState(false);
  const [showMinMenu, setShowMinMenu] = useState(false);
  const [showLgMenu, setShowLgMenu] = useState(false);
  const [showSearchEccn, setShowSearchEccn] = useState(false);
  const jwt: JwtData =
    decode(window.localStorage.getItem("access_token") || "") || [];
  const jwtRoles: string[] = jwt.realm_access?.roles;

  /* useEffect(() => {
    if (!props.rootStore.authStore.isLogged) {
      navigate("/login");
    }
  }, [props.rootStore.authStore.isLogged]);*/

  const logout = () => {
    props.rootStore.authStore.logout();
  };

  const getFullname = () => {
    return props.rootStore.userStore.user
      ? `${props.rootStore.userStore.user.firstname} ${props.rootStore.userStore.user.lastname}`
      : "";
  };

  const openSearchEccn = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setShowSearchEccn(true);
  };

  const getScreening = () => {
    const screeningType = props.rootStore.userStore.getScreeningType();

    if (jwtRoles?.includes("Screening")) {
      if (screeningType === "USE_BUILT_IN_SCREENING") {
        return (
          <li
            className={
              props.selectedPage === "SCREENING" ? styles.selected : ""
            }
          >
            <Link to="/screening/basic/ALL">{i18n.t("global.screening")}</Link>
          </li>
        );
      }
      return (
        <li
          className={props.selectedPage === "SCREENING" ? styles.selected : ""}
        >
          <Link to="/screening/entity">{i18n.t("global.screening")}</Link>
        </li>
      );
    }
  };

  const getFlow = () => {
    if (jwtRoles?.includes("Flux")) {
      return (
        <li className={props.selectedPage === "FLOW" ? styles.selected : ""}>
          <Link to="/search-flow">{i18n.t("global.flows")}</Link>
        </li>
      );
    }
  };

  const getClassement = () => {
    if (jwtRoles?.includes("Classement")) {
      return (
        <li>
          <Link
            id="classification-button"
            to="#"
            onClick={(e) => openSearchEccn(e)}
          >
            {i18n.t("global.classification")}
          </Link>
        </li>
      );
    }
  };

  const getExportabilite = () => {
    if (jwtRoles?.includes("Exportabilite")) {
      return (
        <li
          className={
            props.selectedPage === "EXPORTABILITY" ? styles.selected : ""
          }
        >
          <Link to="/exportability">{i18n.t("global.exportability")}</Link>
        </li>
      );
    }
  };

  const getLicences = () => {
    if (jwtRoles?.includes("Licences")) {
      return (
        <li
          className={
            props.selectedPage === "LICENSE_MNGT" ? styles.selected : ""
          }
        >
          <Link to="/license/search">{i18n.t("global.license-mngt")}</Link>
        </li>
      );
    }
  };

  const getAnalytics = () => {
    if (jwtRoles?.includes("Analytics")) {
      return (
        <li
          className={props.selectedPage === "ANALYTICS" ? styles.selected : ""}
        >
          <Link to="/admin/report">{i18n.t("global.analytics")}</Link>
        </li>
      );
    }
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.appName}>
            <Title dark tag="h4">
              {i18n.t("app.title")}
            </Title>
          </div>
        </div>

        <div className={`${styles.row} ${styles.menuContainer}`}>
          <ul className={styles.menu}>
            <li
              className={props.selectedPage === "HOME" ? styles.selected : ""}
            >
              <Link to="/home">{i18n.t("global.home")}</Link>
            </li>

            {getFlow()}
            {getClassement()}
            {getExportabilite()}
            {getScreening()}
            {getLicences()}
            {getAnalytics()}

            <li
              className={
                props.selectedPage === "REGULATION" ? styles.selected : ""
              }
            >
              <Link to="/regulation/control-lists">
                {i18n.t("pages.regulation.title")}
              </Link>
            </li>
          </ul>

          <div className={styles.dropdownMenu}>
            <span
              className={styles.username}
              onClick={() => {
                setShowMenu(true);
                setShowLgMenu(false);
              }}
            >
              {getFullname().trim()}
            </span>

            <Menu show={showMenu}>
              {jwtRoles?.includes("Admin") && (
                <MenuItem
                  className="admin"
                  onClick={() => {
                    navigate("/admin/countries");
                  }}
                >
                  {i18n.t("global.admin")}
                </MenuItem>
              )}

              <MenuItem className="logout" onClick={() => logout()}>
                {i18n.t("global.logout")}
              </MenuItem>
            </Menu>
          </div>

          <div className={styles.dropdownMenulg}>
            <span
              className={styles.username}
              onClick={() => {
                setShowLgMenu(true);
                setShowMenu(false);
              }}
            >
              {i18n.t("global.langue")}
            </span>

            <Menu show={showLgMenu}>
              <MenuItem
                className="admin"
                onClick={() => {
                  i18next.changeLanguage("en");
                  window.location.reload();
                }}
              >
                EN
              </MenuItem>

              <MenuItem
                className="admin"
                onClick={() => {
                  i18next.changeLanguage("fr");
                  window.location.reload();
                }}
              >
                FR
              </MenuItem>
              <MenuItem
                className="logout"
                onClick={() => {
                  i18next.changeLanguage("it");
                  window.location.reload();
                }}
              >
                IT
              </MenuItem>
              <MenuItem
                className="logout"
                onClick={() => {
                  i18next.changeLanguage("de");
                  window.location.reload();
                }}
              >
                DE
              </MenuItem>
              <MenuItem
                className="admin"
                onClick={() => {
                  i18next.changeLanguage("es");
                  window.location.reload();
                }}
              >
                ES
              </MenuItem>
            </Menu>
          </div>
        </div>

        <div className={styles.hamburgerMenu}>
          {!showMinMenu ? (
            <img
              onClick={() => setShowMinMenu(true)}
              src={
                require("../../../statics/images/hamburger-menu.svg").default
              }
              className={styles.hamburgerIcon}
              alt={"humberger-menu"}
            />
          ) : (
            <div></div>
          )}
          <CSSTransition
            in={showMinMenu}
            classNames="drawerMenu"
            timeout={300}
            unmountOnExit
          >
            <div className={styles.drawerMenu}>
              <div className={`${styles.row} ${styles.drawerHeader}`}>
                <img
                  id="closeWhite"
                  onClick={() => setShowMinMenu(false)}
                  src={
                    require("../../../statics/images/close-white.svg").default
                  }
                  alt={"close"}
                />
              </div>

              <ul>
                <li className={styles.fullname}>{getFullname()}</li>

                {jwtRoles?.includes("Admin") && (
                  <li
                    className={`${styles.subLink} ${
                      props.selectedPage === "ADMIN" ? styles.selected : ""
                    }`}
                  >
                    <Link to="admin/tenants">{i18n.t("global.admin")}</Link>
                  </li>
                )}

                <li className={styles.subLink}>
                  <span id="logout" onClick={() => logout()}>
                    {i18n.t("global.logout")}
                  </span>
                </li>

                <li
                  className={
                    props.selectedPage === "HOME" ? styles.selected : ""
                  }
                >
                  <Link to="">{i18n.t("global.home")}</Link>
                </li>

                {getFlow()}
                {getClassement()}
                {getExportabilite()}
                {getScreening()}
                {getLicences()}
                {getAnalytics()}

                <li
                  className={
                    props.selectedPage === "REGULATION" ? styles.selected : ""
                  }
                >
                  <Link to="/regulation/control-lists">
                    {i18n.t("pages.regulation.title")}
                  </Link>
                </li>
              </ul>
            </div>
          </CSSTransition>
        </div>
      </div>

      <SearchEccn
        isOpen={showSearchEccn}
        ouClick={""}
        onClose={() => setShowSearchEccn(false)}
        exportabilityMode={true}
        rootStore={props.rootStore}
      />

      {props.rootStore.notificationStore.display && (
        <div className={styles.notification}>
          <Notification
            status={props.rootStore.notificationStore.state}
            title={props.rootStore.notificationStore.title}
            autoHideDuration={300000}
            onClose={() => props.rootStore.notificationStore.hideNotification()}
          >
            {props.rootStore.notificationStore.message}
          </Notification>
        </div>
      )}
    </div>
  );
};

export default inject("rootStore")(observer(AppBar));
