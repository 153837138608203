import React, { Component, RefObject } from "react";
import { Transition } from "react-transition-group";
import injectSheet from "react-jss";
import cx from "classnames";

const TIMEOUT = 300;

type Props = {
  timeout?: number;
};

type CollapseProps = Props;

const styles = {
  container: {
    height: 0,
    minHeight: 0,
    overflow: "hidden",
    transition: `height ${TIMEOUT} ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
  },
  entered: {
    height: "auto",
    overflow: "visible",
  },
};

export class Collapse extends Component<CollapseProps> {
  private wrapperRef: RefObject<HTMLDivElement>;

  static defaultProps = {
    timeout: TIMEOUT,
  };

  constructor(props: CollapseProps) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  handleEnter = (node: HTMLElement) => {
    node.style.height = "0px";
  };

  setNodeToActualSize = (node: HTMLElement) => {
    const wrapperHeight = this.wrapperRef.current
      ? this.wrapperRef.current.clientHeight
      : 0;
    node.style.height = `${wrapperHeight}px`;
  };

  handleEntered = (node: HTMLElement) => {
    node.style.height = "auto";
  };

  handleExiting = (node: HTMLElement) => {
    setTimeout(() => {
      node.style.height = "0px";
    }, 0);
  };

  render() {
    const { timeout, children, ...rest } = this.props;
    return <div></div>;
  }
}

export default injectSheet(styles)(Collapse);
