/* Styles import */
import styles from "./loader.module.css";

/* Global import */
import React, { Component } from "react";
import { Loading } from "../../components/horizon-components-react/src/components";

interface Props {}
interface State {}

export default class Loader extends Component<Props, State> {
  render() {
    return (
      <div className={styles.overlay}>
        <div>
          <Loading />
        </div>
      </div>
    );
  }
}
