import * as React from "react";
import {
  ChangeEvent,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import cx from "classnames";
import classes from "./styles.module.scss";
import uniqueId from "lodash.uniqueid";
//import EventListener from "react-event-listener";
import debounce from "debounce"; // < 1kb payload overhead when lodash/debounce is > 3kb.

import IconButton from "../IconButton";
import Clear from "@material-ui/icons/Clear";
import { observer, inject } from "mobx-react";
import { Input } from "@material-ui/core";

/*useEffect(() => {
    const handleResize = debounce(() => {
      if (inputRef.current && clearIconRef.current) {
        moveClearButton(inputRef.current, clearIconRef.current);
      }
    }, 166);

    // Créez des références (refs) pour vos éléments DOM (input et clearIcon)
    const inputRef = React.createRef();
    const clearIconRef = React.createRef();

    // Fonction pour gérer le déplacement du bouton de suppression
    const moveClearButton = (inputNode: { offsetTop?: any; offsetHeight?: any; offsetLeft?: any; offsetWidth?: any; }, iconNode: { parentElement?: any; }) => {
      let parentNode = iconNode.parentElement;
    if (parentNode != null) {
      let position = {
        top: `${inputNode.offsetTop +
          (inputNode.offsetHeight - parentNode.offsetHeight) / 2}px`,
        left: `${inputNode.offsetLeft +
          inputNode.offsetWidth -
          parentNode.offsetWidth -
          (32 - parentNode.offsetWidth) / 2}px`
      };
      parentNode.style.top = position.top;
      parentNode.style.left = position.left;
      parentNode.style.position = "absolute";
    }
  }
    // Ajoutez un écouteur d'événement de redimensionnement de fenêtre lorsque le composant est monté
    window.addEventListener('resize', handleResize);
    // Nettoyez l'écouteur d'événement lorsque le composant est démonté
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);*/

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  /** If `true`, the component will be better suited to dark backgrounds. */
  dark?: boolean;
  /** The label to show next to the <input /> */
  label?: string;
  /** Help text below input */
  helperText?: string;
  /** The callback for when the input value changes */
  /*onChange?: (
    event: React.ChangeEvent<HTMLInputElement> | null,
    value: string
  ) => void;*/
  /** The callback for when user press enter key */
  onSearch?: (value: string) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  capture?: boolean | "user" | "environment"; // Mettez capture en conformité avec le HTML5
}

interface ISearchInputState {}

type ISearchInputProps = IProps &
  Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    Exclude<keyof React.InputHTMLAttributes<HTMLInputElement>, "onChange">
  >;

type finalProps = ISearchInputProps & ISearchInputState;
const SearchInput: React.FC<IProps> = ({
  label,
  placeholder,
  value,
  helperText,
  className,
  dark,
  onSearch,
  onChange,
  ...rest
}) => {
  /*private id: string = uniqueId("SearchInput-");
  private input: HTMLInputElement | null = null;
  private clearIcon: any = null;
  inputRef: any;
  clearIconRef: any;

  constructor(props: ISearchInputProps | Readonly<ISearchInputProps>) {
    super(props);
    state = {
      inputRef: React.createRef(),
      clearIconRef: React.createRef(),
    };
    handleResize = debounce(handleResize, 166);
  }*/

  const id = uniqueId("SearchInput-");
  const input = { value: "" };
  let clearIcon = null;
  const inputRef = useRef();
  const clearIconRef = useRef();
  const [valeurInput, setValeurInput] = useState("");

  useEffect(() => {
    const handleResize = debounce(() => {
      if (inputRef.current && clearIconRef.current) {
        moveClearButton(inputRef.current, clearIconRef.current);
      }
    }, 166);

    // Créez des références (refs) pour vos éléments DOM (input et clearIcon)
    const inputRef = React.createRef();
    const clearIconRef = React.createRef();

    // Fonction pour gérer le déplacement du bouton de suppression
    const moveClearButton = (
      inputNode: {
        offsetTop?: any;
        offsetHeight?: any;
        offsetLeft?: any;
        offsetWidth?: any;
      },
      iconNode: { parentElement?: any },
    ) => {
      let parentNode = iconNode.parentElement;
      if (parentNode != null) {
        let position = {
          top: `${
            inputNode.offsetTop +
            (inputNode.offsetHeight - parentNode.offsetHeight) / 2
          }px`,
          left: `${
            inputNode.offsetLeft +
            inputNode.offsetWidth -
            parentNode.offsetWidth -
            (32 - parentNode.offsetWidth) / 2
          }px`,
        };
        parentNode.style.top = position.top;
        parentNode.style.left = position.left;
        parentNode.style.position = "absolute";
      }
    };
    // Ajoutez un écouteur d'événement de redimensionnement de fenêtre lorsque le composant est monté
    window.addEventListener("resize", handleResize);
    // Nettoyez l'écouteur d'événement lorsque le composant est démonté
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /*
  componentDidMount() {
    window.addEventListener("resize", handleResize);
    moveClearButton();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", handleResize);
  }

  componentDidUpdate(prevProps: any, prevState: { inputRef: any; clearIconRef: any; }) {
     if (
    inputRef.current &&
    clearIconRef.current &&
    (inputRef !== prevState.inputRef ||
      clearIconRef !== prevState.clearIconRef)
  ) {
    moveClearButton();
  }
  }

  const moveClearButton = () => {
    const { inputRef, clearIconRef } = this;
  if (inputRef.current && clearIconRef.current) {
    const inputNode = inputRef.current;
    const iconNode = clearIconRef.current;
    let parentNode = iconNode.parentElement;
    if (parentNode != null) {
      let position = {
        top: `${inputNode.offsetTop +
          (inputNode.offsetHeight - parentNode.offsetHeight) / 2}px`,
        left: `${inputNode.offsetLeft +
          inputNode.offsetWidth -
          parentNode.offsetWidth -
          (32 - parentNode.offsetWidth) / 2}px`,
      };
      parentNode.style.top = position.top;
      parentNode.style.left = position.left;
      parentNode.style.position = "absolute";
    }
  }
  }

  const handleResize = () => {
    moveClearButton();
  }*/

  /*componentDidMount() {
    if (input && clearIcon) {
      moveClearButton(input, clearIcon);
    }
  }*/

  //const id = props.id || id;
  /*
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && props.onSearch) {
      props.onSearch((e.target as HTMLInputElement).value);
    }
  };

  const handleClear = () => {
   if (input) {
    input.value = "";
    if (props.onChange) {
      props.onChange({
        target: { value: "" }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }
  };*/

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nouvelleValeur = e.target.value;
    setValeurInput(nouvelleValeur);
    if (onChange) {
      onChange(e);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && onSearch) {
      onSearch((e.target as HTMLInputElement).value);
    }
  };

  const handleClear = () => {
    if (input) {
      setValeurInput("");
      if (onChange) {
        onChange({
          target: { value: "" },
        } as React.ChangeEvent<HTMLInputElement>);
      }
    }
  };

  return (
    // <EventListener target="window" onResize={handleResize}>
    <div
      className={cx(
        classes["ds-form-group"],
        {
          [classes["ds-form--dark"]]: dark,
        },
        className,
      )}
    >
      {label && (
        <label htmlFor={id} className={cx(classes["ds-label"])}>
          {label}
        </label>
      )}
      <input
        id={id}
        value={valeurInput}
        type="search"
        required
        ref={(input) => {
          input = input;
        }}
        placeholder={placeholder || label}
        {...rest}
        className={cx(classes["ds-search-box"])}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {/* <Input 
          id = {id}
          value = {value}
          type = 'search'
          required
          placeholder = { placeholder || label }
          onChange={e => console.log(e)}
          />*/}
      <IconButton
        className={cx(classes["ds-clear-button"])}
        aria-label="Clear"
        onClick={handleClear}
      >
        <Clear
          ref={(ref) => {
            clearIcon = ReactDOM.findDOMNode(ref as React.ReactInstance);
          }}
          className={cx(classes["ds-clear-icon"])}
        />
      </IconButton>
      <div className={cx(classes["ds-form-helptext"])}>{helperText}</div>
    </div>
    // </EventListener>
  );

  /*handleResize = debounce(() => {
    // Skip configuration where the position is screen size invariant.
    if (input && clearIcon) {
      moveClearButton(input, clearIcon);
    }
  }, 166); // Corresponds to 10 frames at 60 Hz.*/

  /*moveClearButton(inputNode: HTMLElement, iconNode: HTMLElement): void {
    let parentNode = iconNode.parentElement;
    if (parentNode != null) {
      let position = {
        top: `${inputNode.offsetTop +
          (inputNode.offsetHeight - parentNode.offsetHeight) / 2}px`,
        left: `${inputNode.offsetLeft +
          inputNode.offsetWidth -
          parentNode.offsetWidth -
          (32 - parentNode.offsetWidth) / 2}px`
      };
      parentNode.style.top = position.top;
      parentNode.style.left = position.left;
      parentNode.style.position = "absolute";
    }
  }*/
};

export default observer(SearchInput);
