import React, { SFC, MouseEvent, ChangeEvent } from "react";
import cx from "classnames";
import range from "lodash.range";
import uniqueid from "lodash.uniqueid";
import FormGroup from "../FormGroup";
import classes from "./styles.module.scss";

type Props = {
  /** The total number of rows. */
  count: number;
  /** The one-based index of the current page. */
  page: number;
  /** The number of rows per page. */
  rowsPerPage: number;
  /** Customizes the options of the rows per page select field. */
  rowsPerPageOptions?: number[];
  /** The callback fired when the user clicks a page link */
  onPageChange: (event: MouseEvent<HTMLAnchorElement>, page: number) => void;
  /** The callback fired when the number of rows per page is changed */
  onRowsPerPageChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  /** The legend of the pagination. */
  legend?: string;
};

const ROWS_PER_PAGE_OPTIONS_DEFAULT = [5, 10, 25];

const Ellipsis = () => (
  <li className={classes["ds-pagination__item-ellipsis"]}>&hellip;</li>
);

const Pagination: SFC<Props> = ({
  page,
  count,
  rowsPerPage,
  rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS_DEFAULT,
  onPageChange,
  onRowsPerPageChange,
  legend,
}) => {
  const pageCount = Math.ceil(count / rowsPerPage);
  const windowSize = 5;
  const actualSize = windowSize > pageCount ? pageCount : windowSize;
  let startPage = page - Math.floor(actualSize / 2);
  startPage = Math.max(startPage, 1);
  startPage = Math.min(startPage, pageCount - actualSize + 1);
  const endPage = startPage + actualSize - 1;
  const hasLeftEllipsis = startPage !== 1;
  const hasRightEllipsis = endPage !== pageCount;
  const pages = range(
    hasLeftEllipsis ? startPage + 1 : startPage,
    hasRightEllipsis ? endPage : endPage + 1,
  );

  return (
    <nav className={classes["ds-pagination"]} aria-label="Pagination">
      {legend && onRowsPerPageChange && (
        <div className={classes["ds-pagination__legend"]}>
          <FormGroup>
            <select value={rowsPerPage} onChange={onRowsPerPageChange}>
              {rowsPerPageOptions.map((val) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </select>
          </FormGroup>
          {legend}
        </div>
      )}
      {legend && !onRowsPerPageChange && (
        <div className={classes["ds-pagination__legend"]}>{legend}</div>
      )}
      <ol className={classes["ds-pagination__list"]}>
        <li
          className={cx(classes["ds-pagination__item-previous"], {
            [classes["ds-pagination__item--disabled"]]: page === 1,
          })}
        >
          {page === 1 ? (
            "Previous"
          ) : (
            <a
              aria-label="Previous page"
              onClick={(event) => onPageChange(event, page - 1)}
            >
              Previous
            </a>
          )}
        </li>
        {hasLeftEllipsis && <Ellipsis />}
        {pages.map((p: number) =>
          p === page ? (
            <li
              key={uniqueid("page-")}
              className={cx(
                classes["ds-pagination__item"],
                classes["ds-pagination__item--current"],
              )}
            >
              {p}
            </li>
          ) : (
            <li
              key={uniqueid("page-")}
              className={classes["ds-pagination__item"]}
            >
              <a
                aria-label={`Page ${page}`}
                onClick={(event) => onPageChange(event, p)}
              >
                {p}
              </a>
            </li>
          ),
        )}
        {hasRightEllipsis && <Ellipsis />}
        <li
          className={cx(classes["ds-pagination__item-next"], {
            [classes["ds-pagination__item--disabled"]]: page === pageCount,
          })}
        >
          {page === pageCount ? (
            "Next"
          ) : (
            <a
              aria-label="Next page"
              onClick={(event) => onPageChange(event, page + 1)}
            >
              Next
            </a>
          )}
        </li>
      </ol>
    </nav>
  );
};

export default Pagination;
