/* Styles import */
import styles from "./equipment.module.css";

/* Global import */
import React, { useEffect, useState } from "react";
import {
  GridRow,
  GridCol,
  TextInput,
  IconButton,
  TextArea,
  Checkbox,
} from "../../components/horizon-components-react/src/components";
import i18n from "i18next";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/DeleteSweep";

/* Custom import  */
import SearchEccn from "../searchEccn";
import { Tooltip } from "@material-ui/core";

interface Props {
  index: number;
  onChange: (equipment: LicenseEquipment) => void;
  removeEquipment: (index: number) => void;
  rootStore: import("../../stores/rootStore").default;
  historyValues?: LicenseEquipment;
  disabled?: boolean;
  regulation?: string;
}

const EquipmentComponent = ({
  index,
  onChange,
  removeEquipment,
  rootStore,
  historyValues,
  regulation,
  disabled,
}: Props) => {
  const [eccnCode, setEccnCode] = useState("");
  const [partNumber, setPartNumber] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [quantityActual, setQuantityActual] = useState<number | undefined>(
    undefined
  );
  const [quantityInitial, setQuantityInitial] = useState(0);
  const [quantityOrdered, setQuantityOrdered] = useState(0);
  const [quantityDelivered, setQuantityDelivered] = useState(0);
  const [amountOrdered, setAmountOrdered] = useState(0);
  const [amountDelivered, setAmountDelivered] = useState(0);

  const [amountInitial, setAmountInitial] = useState(0);
  const [amountActual, setAmountActual] = useState<number | undefined>(
    undefined
  );

  const [amount, setAmount] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);

  const [keyword, setKeyword] = useState("");
  const [model, setModel] = useState("");

  const [publicListName, setPublicListName] = useState("");
  const [usOrigin, setUsOrigin] = useState(false);
  const [classificationIsOpen, setClassificationIsOpen] = useState(false);
  const [activeLine, setActiveLine] = useState(false);
  const [userChanged, setUserChanged] = useState(false);

  useEffect(() => {
    if (historyValues) {
      setEccnCode(historyValues.eccn_code || eccnCode);
      setPartNumber(historyValues.partNumber || partNumber);
      setQuantity(historyValues.quantity || quantity);
      setQuantityInitial(historyValues.quantityInitial || quantityInitial);
      setQuantityOrdered(historyValues.quantityOrdered || quantityOrdered);
      setQuantityDelivered(
        historyValues.quantityDelivered || quantityDelivered
      );
      setQuantityActual(
        historyValues.quantityActual !== undefined
          ? historyValues.quantityActual
          : quantityActual
      );
      setAmountInitial(historyValues.amountInitial || amountInitial);
      setAmountDelivered(historyValues.amountDelivered || amountDelivered);
      setAmountOrdered(historyValues.amountOrdered || amountOrdered);
      setAmountActual(
        historyValues.amountActual !== undefined
          ? historyValues.amountActual
          : amountActual
      );
      setAmount(historyValues.amount || amount);
      setUnitPrice(historyValues.unitPrice || unitPrice);

      setKeyword(historyValues.keyword || keyword);
      setPublicListName(historyValues.publicListName || publicListName);
      setUsOrigin(historyValues.usOrigin || usOrigin);
      setModel(historyValues.model || model);
      if (
        regulation === "FR_MUNITIONS_LIST" &&
        userChanged === false &&
        historyValues.activeLine !== undefined
      ) {
        setActiveLine(true);
      }
    }
  }, [
    eccnCode,
    partNumber,
    quantity,
    quantityActual,
    amountActual,
    amountInitial,
    unitPrice,
    amount,
    keyword,
    publicListName,
    usOrigin,
    historyValues,
    activeLine,
  ]);

  const updateEquipment = (equipmentElem: object) => {
    const updatedEquipment = {
      id: historyValues?.id,
      eccnCode,
      partNumber,
      quantity,
      quantityActual,
      quantityOrdered,
      quantityDelivered,
      quantityInitial,
      amountActual,
      amountInitial,
      amountOrdered,
      amountDelivered,
      unitPrice,
      amount,
      keyword,
      publicListName,
      usOrigin,
      activeLine,
      model, //mdr il a jamais été enregistré si on update l'equip en fait
      ...equipmentElem,
    };

    onChange(updatedEquipment);
  };

  const handleEccn = (e: any) => {
    setEccnCode(e.target.value);
    updateEquipment({
      eccnCode: e.target.value,
    });
  };

  const handlekeyword = (e: any) => {
    // setEccnCode(value)
    updateEquipment({
      keyword: e.target.value,
    });
  };

  const handlePartNumber = (e: any) => {
    setPartNumber(e.target.value);
    updateEquipment({
      partNumber: e.target.value,
    });
  };

  const handleQuantity = (e: any) => {
    const quantity = parseInt(e.target.value, 10);

    setQuantity(quantity);
    updateEquipment({
      quantity: quantity,
    });
  };

  const handleQuantityOrdered = (e: any) => {
    const quantityOrdered = parseInt(e.target.value, 10);

    setQuantityOrdered(quantityOrdered);
    updateEquipment({
      quantityOrdered: quantityOrdered,
    });
  };
  const handleQuantityDelivered = (e: any) => {
    const quantityDelivered = parseInt(e.target.value, 10);

    setQuantityDelivered(quantityDelivered);
    updateEquipment({
      quantityDelivered: quantityDelivered,
    });
  };

  const handleQuantityInitial = (e: any) => {
    const quantityIni = parseInt(e.target.value, 10);

    setQuantityInitial(quantityIni);
    updateEquipment({
      quantityInitial: quantityIni,
    });
  };

  const handleActiveLine = (e: any) => {
    setActiveLine(e.target.checked);
    setUserChanged(true);
    updateEquipment({
      activeLine: e.target.checked,
    });
  };

  const handleQuantityActual = (e: any) => {
    let finalQuantityActual: number | undefined;

    if (e.target.value === "") {
      finalQuantityActual = undefined;
    } else {
      const newQuantityActual = parseInt(e.target.value, 10);
      finalQuantityActual = isNaN(newQuantityActual)
        ? undefined
        : newQuantityActual;
    }
    setQuantityActual(finalQuantityActual);
    updateEquipment({
      quantityActual: finalQuantityActual,
    });
  };

  const handleUnitPrice = (e: any) => {
    const unitPrice = parseInt(e.target.value, 10);

    setUnitPrice(unitPrice);
    updateEquipment({
      unitPrice: unitPrice,
    });
  };
  const handleAmountInitial = (e: any) => {
    const amountInitial = parseInt(e.target.value, 10);

    setAmountInitial(amountInitial);
    updateEquipment({
      amountInitial: amountInitial,
    });
  };

  const handleAmountOrdered = (e: any) => {
    const amountOrdered = parseInt(e.target.value, 10);

    setAmountOrdered(amountOrdered);
    updateEquipment({
      amountOrdered: amountOrdered,
    });
  };
  const handleAmountDelivered = (e: any) => {
    const amountDelivered = parseInt(e.target.value, 10);

    setAmountDelivered(amountDelivered);
    updateEquipment({
      amountDelivered: amountDelivered,
    });
  };

  const handleAmountActual = (e: any) => {
    let finalAmountActual: number | undefined;
    if (e.target.value === "") {
      finalAmountActual = undefined;
    } else {
      const newAmountActual = parseInt(e.target.value, 10);
      finalAmountActual = isNaN(newAmountActual) ? undefined : newAmountActual;
    }

    setAmountActual(finalAmountActual);
    updateEquipment({
      amountActual: finalAmountActual,
    });
  };

  const handleModel = (e: any) => {
    let newModel: string | undefined;
    if (e.target.value === "") {
      newModel = undefined;
    } else {
      newModel = e.target.value.toString();
    }
    setModel(newModel as string);
    updateEquipment({
      model: newModel,
    });
  };

  const handleClose = (eccn?: ClassificationEccn) => {
    if (eccn) {
      setEccnCode(eccn.eccnCode);
      setKeyword(eccn.keyword || "");
      setPublicListName(eccn.publicListName || "");
      setUsOrigin(eccn.usOrigin);
      updateEquipment({
        eccnCode: eccn.eccnCode,
        keyword: eccn.keyword || "",
        publicListName: eccn.publicListName || "",
        usOrigin: eccn.usOrigin,
      });
    }

    setClassificationIsOpen(false);
  };

  return (
    <div className={styles.box} key={index}>
      <div className={styles.boxHeader}>
        {`${i18n.t("pages.license.add.equipment")} ${index + 1}`}
        {/* To DO condition munition list */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {regulation === "FR_MUNITIONS_LIST" && (
            <Checkbox
              style={{ marginRight: "2px" }}
              id="us-origin"
              large
              label={i18n.t("pages.license.add.active_line")}
              checked={activeLine}
              onChange={(e) => handleActiveLine(e)}
            />
          )}
          {index !== 0 && !rootStore.userStore.isAuthorized(["L_R_A"]) && (
            <IconButton
              onClick={() => removeEquipment(index)}
              style={{ marginTop: "5px" }}
            >
              <Tooltip title={() => i18n.t("pages.search-flow.tooltip.delete")}>
                <DeleteIcon style={{ color: "#e74c3c" }} />
              </Tooltip>
            </IconButton>
          )}
        </div>
      </div>

      <SearchEccn
        onClose={handleClose}
        isOpen={classificationIsOpen}
        ouClick={"home"}
        rootStore={rootStore}
      />

      <GridRow>
        <GridCol md={3} xs={12}>
          <TextInput
            id={"eccn-input"}
            label={i18n.t("pages.license.add.douanierAdd")}
            value={keyword || ""}
            errorText={i18n.t("errors")}
            onChange={(e) => handlekeyword(e)}
            disabled={disabled || false}
            className={styles.classification}
          />
        </GridCol>

        <GridCol md={3} xs={12}>
          <div className={styles.row}>
            <TextInput
              id={"eccn-input"}
              label={i18n.t("pages.license.add.classificationAdd")}
              required={true}
              value={eccnCode || ""}
              errorText={i18n.t("errors.licenses.submitField")}
              onChange={(e) => handleEccn(e)}
              disabled={disabled || false}
              className={styles.classification}
            />
            {/*
            <IconButton
              id="search"
              className={styles.searchButton}
              onClick={() => !disabled && setClassificationIsOpen(true)}
            >
              <SearchIcon />
            </IconButton>
          */}
          </div>
        </GridCol>

        <GridCol md={3} xs={12}>
          <TextInput
            id={"partNumber-input"}
            label={i18n.t("pages.license.add.part-number")}
            value={partNumber || ""}
            disabled={disabled || false}
            errorText={i18n.t("errors")}
            maxLength={30}
            onChange={(e) => handlePartNumber(e)}
          />
        </GridCol>
        <GridCol md={3} xs={12}>
          <TextInput
            id={"unit-price"}
            required={false}
            label={i18n.t("pages.license.add.unit-price")}
            value={unitPrice || ""}
            disabled={disabled || false}
            type="number"
            step={1}
            errorText={i18n.t("errors")}
            onChange={(e) => handleUnitPrice(e)}
          />
        </GridCol>
      </GridRow>
      {/* CDC LISI ******************************************************* */}
      {regulation === "FR_MUNITIONS_LIST" ? (
        <div>
          <GridRow>
            <GridCol md={3} xs={6}>
              <TextInput
                id={"quantity-input-initial"}
                required={true}
                label={i18n.t("pages.license.add.quantity-initial")}
                value={quantityInitial || ""}
                disabled={disabled || false}
                type="number"
                min={0}
                step={1}
                errorText={i18n.t("errors.licenses.submitField")}
                onChange={(e) => handleQuantityInitial(e)}
              />
            </GridCol>
            <GridCol md={3} xs={6}>
              <TextInput
                id={"quantity-input-ordered"}
                required={false}
                label={i18n.t("pages.license.add.quantity-ordered")}
                value={
                  quantityOrdered !== undefined
                    ? quantityOrdered.toString()
                    : ""
                }
                disabled={disabled || false}
                type="number"
                step={1}
                errorText={i18n.t("errors.licenses.submitField")}
                onChange={(e) => handleQuantityOrdered(e)}
              />
            </GridCol>
            <GridCol md={3} xs={6}>
              <TextInput
                id={"quantity-input-delivered"}
                required={false}
                label={i18n.t("pages.license.add.quantity-delivered")}
                value={
                  quantityDelivered !== undefined
                    ? quantityDelivered.toString()
                    : ""
                }
                disabled={disabled || false}
                type="number"
                step={1}
                errorText={i18n.t("errors.licenses.submitField")}
                onChange={(e) => handleQuantityDelivered(e)}
              />
            </GridCol>
          </GridRow>
          <GridRow>
            {" "}
            <GridCol md={3} xs={6}>
              <TextInput
                id={"amount-input-initial"}
                required={true}
                label={i18n.t("pages.license.add.amount-initial")}
                value={amountInitial || ""}
                disabled={disabled || false}
                type="number"
                min={0}
                step={1}
                errorText={i18n.t("errors.licenses.submitField")}
                onChange={(e) => handleAmountInitial(e)}
              />
            </GridCol>
            <GridCol md={3} xs={6}>
              <TextInput
                id={"amount-input-ordered"}
                required={false}
                label={i18n.t("pages.license.add.amount-ordered")}
                value={
                  amountOrdered !== undefined ? amountOrdered.toString() : ""
                }
                disabled={disabled || false}
                type="number"
                step={1}
                errorText={i18n.t("errors.licenses.submitField")}
                onChange={(e) => handleAmountOrdered(e)}
              />
            </GridCol>
            <GridCol md={3} xs={6}>
              <TextInput
                id={"amount-input-delivered"}
                required={false}
                label={i18n.t("pages.license.add.amount-delivered")}
                value={
                  amountDelivered !== undefined
                    ? amountDelivered.toString()
                    : ""
                }
                disabled={disabled || false}
                type="number"
                step={1}
                errorText={i18n.t("errors.licenses.submitField")}
                onChange={(e) => handleAmountDelivered(e)}
              />
            </GridCol>
          </GridRow>
        </div>
      ) : (
        <GridRow>
          <GridCol md={3} xs={6}>
            <TextInput
              id={"quantity-input"}
              required={true}
              label={i18n.t("pages.license.add.quantity-initial")}
              value={quantity || ""}
              disabled={disabled || false}
              type="number"
              min={0}
              step={1}
              errorText={i18n.t("errors.licenses.submitField")}
              onChange={(e) => handleQuantity(e)}
            />
          </GridCol>
          <GridCol md={3} xs={6}>
            <TextInput
              id={"quantity-input-actual"}
              required={true}
              label={i18n.t("pages.license.add.quantity-actual")}
              value={
                quantityActual !== undefined ? quantityActual.toString() : ""
              }
              disabled={disabled || false}
              type="number"
              step={1}
              errorText={i18n.t("errors.licenses.submitField")}
              onChange={(e) => handleQuantityActual(e)}
            />
          </GridCol>
          <GridCol md={3} xs={6}>
            <TextInput
              id={"amount-input-initial"}
              required={true}
              label={i18n.t("pages.license.add.amount-initial")}
              value={amountInitial || ""}
              disabled={disabled || false}
              type="number"
              min={0}
              step={1}
              errorText={i18n.t("errors.licenses.submitField")}
              onChange={(e) => handleAmountInitial(e)}
            />
          </GridCol>
          <GridCol md={3} xs={6}>
            <TextInput
              id={"amount-input-actual"}
              required={true}
              label={i18n.t("pages.license.add.amount-actual")}
              value={amountActual !== undefined ? amountActual.toString() : ""}
              disabled={disabled || false}
              type="number"
              step={1}
              errorText={i18n.t("errors.licenses.submitField")}
              onChange={(e) => handleAmountActual(e)}
            />
          </GridCol>
        </GridRow>
      )}
      {/* CDC LISI ******************************************************* */}

      <GridRow>
        <GridCol md={12}>
          <TextArea
            id="model"
            label={i18n.t("pages.license.add.model")}
            required={false}
            disabled={disabled}
            //errorText={ i18n.t('errors') }
            onChange={(e) => handleModel(e)}
            // options={ rootStore.modelStore.modelToOptions }
            value={
              model
                ?.replace(/&#39;/g, "'")
                ?.replace(/&amp;/g, "&")
                ?.replace(/&lt;/g, "<")
                ?.replace(/&gt;/g, ">")
                ?.replace(/&quot;/g, '"') || ""
            }

            //returnLabel={ true }
          />
        </GridCol>
      </GridRow>
    </div>
  );
};

export default EquipmentComponent;
