import React, { ReactNode, HTMLAttributes, SFC } from "react";
import cx from "classnames";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import classes from "../Table/styles.module.scss";

type Props = {
  sortDirection?: "asc" | "desc";
  onSort?: () => void;
  /** The content of the table */
  children?: ReactNode;
};

type TableHeaderCellProps = Props & HTMLAttributes<HTMLTableHeaderCellElement>;

const TableHeaderCell: SFC<TableHeaderCellProps> = ({
  sortDirection,
  onSort = () => {},
  children,
  ...rest
}) => (
  <th {...rest}>
    <button
      className={cx(classes["ds-table__button-sorting"], {
        [classes["ds-table__button-sorting--asc"]]: sortDirection === "asc",
      })}
      onClick={onSort}
    >
      {children}
      {sortDirection && (
        <ArrowDropDownIcon
          className={classes["ds-icon-caret"]}
          aria-hidden="true"
        />
      )}
    </button>
  </th>
);

export default TableHeaderCell;
