import * as React from 'react';
import { OutlinedInput, OutlinedInputProps } from '@mui/material';

type DebounceProps = {
  handleDebounce: (value: string) => void ;
  debounceTimeout: number;
  //onChangeInherited: (filter: string) => void;
};

export default function DebounceInput(props: OutlinedInputProps & DebounceProps) {
  const { handleDebounce, debounceTimeout, ...rest } = props;

  const timerRef = React.useRef<ReturnType<typeof setTimeout>>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timerRef.current as NodeJS.Timeout);
    timerRef.current = setTimeout(() => {
      handleDebounce(event.target.value);
    }, debounceTimeout);
  };

  return <OutlinedInput {...rest} onChange={handleChange} />;
}