import React, { useEffect, useState } from "react";
import styles from "./license.module.css";
import { inject, observer } from "mobx-react";
import Api from "../../utils/api";
import { useNavigate } from "react-router-dom";
import AppBar from "../../components/appBar";
import HeaderFlow from "../../components/headerFlow";
import Loader from "../../components/loader";
import moment from "moment";
import { flowType } from "../../utils/constant";
import {
  convertEntityNameToId,
  convertEntityIdToName,
  flowStatus,
} from "../../utils/generator";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  Input,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import i18n from "i18next";
import EditIcon from "@material-ui/icons/Create";
import DebounceInput from "../../components/debounceInput";
import { UploadFileInput } from "../../customComponents/uploadFileInput";
import { toJS } from "mobx";
import Axios from "axios";
import configuration from "../../configuration";

interface Props {
  rootStore: import("../../stores/rootStore").default;
}

interface HeadRowData {
  order_num: number;
  createdAt: number;
  entite: string;
  departure_country: string;
  destination_country: string;
  flowType: FlowType;
  customer: string;
  flowEquipments: string;
  DUMLeccn: string;
  EARITAReccn: string;
  actions: string;
}

interface HeadCell {
  id: keyof HeadRowData;
  label: string;
  disableSorting?: boolean;
  showFilter?: boolean;
  typeFilter?: string;
  options?: Array<{ value: string | number; label: string }>;
}

const showFlows: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const navigate = useNavigate();
  const navigateTowards = (id: any) => {
    rootStore.authStore.checkIsConnect(); //todo remove
    const url = `/flow/${id}/classification`;
    navigate(url);
  };

  const [state, setState] = useState({
    page: 1,
    totalResults: 0,
    customer: "",
    filter: "ALL",
    open: false,
    open1: false,
    desId: 0,
    ml: false,
    usItar: false,
    search: "",
    status: "" as FlowStatus,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [flows, setFlows] = useState<newFlowDto[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [beforeDate, setBeforeDate] = useState("");

  /*FILTRAGE DES DONNEES*/
  const [filterText, setFilterText] = useState<{
    [key in keyof HeadRowData]?: string;
  }>({});
  /*FILTRAGE DES DONNEES*/

  /* ENTETES */
  const [headCellsState, setHeadCellsState] = useState<HeadCell[]>([
    {
      id: "order_num",
      label: i18n.t("pages.search-flow.order_num"),
      showFilter: true,
      typeFilter: "search",
    },
    {
      id: "createdAt",
      label: i18n.t("pages.search-flow.creation-date"),
      showFilter: true,
      typeFilter: "date",
    },
    {
      id: "entite",
      label: i18n.t("pages.search-flow.entite"),
      showFilter:
        toJS(rootStore.tenantStore.entities).length > 0 ? true : false,
      typeFilter: "select",
    },
    {
      id: "departure_country",
      label: i18n.t("pages.search-flow.departure-country"),
      showFilter: true,
      typeFilter: "select",
    },
    {
      id: "destination_country",
      label: i18n.t("pages.search-flow.end-user-country"),
      showFilter: true,
      typeFilter: "select",
    },
    {
      id: "flowType",
      label: i18n.t("pages.search-flow.flow-type"),
      showFilter: true,
      typeFilter: "select",
    },
    {
      id: "customer",
      label: i18n.t("pages.search-flow.customers-company"),
      showFilter: true,
      typeFilter: "search",
    },
    {
      id: "flowEquipments",
      label: i18n.t("pages.search-flow.model"),
      showFilter: false,
    },
    {
      id: "DUMLeccn",
      label: i18n.t("pages.search-flow.du-ml"),
      showFilter: false,
    },
    { id: "EARITAReccn", label: i18n.t("pages.search-flow.regulation") },
    { id: "actions", label: i18n.t("pages.search-flow.action") },
  ]);
  /* ENTETES */

  const [entite, setEntite] = useState<string>(
    convertEntityNameToId(
      rootStore.userStore.user?.entite,
      toJS(rootStore.tenantStore.entities)
    )
  );

  const [messageNotification, setMessageNotification] = useState("");
  const [open, setSnackOpen] = React.useState(false);
  const [statusSnack, setStatusSnack] = useState(0);

  const loadData = async () => {
    await rootStore.countriesStore.getCountries();
    rootStore.countriesStore.getCountriesDeparture();
  };
  const fetchData = async () => {
    setLoading(true);
    let params =
      "page=" + currentPage + `&tenantId=${rootStore.userStore.user?.tenantId}`;

    if (
      rootStore.userStore.user?.entite !== "ALL" &&
      rootStore.userStore.user?.entite != null &&
      rootStore.userStore.user?.entite !== ""
    ) {
      let entiteId = convertEntityNameToId(
        rootStore.userStore.user?.entite,
        toJS(rootStore.tenantStore.entities)
      );
      params += `&entite=${entiteId}`;
    }

    if (beforeDate) {
      params += `&before_date=${beforeDate}`;
    }
    if (filterText) {
      if (filterText.departure_country) {
        params += `&departure_country=${filterText.departure_country}`;
      }
      if (filterText.destination_country) {
        params += `&destination_country=${filterText.destination_country}`;
      }
      if (filterText.flowType) {
        params += `&flow_type=${filterText.flowType}`;
      }
      if (filterText.order_num) {
        params += `&order_num=${filterText.order_num}`;
      }
      if (filterText.customer) {
        params += `&customer=${filterText.customer}`;
      }
      if (filterText.entite) {
        params += `&filtreEntite=${filterText.entite}`;
      }
    }

    const flux = await api.newSearchFlow(params);

    if (flux) {
      setTotalPages(flux.last_page);
      setFlows(flux.data);

      const flowTypeOptions = flowType.map((type) => ({
        label: type.label,
        value: type.value,
      }));
      const entiteOptions =
        rootStore.userStore.user?.entite === "ALL"
          ? rootStore.tenantStore.entities.map((entite) => ({
              label: entite.id_entite,
              value: entite.id,
            }))
          : rootStore.tenantStore.entities
              .filter(
                (entite: any) =>
                  entite.id_entite === rootStore.userStore.user?.entite
              )
              .map((entite) => ({ label: entite.id_entite, value: entite.id }));

      const newHeadCells = headCellsState.map((cell) => {
        if (cell.id === "departure_country" && cell.typeFilter === "select") {
          return {
            ...cell,
            options: rootStore.countriesStore.countriesDepartureToOptions,
          };
        } else if (
          cell.id === "destination_country" &&
          cell.typeFilter === "select"
        ) {
          return {
            ...cell,
            options: rootStore.countriesStore.countriesToOptions,
          };
        } else if (cell.id === "flowType" && cell.typeFilter === "select") {
          return { ...cell, options: flowTypeOptions };
        } else if (cell.id === "entite" && cell.typeFilter === "select") {
          return { ...cell, options: entiteOptions };
        }
        return cell;
      });
      setHeadCellsState(newHeadCells);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    fetchData();
  }, [currentPage, beforeDate, filterText]);

  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof HeadRowData>();

  const handleSelectChange =
    (column: keyof HeadRowData) => (event: SelectChangeEvent<string>) => {
      setCurrentPage(1);
      setFilterText({ ...filterText, [column]: event.target.value });
    };

  const handleSearchTextChange = (
    filter: string,
    column: keyof HeadRowData
  ) => {
    setCurrentPage(1);
    setFilterText({ ...filterText, [column]: filter as string });
  };

  const handleBeforeDateChange = (beforeDate: string) => {
    setCurrentPage(1);
    setBeforeDate(beforeDate);
  };

  const handleRequestSort = (property: keyof HeadRowData) => {
    setOrderBy((currentOrderBy) => {
      const isAsc = currentOrderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      return property;
    });
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const renderFilterInput = (headCell: HeadCell) => {
    const inputStyle = {
      height: "35px",
      fontSize: "12px",
      margin: "0 !important",
      padding: "0 !important",
    };

    if (headCell.typeFilter === "date") {
      return (
        <FormControl fullWidth size="small" margin="none">
          <Input
            id="creation-before-date"
            type="date"
            style={inputStyle}
            sx={{
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
            onChange={(e) => handleBeforeDateChange(e.target.value)}
          />
        </FormControl>
      );
    }
    if (headCell.typeFilter === "select" && headCell.options) {
      const sortedOptions = headCell.options.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
      return (
        <FormControl
          fullWidth
          variant="outlined"
          size="small"
          margin="none"
          style={inputStyle}
        >
          <Select
            size="small"
            value={filterText[headCell.id] || ""}
            id="combo-box-demo"
            style={inputStyle}
            onChange={handleSelectChange(headCell.id)}
          >
            <MenuItem value="">
              <em>{i18n.t(`pages.license.search.all`)}</em>
            </MenuItem>
            {sortedOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <FormControl
          variant="outlined"
          fullWidth
          margin="none"
          style={inputStyle}
        >
          <DebounceInput
            style={inputStyle}
            handleDebounce={(event) => {
              handleSearchTextChange(event, headCell.id);
            }}
            debounceTimeout={2000}
          />
        </FormControl>
      );
    }
  };

  //DELETE
  const handleClose1 = () => setState({ ...state, open1: false });
  const submitDelete = async (flowId: number) => {
    rootStore.authStore.checkIsConnect(); //todo remove
    const searchResult = await rootStore.flowStore.deleteFlow(flowId);

    window.location.reload();
  };
  const getTranslatedFlowType = (flowTypeValue: string): string => {
    const foundType = flowType.find((type) => type.value === flowTypeValue);
    return foundType ? foundType.label : flowTypeValue;
  };

  const uploadXls = (file: File) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = new FormData();
        if (file) {
          data.append("file", file);

          data.append("id_user", String(rootStore.userStore.user?.id!));

          data.append("id_tenant", rootStore.userStore.user?.tenantId!);
        }

        const response = await Axios({
          method: "POST",
          url: `${configuration.LARAVEL_API}/importfileflow`,
          headers: {
            "content-type": "multipart/form-data",
          },

          data,
        });

        if (response.data[0] == 1) {
          setMessageNotification(i18n.t("notifications.form.success"));
          setStatusSnack(0);
          setSnackOpen(true);

          setTimeout(() => {
            navigate({ pathname: `/flow/${response.data[1]}/classification` });
          }, 1000);
        } else {
          setMessageNotification(i18n.t("notifications.form.success"));
          setStatusSnack(0);
          setSnackOpen(true);

          await fetchData();

          setTimeout(() => {
            navigate({ pathname: `/search-flow` });
          }, 1000);
        }

        resolve(response.data);
      } catch (error) {
        setMessageNotification(
          i18n.t("notifications.form.failure") + " : " + error
        );
        setStatusSnack(1);

        reject(error);
      }
    });
  };

  const renderTableFlows = () => (
    <div>
      <TableContainer>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              {/* Header column */}
              {headCellsState
                .filter(
                  (headCell) =>
                    !(
                      rootStore.tenantStore.entities.length === 0 &&
                      headCell.id === "entite"
                    )
                )
                .map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sx={{
                      width: headCell.id === "EARITAReccn" ? "30px" : "auto",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      paddingY: "0px !important",
                      paddingLeft: "8px !important",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      ":last-child": {
                        borderRight: "none",
                      },
                    }}
                  >
                    {/* Logique de visibilité (à compléter si nécessaire) */}
                    {headCell.disableSorting ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={() => handleRequestSort(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              {/* End Header column */}
            </TableRow>

            <TableRow>
              {/* Contrôles de filtre */}
              {headCellsState
                .filter(
                  (headCell) =>
                    !(
                      rootStore.tenantStore.entities.length === 0 &&
                      headCell.id === "entite"
                    )
                )
                .map((headCell) => (
                  <TableCell
                    key={headCell.id + "-filter"}
                    sx={{
                      margin: 0,
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      ":last-child": {
                        borderRight: "none",
                      },
                      paddingY: "0px !important",
                      paddingLeft: "8px !important",
                      paddingRight: "8px !important",
                    }}
                  >
                    {headCell.showFilter ? renderFilterInput(headCell) : null}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {flows.map((flow, index) => {
              let label = flowStatus["CLASSIFICATION"];
              const rowColor = index % 2 === 0 ? "#F6FBFC" : "white"; // ou une autre couleur pour les lignes paires

              if (flowStatus[flow.status]) {
                label = flowStatus[flow.status];
              }

              return (
                <TableRow key={index} style={{ backgroundColor: rowColor }}>
                  <TableCell>
                    {flow.order_num ? flow.order_num.replace("null", " ") : ""}
                  </TableCell>

                  <TableCell>
                    {flow.created_at &&
                      moment(flow.created_at)
                        .locale(i18n.language)
                        .format(
                          i18n.language === "fr" ? "D MMM YYYY" : "MMM D, YYYY"
                        )}
                  </TableCell>
                  {rootStore.tenantStore.entities.length > 0 && (
                    <TableCell className={styles.fixedColumn}>
                      {flow.entity_id
                        ? convertEntityIdToName(
                            flow.entity_id,
                            toJS(rootStore.tenantStore.entities)
                          )
                        : ""}
                    </TableCell>
                  )}
                  <TableCell>
                    {flow.departure_country
                      ? flow.departure_country.name ===
                        "United States of America (USA)"
                        ? "USA"
                        : flow.departure_country.name
                      : ""}
                  </TableCell>
                  <TableCell>
                    {flow.destination_country
                      ? flow.destination_country.name
                      : ""}
                  </TableCell>
                  <TableCell>{getTranslatedFlowType(flow.flow_type)}</TableCell>
                  <TableCell>{flow.customer}</TableCell>

                  <TableCell className={styles.mediumColumn}>
                    {flow.flow_equipments.length > 0
                      ? flow.flow_equipments[0].model
                      : ""}
                  </TableCell>
                  <TableCell className={styles.fixedColumn}>
                    {flow.status === "ARCHIVED" && <Chip label="ARCHIVED" />}
                    {!flow.has_ml_eccn && flow.has_du_eccn && (
                      <Chip label="DU" />
                    )}

                    {flow.has_ml_eccn == true && <Chip label="ML" />}

                    {!flow.has_du_eccn &&
                      !flow.has_ml_eccn == true &&
                      flow.has_nc_eccn == true && <Chip label="NL" />}
                  </TableCell>
                  <TableCell className={styles.fixedColumn}>
                    {!flow.has_itar_eccn && flow.has_ear_eccn == true && (
                      <Chip label="EAR" />
                    )}

                    {flow.has_itar_eccn == true && <Chip label="ITAR" />}

                    {!flow.has_ear_eccn &&
                      !flow.has_itar_eccn &&
                      flow.has_ear99eccn == true && <Chip label="EAR99" />}
                  </TableCell>
                  <TableCell className={styles.fixedColumn}>
                    {flow.status !== "ARCHIVED" && (
                      <IconButton onClick={() => navigateTowards(flow.id)}>
                        <Tooltip
                          title={i18n.t("pages.search-flow.tooltip.edit")}
                        >
                          <EditIcon />
                        </Tooltip>
                      </IconButton>
                    )}

                    <IconButton
                      onClick={() => {
                        setState({ ...state, desId: flow.id, open1: true });
                      }}
                    >
                      <Tooltip
                        title={i18n.t("pages.search-flow.tooltip.delete")}
                      >
                        <Delete color="warning" />
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack spacing={2} direction="row" justifyContent="flex-end">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Stack>
      <Dialog
        open={state.open1}
        title="Suppression Flux"
        onClose={handleClose1}
      >
        <DialogContent>
          Le Flux va être supprimé ainsi que ses données.
          <br />
          Etes-vous vraiment sûr ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Annuler</Button>
          <Button
            onClick={() => {
              submitDelete(state.desId);
              handleClose1();
            }}
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"FLOW"} />

      <HeaderFlow pages="SEARCH" />

      <div
        className={styles.bodyFlows}
        style={{ padding: "2px 8px !important" }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          margin="15px"
        >
          <Typography
            variant="h2"
            sx={{ fontSize: 34, fontWeight: "medium", margin: "15px" }}
          >
            {i18n.t("pages.search-flow.title")}
          </Typography>
          <UploadFileInput
            tenantId={rootStore.userStore.user?.tenantId}
            slug={i18n.t("components.upload-file-input.slug.flow.dashboard")}
            categorie={i18n.t("components.upload-file-input.categorie.flow")}
            buttonText={i18n.t("pages.new-flow.upload-flux")}
            onFileUpload={uploadXls}
          />
          <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            message={messageNotification}
          >
            <Alert
              onClose={handleClose}
              severity={statusSnack === 1 ? "error" : "success"}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {messageNotification}
            </Alert>
          </Snackbar>
        </Box>
        <div>{renderTableFlows()}</div>
        {loading && <Loader />}
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(showFlows));
