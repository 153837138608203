/* Styles import */
import styles from "./end-user.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Title,
  TextInput,
  GridRow,
  GridCol,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../../components/appBar";
import Footer from "../../../components/footer";
import Loader from "../../../components/loader";
import { flowUrl } from "../../../utils/generator";
import HeaderScreeningComponent from "../../../components/headerScreening";
import TimeLine from "../../../components/timeLine";

interface State {
  firstname: string;
  lastname: string;
  loading: boolean;
  flowId?: string;
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const EndUser: React.FC<Props> = ({ rootStore }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const navigateTowards = (url: string) => {
    navigate(flowUrl(url, id));
  };
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    loading: false,
    flowId: "",
  });

  useEffect(() => {
    if (id) {
      setState((prevState) => ({
        ...prevState,
        flowId: id,
      }));
    }
  }, []);

  const submitIsDisabled = () => {
    const { firstname, lastname } = state;
    return firstname === "" || lastname === "";
  };

  const handleSubmit = async () => {
    setState({ ...state, loading: true });

    const { firstname, lastname } = state;
    const flowId = id;

    await rootStore.screeningStore.screeningEndUser(
      firstname,
      lastname,
      parseInt(flowId || "0"),
    );

    setState({ ...state, loading: false });
    navigate(flowUrl("/screening/end-user/result", flowId));
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="SCREENING" />

      <HeaderScreeningComponent
        pages="INDIVIDUAL"
        type="CLASSIC"
        rootStore={rootStore}
      />

      {state.loading && <Loader />}

      <div className={styles.image}></div>
      <div className={styles.body}>
        <Title tag="h2">{i18n.t("pages.screening.end-user.title")}</Title>

        <form className={styles.form}>
          <div className={styles.row}>
            <div className={styles.input}>
              <TextInput
                id="firstname"
                type="text"
                label={i18n.t("pages.screening.end-user.firstname")}
                value={state.firstname || ""}
                required={true}
                errorText={i18n.t("errors.firstname-not-defined")}
                onChange={(value) =>
                  setState({ ...state, firstname: value.target.value })
                }
              />
            </div>

            <div className={styles.input}>
              <TextInput
                id="lastname"
                type="text"
                label={i18n.t("pages.screening.end-user.lastname")}
                value={state.lastname || ""}
                required={true}
                errorText={i18n.t("errors.lastname-not-defined")}
                onChange={(value) =>
                  setState({ ...state, lastname: value.target.value })
                }
              />
            </div>
          </div>
        </form>
      </div>

      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => navigateTowards("/screening/entity/result"),
            label: i18n.t("global.previous"),
          },
          {
            id: "submit",
            onClick: () => handleSubmit(),
            label: i18n.t("components.searchEccn.search-button"),
            primary: true,
            disabled: submitIsDisabled(),
          },
        ]}
      >
        {state.flowId && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(EndUser));
