/* Styles import */
import styles from "./result.module.css";

/* Global import */
import React, { Component, createContext, useEffect, useState } from "react";
import {
  GridCol,
  GridRow,
  Title,
} from "../../../../components/horizon-components-react/src/components";
import i18n from "i18next";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../../../components/appBar";
import Footer from "../../../../components/footer";
import { flowUrl } from "../../../../utils/generator";
import TimeLine from "../../../../components/timeLine";
import { inject, observer } from "mobx-react";

interface Props {
  rootStore: import("../../../../stores/rootStore").default;
}
const ResultEndUser: React.FC<Props> = ({ rootStore }) => {
  const navigate = useNavigate();
  const [flowId, setFlowId] = useState<string | null>(null);
  function navigateTowards(url: string) {
    const { id } = useParams();
    navigate(flowUrl(url, id));
  }

  const screeningResult = rootStore.screeningStore.screeningEndUserResult;
  const screeningResultElement = screeningResult && (
    <div className={styles.message}>
      <div
        className={`${styles.traficLightBox} ${
          styles[screeningResult.trafficLightColor.toLowerCase()]
        }`}
      ></div>

      <img
        className={styles.traficLight}
        src={require(
          `../../../../../statics/images/traffic-${screeningResult.trafficLightColor.toLowerCase()}.svg`,
        )}
        alt={"traficLight"}
      />

      <p>{screeningResult.message}</p>
    </div>
  );

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      setFlowId(id);
    }
  }, []);

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"SCREENING"} />

      <div className={styles.image}></div>

      <div className={styles.body}>
        <Title tag="h2">
          {i18n.t("pages.screening.end-user.result.title")}
        </Title>
        {screeningResultElement}
      </div>

      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => navigateTowards("/screening/end-user"),
            label: i18n.t("global.previous"),
          },
          {
            id: "next",
            onClick: () => navigateTowards("/license/matching"),
            label: i18n.t("pages.license.menu.matching"),
            primary: true,
          },
        ]}
      >
        {flowId && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(ResultEndUser));
