import React, { ReactNode, HTMLAttributes, SFC } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";

type Props = {
  /** The content of the table */
  children?: ReactNode;
};

type TableProps = Props & HTMLAttributes<HTMLTableElement>;

export const Table: SFC<TableProps> = ({ className, children, ...rest }) => (
  <div className={cx(classes["ds-table-wrapper"], className)}>
    <table {...rest}>{children}</table>
  </div>
);

export default Table;
