/* Styles import */
import styles from "./log.module.css";

/* Global import */
import React, {
  Component,
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import {
  Title,
  Card,
  CardHeader,
  Loading,
  SearchInput,
  GridContainer,
  GridRow,
  GridCol,
  TextInput,
  Button,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { observer, inject } from "mobx-react";

/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderReport from "../../../components/headerReport";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import Moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
interface Props {}
interface InjectedProps extends Props {
  history: import("history").History;
  rootStore: import("../../../stores/rootStore").default;
}
interface State {
  search: string;
  name?: string;
  erp: boolean;
  screening: string;
  gridRef: any;
  setGridRef: any;
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}

const Reportsm: React.FC<Props> = ({ rootStore }) => {
  const [search, setSearch] = useState<string>("");
  const [myRef, setMyRef] = useState<any>(null);
  const [name, setName] = useState<string | undefined>(undefined);
  const [erp, setErp] = useState<boolean>(false);
  const [screening, setScreening] = useState<string>("USE_BVD");
  const [gridRef, setGridRef] = useState<any>(null);

  useEffect(() => {
    rootStore.tenantStore.getLog("ALL");
  }, []);
  const dataSource = rootStore.tenantStore.datalog;
  const columns = [
    { name: "Date_heure", header: "Date_heure", minWidth: 150, defaultFlex: 2 },
    { name: "Client", header: "Clients", minWidth: 100, defaultFlex: 2 },
    { name: "User", header: "User", minWidth: 100, defaultFlex: 2 },
    { name: "Action", header: "Action", minWidth: 100, defaultFlex: 2 },
    { name: "value", header: "Value", minWidth: 100, defaultFlex: 2 },
    { name: "Result", header: "Result", minWidth: 100, defaultFlex: 2 },
    {
      name: "Departure_country",
      header: "Departure",
      minWidth: 100,
      defaultFlex: 2,
    },
    {
      name: "Destination_country",
      header: "Destination",
      minWidth: 100,
      defaultFlex: 2,
    },
    {
      name: "National_code",
      header: "National_code",
      minWidth: 100,
      defaultFlex: 2,
      defaultVisible: false,
    },
    {
      name: "US_code",
      header: "US_code",
      minWidth: 100,
      defaultFlex: 2,
      defaultVisible: false,
    },
    { name: "Info1", header: "Info1", minWidth: 100, defaultFlex: 1 },
    {
      name: "Info2",
      header: "Info2",
      minWidth: 100,
      defaultFlex: 1,
      defaultVisible: false,
    },
  ];

  const filterValue = [
    { name: "Client", operator: "contains", type: "string", value: "" },
    { name: "User", operator: "contains", type: "string", value: "" },
    { name: "Action", operator: "contains", type: "string", value: "" },
  ];

  const gridStyle = { minHeight: "500PX", marginTop: "15PX" };
  const SEPARATOR = ";";
  var dateJour = new Date();
  var afDate = Moment(dateJour).format("YYYY-MM-DD");
  const downloadBlob = (
    blob: any,
    fileName = afDate + " export control report.csv",
  ) => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.position = "absolute";
    link.style.visibility = "hidden";

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const exportXLS = () => {
    const columns = myRef.current.visibleColumns;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const header = columns.map((c: any) => c.header).join(SEPARATOR);
    const rows = myRef.current.data.map((data: any) =>
      columns.map((c: any) => data[c.id]).join(SEPARATOR),
    );
    const contents = [header].concat(rows).join("\n");
    const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });
    const ws = XLSX.utils.json_to_sheet(dataSource);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, afDate + " export control report" + fileExtension);
  };

  const exportCSV = () => {
    const columns = myRef.current.visibleColumns;
    const header = columns.map((c: any) => c.header).join(SEPARATOR);
    const rows = myRef.current.data.map((data: any) =>
      columns.map((c: any) => data[c.id]).join(SEPARATOR),
    );
    const contents = [header].concat(rows).join("\n");
    const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });
    downloadBlob(blob);
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="ADMIN" />
      <HeaderReport pages="REPORTING" rootStore={rootStore} />
      <div className={styles.body}>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Title tag="h2">{i18n.t("pages.search-flow.titrereport")}</Title>
        {rootStore.tenantStore.loading && <Loading />}
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(Reportsm));
