/* Global import */
import { observable, action, makeAutoObservable } from "mobx";

const initialJwtState: JwtData = {
  exp: 0,
  iat: 0,
  jti: "",
  iss: "",
  aud: [],
  sub: "",
  typ: "",
  azp: "",
  sid: "",
  acr: "",
  allowed_origins: [],
  realm_access: {
    roles: [],
  },
  resource_access: {},
  scope: "",
  email_verified: false,
  name: "",
  preferred_username: "",
  given_name: "",
  family_name: "",
  email: "",
};

export default class JwtStore {
  @observable jwt: JwtData;

  constructor() {
    makeAutoObservable(this);
    this.jwt = initialJwtState;
  }

  @action getJwt(): JwtData {
    return this.jwt;
  }

  @action getRoles(): string[] {
    return this.jwt.realm_access.roles || [];
  }

  @action setJwt(jwt: JwtData): void {
    this.jwt = jwt;
  }

  @action clearJwt(): void {
    this.jwt = initialJwtState;
  }
}
