/* Styles import */
import styles from "../admin.module.css";
import Api from "../../../utils/api";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  TimePicker,
  Button,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { observer, inject } from "mobx-react";

/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import ProgressUpload from "../../../components/progressUpload";
import { getToLocaleDate } from "../../../utils/date";
import TextField from '@mui/material/TextField';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';

interface State {
  value: string;
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const System: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const [state, setState] = useState({
    value: '',
    emailUSValue:'',
    lastUpdatedCrawlerUS: "",

    emailUKValue: '',
    lastUpdatedCrawlerUK: '',
    URLCrawlerUK: '',

    emailFRValue: '',
    lastUpdatedCrawlerFR: '',
    URLLastDateCrawlerFR: '',
    URLFileJSONCrawlerFR: '',

    emailCanValue: '',
    lastUpdatedCrawlerCan: '',
    URLCrawlerCan: '',

    emailEUValue: '',
    lastUpdatedCrawlerEU: '',
    URLCrawlerEU: '',

    emailCCCValue: '',
    lastUpdatedCrawlerCCC: '',
    URLCrawlerCCC: '',

    emailRussianValue:'',
    lastUpdatedCrawlerRussian: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const countries = ['CAN Sanction list', 'EU Sanction list', 'FR Sanction list', 'UK Sanction list', 'US Commerce Country Chart', 'US license exceptions', 'Russian Regulations'];
      const settingCrawler = await api.getSettingsCrawlerMultiple(countries);
      const time_run = await api.gettimereplication();
      setState((prevState) => ({
        ...prevState,
        value: time_run,
        emailUSValue: settingCrawler['US license exceptions'].emails,
        lastUpdatedCrawlerUS: settingCrawler['US license exceptions'].lastUpdatedCrawler,

        emailUKValue: settingCrawler['UK Sanction list'].emails,
        lastUpdatedCrawlerUK: settingCrawler['UK Sanction list'].lastUpdatedCrawler,
        URLCrawlerUK: settingCrawler['UK Sanction list'].urlCible,

        emailFRValue: settingCrawler['FR Sanction list'].emails,
        lastUpdatedCrawlerFR: settingCrawler['FR Sanction list'].lastUpdatedCrawler,
        URLLastDateCrawlerFR: settingCrawler['FR Sanction list'].urlCible,
        URLFileJSONCrawlerFR: settingCrawler['FR Sanction list'].urlJSON,

        emailCanValue: settingCrawler['CAN Sanction list'].emails,
        lastUpdatedCrawlerCan: settingCrawler['CAN Sanction list'].lastUpdatedCrawler,
        URLCrawlerCan: settingCrawler['CAN Sanction list'].urlCible,

        emailEUValue: settingCrawler['EU Sanction list'].emails,
        lastUpdatedCrawlerEU: settingCrawler['EU Sanction list'].lastUpdatedCrawler,
        URLCrawlerEU: settingCrawler['EU Sanction list'].urlCible,

        emailCCCValue: settingCrawler['US Commerce Country Chart'].emails,
        lastUpdatedCrawlerCCC: settingCrawler['US Commerce Country Chart'].lastUpdatedCrawler,
        URLCrawlerCCC: settingCrawler['US Commerce Country Chart'].urlCible,

        emailRussianValue: settingCrawler['Russian Regulations'].emails,
        lastUpdatedCrawlerRussian: settingCrawler['Russian Regulations'].lastUpdatedCrawler,
      }));
    };

    fetchData();
  }, []);

  const submit = async () => {
    await api.savetimereplication(state.value);
    rootStore.notificationStore.setNotification(
      i18n.t("pages.admin.users.saved.title1"),
      i18n.t("pages.admin.users.saved.update"),
      "success",
    );
  };

  const submitCrawler = async (email: string, paysCrawler: string, urlCible?: string, urlJSON?: string) => {
    await api.sendSettingsCrawler({
      textfield: email,
      pays : paysCrawler,
      urlCible: urlCible,
      urlJSON: urlJSON,
    });
    rootStore.notificationStore.setNotification(
      i18n.t("pages.admin.users.saved.USLETitle"),
      i18n.t("pages.admin.users.saved.update"),
      "success",
    );
  };

  const exportUSCrawler = async () => {
    await api.exportUSLEtoXLS();
    rootStore.notificationStore.setNotification(
      i18n.t("pages.admin.users.saved.USLETitle"),
      i18n.t("pages.admin.users.saved.update"),
      "success",
    );
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="ADMIN" />

      <HeaderAdmin pages="SYSTEM" rootStore={rootStore} />

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.menu.system")} />

        <CardContent></CardContent>

        <TimePicker
        title=""
        value={state.value}
        onChange={(time) => {
          setState({ ...state, value: time as string});
        }}
        />

        <br />
        <br />

        <Button primary style={{ height: "34px" }} onClick={() => submit()}>
          {i18n.t("pages.admin.users.add")}
        </Button>
      </Card>
      
      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.menu.CanCrawler")} />
        
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            borderRadius: 1,
            justifyContent: "space-around",
          }}
        >

            <TextField
              id="outlined-read-only-input"
              label={i18n.t("pages.admin.users.lastUpdated")}
              InputProps={{
                readOnly: true,
              }}
              value={state.lastUpdatedCrawlerCan}
              size="small"
              sx={{
                m:2,
              }}
            />

            <TextField
              required
              id="outlined-required"
              label={i18n.t("pages.admin.users.urlCrawler")}
              size="small"
              sx={{
                m:2,
              }}
              value={state.URLCrawlerCan}
              onChange={(e) => setState({ ...state, URLCrawlerCan: e.target.value })}
            />

            <TextField
              required
              id="outlined-required"
              label={i18n.t("pages.admin.users.email")}
              size="small"
              sx={{
                m:2,
              }}
              value={state.emailCanValue}
              onChange={(e) => setState({ ...state, emailCanValue: e.target.value })}
            />
          </Box>
          
          <Stack direction="row" spacing={2}>
            <Button primary style={{ height: "34px" }} onClick={() => submitCrawler(state.emailCanValue, 'CAN Sanction list', state.URLCrawlerCan)}>
              {i18n.t("global.save")}
            </Button>
          </Stack>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.menu.EUCrawler")} />
        
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            borderRadius: 1,
            justifyContent: "space-around",
          }}
        >

            <TextField
              id="outlined-read-only-input"
              label={i18n.t("pages.admin.users.lastUpdated")}
              InputProps={{
                readOnly: true,
              }}
              value={state.lastUpdatedCrawlerEU}
              size="small"
              sx={{
                m:2,
              }}
            />

            <TextField
              required
              id="outlined-required"
              label={i18n.t("pages.admin.users.urlCrawler")}
              size="small"
              sx={{
                m:2,
              }}
              value={state.URLCrawlerEU}
              onChange={(e) => setState({ ...state, URLCrawlerEU: e.target.value })}
            />

            <TextField
              required
              id="outlined-required"
              label={i18n.t("pages.admin.users.email")}
              size="small"
              sx={{
                m:2,
              }}
              value={state.emailEUValue}
              onChange={(e) => setState({ ...state, emailEUValue: e.target.value })}
            />
          </Box>
          
          <Stack direction="row" spacing={2}>
            <Button primary style={{ height: "34px" }} onClick={() => submitCrawler(state.emailEUValue, 'EU Sanction list', state.URLCrawlerEU)}>
              {i18n.t("global.save")}
            </Button>
          </Stack>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.menu.EU8332014Crawler")} />

        <CardContent></CardContent>
        
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            borderRadius: 1,
            justifyContent: "space-around",
          }}
        >

            <TextField
              id="outlined-read-only-input"
              label={i18n.t("pages.admin.users.lastUpdated")}
              InputProps={{
                readOnly: true,
              }}
              value={state.lastUpdatedCrawlerRussian}
              size="small"
              sx={{
                m:2,
              }}
            />

            <TextField
              required
              id="outlined-required"
              label={i18n.t("pages.admin.users.email")}
              size="small"
              sx={{
                m:2,
              }}
              value={state.emailRussianValue}
              onChange={(e) => setState({ ...state, emailRussianValue: e.target.value })}
            />
          </Box>
          
          <Stack direction="row" spacing={2}>
            <Button primary style={{ height: "34px" }} onClick={() => submitCrawler(state.emailRussianValue, 'Russian Regulations')}>
              {i18n.t("global.save")}
            </Button>
          </Stack>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.menu.FRcrawler")} />
        
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            borderRadius: 1,
            justifyContent: "space-around",
          }}
        >

            <TextField
              id="outlined-read-only-input"
              label={i18n.t("pages.admin.users.lastUpdated")}
              InputProps={{
                readOnly: true,
              }}
              value={state.lastUpdatedCrawlerFR}
              size="small"
              sx={{
                m:2,
              }}
            />

            <TextField
              required
              id="outlined-required"
              label={i18n.t("pages.admin.users.urlLastDateFRCrawler")}
              size="small"
              sx={{
                m:2,
              }}
              value={state.URLLastDateCrawlerFR}
              onChange={(e) => setState({ ...state, URLLastDateCrawlerFR: e.target.value })}
            />

            <TextField
              required
              id="outlined-required"
              label={i18n.t("pages.admin.users.urlFileJSONCrawlerFR")}
              size="small"
              sx={{
                m:2,
              }}
              value={state.URLFileJSONCrawlerFR}
              onChange={(e) => setState({ ...state, URLFileJSONCrawlerFR: e.target.value })}
            />

            <TextField
              required
              id="outlined-required"
              label={i18n.t("pages.admin.users.email")}
              size="small"
              sx={{
                m:2,
              }}
              value={state.emailFRValue}
              onChange={(e) => setState({ ...state, emailFRValue: e.target.value })}
            />
          </Box>
          
          <Stack direction="row" spacing={2}>
            <Button primary style={{ height: "34px" }} onClick={() => submitCrawler(state.emailFRValue, 'FR Sanction list', state.URLLastDateCrawlerFR, state.URLFileJSONCrawlerFR)}>
              {i18n.t("global.save")}
            </Button>
          </Stack>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.menu.UKcrawler")} />
        
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            borderRadius: 1,
            justifyContent: "space-around",
          }}
        >

            <TextField
              id="outlined-read-only-input"
              label={i18n.t("pages.admin.users.lastUpdated")}
              InputProps={{
                readOnly: true,
              }}
              value={state.lastUpdatedCrawlerUK}
              size="small"
              sx={{
                m:2,
              }}
            />

            <TextField
              required
              id="outlined-required"
              label={i18n.t("pages.admin.users.urlCrawler")}
              size="small"
              sx={{
                m:2,
              }}
              value={state.URLCrawlerUK}
              onChange={(e) => setState({ ...state, URLCrawlerUK: e.target.value })}
            />

            <TextField
              required
              id="outlined-required"
              label={i18n.t("pages.admin.users.email")}
              size="small"
              sx={{
                m:2,
              }}
              value={state.emailUKValue}
              onChange={(e) => setState({ ...state, emailUKValue: e.target.value })}
            />
          </Box>
          
          <Stack direction="row" spacing={2}>
            <Button primary style={{ height: "34px" }} onClick={() => submitCrawler(state.emailUKValue, 'UK Sanction list', state.URLCrawlerUK)}>
              {i18n.t("global.save")}
            </Button>
          </Stack>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.menu.CCCCrawler")} />
        
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            borderRadius: 1,
            justifyContent: "space-around",
          }}
        >

            <TextField
              id="outlined-read-only-input"
              label={i18n.t("pages.admin.users.lastUpdated")}
              InputProps={{
                readOnly: true,
              }}
              value={state.lastUpdatedCrawlerCCC}
              size="small"
              sx={{
                m:2,
              }}
            />

            <TextField
              required
              id="outlined-required"
              label={i18n.t("pages.admin.users.urlCrawler")}
              size="small"
              sx={{
                m:2,
              }}
              value={state.URLCrawlerCCC}
              onChange={(e) => setState({ ...state, URLCrawlerCCC: e.target.value })}
            />

            <TextField
              required
              id="outlined-required"
              label={i18n.t("pages.admin.users.email")}
              size="small"
              sx={{
                m:2,
              }}
              value={state.emailCCCValue}
              onChange={(e) => setState({ ...state, emailCCCValue: e.target.value })}
            />
          </Box>
          
          <Stack direction="row" spacing={2}>
            <Button primary style={{ height: "34px" }} onClick={() => submitCrawler(state.emailCCCValue, 'US Commerce Country Chart', state.URLCrawlerCCC)}>
              {i18n.t("global.save")}
            </Button>
          </Stack>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.menu.UScrawler")} />

        <CardContent></CardContent>
        
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            borderRadius: 1,
            justifyContent: "space-around",
          }}
        >

            <TextField
              id="outlined-read-only-input"
              label={i18n.t("pages.admin.users.lastUpdated")}
              InputProps={{
                readOnly: true,
              }}
              value={state.lastUpdatedCrawlerUS}
              size="small"
              sx={{
                m:2,
              }}
            />

            <TextField
              required
              id="outlined-required"
              label={i18n.t("pages.admin.users.email")}
              size="small"
              sx={{
                m:2,
              }}
              value={state.emailUSValue}
              onChange={(e) => setState({ ...state, emailUSValue: e.target.value })}
            />
          </Box>
          
          <Stack direction="row" spacing={2}>
            <Button primary style={{ height: "34px" }} onClick={() => submitCrawler(state.emailUSValue, 'US license exceptions')}>
              {i18n.t("global.save")}
            </Button>
            <Button style={{ height: "34px" }} onClick={() => exportUSCrawler()}>
              {i18n.t("global.exportXLS")}
            </Button>
          </Stack>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(System));
