import React, { useEffect, useState, useCallback } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community/";
import "@inovua/reactdatagrid-community/index.css";
import Api from "../../utils/api";

import configuration from "../../configuration";
type PeopleType = {
  id: string;
  Departure: string;
  Destination: string;
  Import: string;
  Org: string;
  Light: string;
  Active: string;
  Message: string;
  Link_text: string;
  Link: string;
  Link_text1: string;
  Link1: string;
  Link_text2: string;
  Link2: string;
};

const gridStyle = { minHeight: 550 };

const columns = [
  {
    name: "id",
    sortable: false,
    header: "Id",
    defaultVisible: false,
    defaultWidth: 80,
    editable: false,
  },
  { name: "Departure", sortable: false, header: "Departure", defaultFlex: 1 },
  {
    name: "Destination",
    sortable: false,
    header: "Destination",
    defaultFlex: 1,
  },
  { name: "Import", sortable: false, header: "Import", defaultFlex: 1 },
  { name: "Org", sortable: false, header: "Org", defaultFlex: 1 },
  { name: "Light", sortable: false, header: "Light", defaultFlex: 1 },
  { name: "Active", sortable: false, header: "Active", defaultFlex: 1 },
  { name: "Message", sortable: false, header: "Message", defaultFlex: 1 },
  { name: "Link_text", sortable: false, header: "Link text 1", defaultFlex: 1 },
  { name: "Link", sortable: false, header: "Link1", defaultFlex: 1 },
  {
    name: "Link_text1",
    sortable: false,
    header: "Link text 2",
    defaultFlex: 1,
  },
  { name: "Link1", sortable: false, header: "Link2", defaultFlex: 1 },
  {
    name: "Link_text2",
    sortable: false,
    header: "Link text 3",
    defaultFlex: 1,
  },
  { name: "Link2", sortable: false, header: "Link3", defaultFlex: 1 },
];

const ListSanction = () => {
  let api: Api;
  const [dataSource, setDataSource] = useState<PeopleType[]>([]);
  const [value, setValue] = useState("");
  useEffect(() => {
    fetch(`${configuration.LARAVEL_API}/Sanction`)
      .then((res) => res.json())
      .then((resp) => setDataSource(resp));
  }, []);

  const onEditComplete = useCallback(
    ({ value, columnId, rowIndex }) => {
      const data = [...dataSource];

      api = new Api();
      if (columnId == "Departure") data[rowIndex]["Departure"] = value;
      if (columnId == "Destination") data[rowIndex]["Destination"] = value;
      if (columnId == "Import") data[rowIndex]["Import"] = value;
      if (columnId == "Org") data[rowIndex]["Org"] = value;
      if (columnId == "Light") data[rowIndex]["Light"] = value;
      if (columnId == "Active") data[rowIndex]["Active"] = value;
      if (columnId == "Message") data[rowIndex]["Message"] = value;
      if (columnId == "Link_text") data[rowIndex]["Link_text"] = value;
      if (columnId == "Link") data[rowIndex]["Link"] = value;
      if (columnId == "Link_text1") data[rowIndex]["Link_text1"] = value;
      if (columnId == "Link1") data[rowIndex]["Link1"] = value;
      if (columnId == "Link_text2") data[rowIndex]["Link_text2"] = value;
      if (columnId == "Link2") data[rowIndex]["Link2"] = value;

      const logr = api.PostSanction(
        data[rowIndex]["id"],
        data[rowIndex]["Departure"],
        data[rowIndex]["Destination"],
        data[rowIndex]["Import"],
        data[rowIndex]["Org"],
        data[rowIndex]["Light"],
        data[rowIndex]["Active"],
        data[rowIndex]["Message"],
        data[rowIndex]["Link_text"],
        data[rowIndex]["Link"],
        data[rowIndex]["Link_text1"],
        data[rowIndex]["Link1"],
        data[rowIndex]["Link_text2"],
        data[rowIndex]["Link2"]
      );

      setDataSource(data);
    },
    [dataSource]
  );

  return (
    <div>
      <div>
        <ReactDataGrid
          idProperty="id"
          style={gridStyle}
          onEditComplete={onEditComplete}
          editable
          //defaultSortInfo={ [{ name: 'Departure', dir: 1 }, { name: 'Destination', dir: 1 }]}
          columns={columns}
          dataSource={dataSource}
        />
      </div>
    </div>
  );
};

export default ListSanction;
